import React, { FC, useContext, useEffect, useState } from 'react';

import { Button, InputLabel, Paper, TextField } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';

import { ShopLocationContext } from '../../../contexts/ShopLocationContext';
import { IShopLocationData } from '../../../interfaces/ShopLocationInterface';
import { FormTheme } from '../../../util/themes/GlobalThemeOverride';
import { AlertContext } from '../../../contexts/AlertContext';
import { ProductCategoryContext } from '../../../contexts';
import { IProductCategoryData } from '../../../interfaces/ProductCategoryInterface';
import TagsCollection from '../../../components/TagsCollection/TagsCollection';
import { ITag, TagType } from '../../../interfaces/TagsCollectionInterface';

const Input = styled('input')({
    display: 'none'
});

interface ShowSingleShopLocationProps {
    shopLocation: IShopLocationData | null;
}

interface State {
    id: number;
    name: string;
    categoryAvailability: Array<number>;
    postalCode: number;
    phoneNumber: string;
    workingHours: string;
    details: string;
    city: string;
    address: string;
    image: File | null;
    createdAt: Date;
    updatedAt: Date;
}

const ShowSingleShopLocation: FC<ShowSingleShopLocationProps> = (props) => {
    const { shopLocation } = props;

    const { showAlert } = useContext(AlertContext);

    const { updateShopLocation } = useContext(ShopLocationContext);

    const { categories } = useContext(ProductCategoryContext);

    const [values, setValues] = useState<State>({
        id: 0,
        name: '',
        categoryAvailability: [],
        postalCode: NaN,
        phoneNumber: '',
        workingHours: '',
        details: '',
        city: '',
        address: '',
        image: null,
        createdAt: new Date(),
        updatedAt: new Date()
    });

    useEffect(() => {
        if (shopLocation) {
            setValues({
                id: shopLocation.id,
                name: shopLocation.name,
                categoryAvailability: shopLocation.categoryAvailability,
                postalCode: shopLocation.postalCode,
                phoneNumber: shopLocation.phoneNumber,
                workingHours: shopLocation.workingHours,
                details: shopLocation.details,
                city: shopLocation.city,
                address: shopLocation.address,
                image: null,
                createdAt: shopLocation.createdAt,
                updatedAt: shopLocation.updatedAt
            });
        }
    }, []);

    const [previewImage, setPreviewImage] = useState(shopLocation?.imageUrl);

    const renderPhoto = () => {
        if(previewImage){
            return (
                <img
                    className='image-preview'
                    src={`${previewImage}`}
                    alt='preview'
                />
            );
        }
    };

    const fileToBase64 = async (file: File | undefined) => {
        if (file !== undefined) {
            const reader = new FileReader();

            reader.onloadend = () => {
                let base64data: string = reader.result as string;

                setPreviewImage(base64data);
            };

            reader.readAsDataURL(file);

            return;
        }

        setValues({ ...values, image: null });

        setPreviewImage(undefined)
    };

    const printNum = (number: number) => {
        if (isNaN(number)) {
            return '';
        }

        return number.toString();
    };

    const validateForm = (prop: keyof State, state: State | null) => {
        if (state === null) {
            return true;
        }

        if (prop !== 'image') {
            if (state[prop] === '') {
                return true;
            }

            if (typeof state[prop] === 'number') {
                if (isNaN(state[prop] as number)) {
                    return true;
                }
            }
        }
    };

    const submitForm = () => {
        let formData = new FormData();

        formData.append(
            'data',
            JSON.stringify({
                id: values.id,
                name: values.name,
                categoryAvailability: values.categoryAvailability,
                postalCode: values.postalCode,
                phoneNumber: values.phoneNumber,
                workingHours: values.workingHours,
                details: values.details,
                city: values.city,
                address: values.address,
                createdAt: values.createdAt,
                updatedAt: values.updatedAt,
                imageUrl: shopLocation?.imageUrl
            })
        );

        if (values.image) {
            formData.append('Images', values.image, values.image.name);
        }

        updateShopLocation(formData).then((response: boolean) => {
            if (response === false) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Updating factory failed'
                });
                return;
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully updated factory'
            });
        });
    };

    const handleChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (prop !== 'image') {
                setValues({ ...values, [prop]: event.target.value });
                return;
            }

            if (event.target.files) {
                const file = event.target.files[0];

                setValues({ ...values, image: file });

                fileToBase64(file);
            }
        };

    const setCategoryAvailability = (data: IProductCategoryData[]) => {
        setValues({ ...values, categoryAvailability: data.map(category => category.id) });
    };

    const mapCategoriesFromIds = () => {
        const received: ITag[] = [];

        if(categories !== null){
            for (let category of categories){
                if (shopLocation?.categoryAvailability.includes(category.id)){
                    received.push({id: category.id, name: category.name, object: category});
                }
            }
        }

        return received;
    }

    return (
        <div className='page-container add-form-container'>
            <ThemeProvider theme={FormTheme}>
                <Paper className='paper-form'>
                    <h1>Preview Factory</h1>
                    <div className='form-container'>
                        <TextField
                            id='textfield-id'
                            key='id'
                            label='Id'
                            value={values?.id}
                            InputLabelProps={{ shrink: true }}
                            disabled
                            required
                        ></TextField>

                        <TextField
                            id='textfield-name'
                            key='name'
                            label='Name'
                            value={values?.name}
                            onChange={handleChange('name')}
                            error={validateForm('name', values)}
                            helperText={
                                validateForm('name', values)
                                    ? 'Name cannot be empty!'
                                    : ' '
                            }
                            required
                        ></TextField>

                        <TextField
                            id='textfield-city'
                            key='city'
                            label='City'
                            value={values?.city}
                            onChange={handleChange('city')}
                            error={validateForm('city', values)}
                            helperText={
                                validateForm('city', values)
                                    ? 'City cannot be empty!'
                                    : ' '
                            }
                            required
                        ></TextField>

                        <TextField
                            id='textfield-address'
                            key='address'
                            label='Address'
                            value={values?.address}
                            onChange={handleChange('address')}
                            error={validateForm('address', values)}
                            helperText={
                                validateForm('address', values)
                                    ? 'Address cannot be empty!'
                                    : ' '
                            }
                            required
                        ></TextField>

                        <TextField
                            id='textfield-postalCode'
                            key='postalCode'
                            label='Postal code'
                            value={values?.postalCode}
                            type='number'
                            onChange={handleChange('postalCode')}
                            error={validateForm('postalCode', values)}
                            helperText={
                                validateForm('postalCode', values)
                                    ? 'Postal code cannot be empty!'
                                    : ' '
                            }
                            required
                        ></TextField>

                        <div className='inner-form-products'>
                            <InputLabel
                                sx={{
                                    marginBottom: '10px'
                                }}
                            >
                                Select Available Categories
                            </InputLabel>

                            <TagsCollection
                                tagType={TagType.AvailableCategories}
                                returnValue={setCategoryAvailability}
                                receivedTags={mapCategoriesFromIds()}
                            />
                        </div>

                        <TextField
                            defaultValue=''
                            label='Phone number'
                            key='phoneNumber'
                            value={values?.phoneNumber}
                            onChange={handleChange('phoneNumber')}
                        ></TextField>

                        <TextField
                            defaultValue=''
                            label='Working hours'
                            key='workingHours'
                            value={values?.workingHours}
                            onChange={handleChange('workingHours')}
                        ></TextField>

                        <TextField
                            defaultValue=''
                            label='Details'
                            key='details'
                            value={values?.details}
                            onChange={handleChange('details')}
                            multiline
                            rows={5}
                        ></TextField>

                        <div className='image-upload-container-showSingleShopLocation'>
                            {renderPhoto()}
                            <label htmlFor='contained-button-file'>
                                <Input
                                    accept='.png, .jpg, .jpeg'
                                    id='contained-button-file'
                                    multiple
                                    type='file'
                                    key='shopLocationImage'
                                    onChange={handleChange('image')}
                                />

                                <Button
                                    variant='contained'
                                    component='span'
                                    sx={{ m: 0.5, width: '50ch' }}
                                >
                                    Change Image
                                </Button>
                            </label>
                        </div>

                        <Button
                            variant='contained'
                            component='label'
                            sx={{ m: 1.5, width: '50ch' }}
                            size='large'
                            color='success'
                            id='add-shoplocation-btn'
                            onMouseDown={submitForm}
                            disabled={false}
                        >
                            Save
                            <input type='button' hidden />
                        </Button>
                    </div>
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default ShowSingleShopLocation;
