import { SvgIconComponent } from '@mui/icons-material';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AlertContext, ProductSubCategoryContext } from '../../contexts';
import { IProductSubCategoryData } from '../../interfaces/ProductSubCategoryInterface';

interface ProductSubCategorySelectProps {
    iconComponent: SvgIconComponent | undefined;
    setValue: Function;
    categoryId: number | null;
    selectedValue: string;
}

const ProductSubCategorySelect: FC<ProductSubCategorySelectProps> = (props) => {
    const { iconComponent, setValue, categoryId, selectedValue } = props;

    const { getAllSubcategoriesByCategoryId } = useContext(
        ProductSubCategoryContext
    );

    const { showAlert } = useContext(AlertContext);

    const [subCategories, setSubCategories] = useState<
        IProductSubCategoryData[] | null
    >([]);

    const [selectedSubCategory, setSelectedSubCategory] = useState('');

    useEffect(() => {
        if (selectedValue) {
            setSelectedSubCategory(selectedValue);
        }
    }, []);

    const getSubCategoryFromName = (name: string) => {
        if (subCategories) {
            for (let subcategory of subCategories) {
                if (subcategory.name === name) {
                    return subcategory;
                }
            }

            return null;
        }

        return null;
    };

    useEffect(() => {
        if (!categoryId) {
            return;
        }

        getAllSubcategoriesByCategoryId(categoryId).then((response) => {
            if (response === null) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Cannot get data from server'
                });

                return;
            }

            setSubCategories(response);
        });
    }, [categoryId]);

    const renderSelectData = () => {
        return subCategories?.map((subCategory: IProductSubCategoryData) => {
            return (
                <MenuItem key={subCategory.id} value={subCategory.name}>
                    {subCategory.name}
                </MenuItem>
            );
        });
    };

    const handleSelectChange = () => (event: SelectChangeEvent) => {
        setSelectedSubCategory(event.target.value);

        setValue(getSubCategoryFromName(event.target.value));
    };

    return (
        <Select
            label='Select SubCategory'
            variant='filled'
            onChange={handleSelectChange()}
            IconComponent={iconComponent}
            value={selectedSubCategory}
        >
            {renderSelectData()}
        </Select>
    );
};

export default ProductSubCategorySelect;
