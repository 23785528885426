import { RuleTwoTone } from '@mui/icons-material';
import { AxiosError, AxiosResponse } from 'axios';
import { createContext, FC, useContext } from 'react';
import { AuthenticationContext, AxiosContext, LanguageContext } from '.';
import { IProductData } from '../interfaces/ProductInterface';

interface IProductContext {
    getAllProducts(): Promise<IProductData[] | null>;
    deleteProduct(id: number): Promise<boolean>;
    addProduct(data: FormData): Promise<boolean>;
    updateProduct(data: FormData): Promise<boolean>;
    getAllProductsBySubcategoryId(
        subcategoryId: number
    ): Promise<IProductData[] | null>;
    getById(id: number): Promise<IProductData | null>;
}

const ProductContext = createContext({} as IProductContext);

interface ProductContextProps {
    children: React.ReactNode;
}

const ProductContextProvider: FC<ProductContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const { getToken } = useContext(AuthenticationContext);

    const { getLanguage } = useContext(LanguageContext);

    const getAllProducts = () => {
        return axiosInstance({
            method: 'GET',
            url: 'api/product/getallwithcategories',
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const deleteProduct = (id: number) => {
        return axiosInstance({
            method: 'DELETE',
            url: `api/product/delete/${id}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const addProduct = (data: FormData) => {
        return axiosInstance({
            method: 'POST',
            url: 'api/product/add',
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                'Content-Type': 'multipart/form-data'
            },
            data
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const updateProduct = (data: FormData) => {
        const lang = getLanguage().toString();

        return axiosInstance({
            method: 'PUT',
            url: 'api/product/update',
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                'Content-Type': 'multipart/form-data',
                Language: lang
            },
            data
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const getAllProductsBySubcategoryId = (subcategoryId: number) => {
        return axiosInstance({
            method: 'GET',
            url: `api/product/getallbysubcategoryid/${subcategoryId}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const getById = (id: number) => {
        const lang = getLanguage().toString();

        console.log(lang);

        return axiosInstance({
            method: 'GET',
            url: `api/product/getbyid/${id}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                Language: lang
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const providerValue = {
        getAllProducts,
        deleteProduct,
        addProduct,
        updateProduct,
        getAllProductsBySubcategoryId,
        getById
    };

    return (
        <ProductContext.Provider value={providerValue}>
            {props.children}
        </ProductContext.Provider>
    );
};

export { ProductContext, ProductContextProvider };
