import { ThemeProvider } from '@emotion/react';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AlertContext } from '../../../contexts';
import { ArticleContext } from '../../../contexts/ArticleContext';
import { IArticleData } from '../../../interfaces/ArticleInterface';
import { printArticleCategory } from '../../../util/functions/ArticleCategory';
import { TableTheme } from '../../../util/themes/GlobalThemeOverride';
import { responsiveWidth } from '../../../util/themes/TableWidth';

interface AllArticlesProps {
    setCurrentArticle: Function;
}

const AllArticles: FC<AllArticlesProps> = (props) => {
    const { setCurrentArticle } = props;

    const { getAllArticles, deleteArticle } = useContext(ArticleContext);

    const { showAlert } = useContext(AlertContext);

    const [rows, setRows] = useState<IArticleData[] | null>([]);

    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const [page, setPage] = useState<number>(0);

    const [rerender, setRerender] = useState<boolean>(false);

    const history = useHistory();

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (rerender) {
            loadData();

            setRerender(false);
        }
    });

    const loadData = () => {
        getAllArticles().then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Cannot get data from server'
                });

                return;
            }

            setRows(response);
        });
    };

    const handleDelete = (id: number) => {
        deleteArticle(id).then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Deleting article failed'
                });
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully deleted article'
            });

            setRerender(true);
        });
    };

    const showSingleArticle = (article: IArticleData) => {
        setCurrentArticle(article);

        history.push('/article/show-single');
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);

        setPage(0);
    };

    const renderData = () => {
        if (rows) {
            return rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: IArticleData) => (
                    <TableRow key={row.id}>
                        <TableCell
                            align='left'
                            onMouseDown={() => {
                                showSingleArticle(row);
                            }}
                        >
                            {row.id}
                        </TableCell>

                        <TableCell
                            align='center'
                            onMouseDown={() => {
                                showSingleArticle(row);
                            }}
                        >
                            {row.title}
                        </TableCell>

                        <TableCell
                            align='center'
                            onMouseDown={() => {
                                showSingleArticle(row);
                            }}
                        >
                            {row.description}
                        </TableCell>

                        <TableCell
                            align='center'
                            onMouseDown={() => {
                                showSingleArticle(row);
                            }}
                        >
                            {printArticleCategory(row.articleCategory)}
                        </TableCell>

                        <TableCell
                            align='center'
                            onMouseDown={() => {
                                showSingleArticle(row);
                            }}
                        >
                            {row.userId}
                        </TableCell>

                        <TableCell align='center'>
                            <Button
                                variant='contained'
                                component='span'
                                color='error'
                                onMouseDown={() => {
                                    handleDelete(row.id);
                                }}
                            >
                                Delete
                                <input type='button' hidden />
                            </Button>
                        </TableCell>
                    </TableRow>
                ));
        }
    };

    return (
        <div className='page-container'>
            <ThemeProvider theme={TableTheme}>
                <Button
                    variant='contained'
                    color='success'
                    className='table-view-page-btn'
                    sx={{ marginBottom: 3 }}
                    onClick={() => {
                        history.push('/article/add-article');
                    }}
                >
                    Add new Article
                    <input type='button' hidden />
                </Button>

                <Paper>
                    <TableContainer
                        component={Paper}
                        sx={{ width: responsiveWidth }}
                    >
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>ID</TableCell>
                                    <TableCell align='center'>Title</TableCell>
                                    <TableCell align='center'>
                                        Description
                                    </TableCell>
                                    <TableCell align='center'>
                                        Category
                                    </TableCell>
                                    <TableCell align='center'>UserId</TableCell>
                                    <TableCell align='center'></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderData()}</TableBody>
                        </Table>
                    </TableContainer>
                    {rows ? (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component='div'
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    ) : (
                        <></>
                    )}
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default AllArticles;
