import { IRejectionReason } from '../../interfaces/RejectionReasonInterface';

export const NEMA_VODE: IRejectionReason = {
    serbianText: 'Kafić je ostao bez vode.',
    englishText: 'Cafe is out of water.'
};

export const TEHNICKI_PROBLEM: IRejectionReason = {
    serbianText: 'Iz tehničkih razloga nismo u mogućnosti da obradimo porudžbinu, ponovite porudžbinu na drugoj lokaciji.',
    englishText: 'Due to technical reasons we are unable to process your order, you can try ordering in a different location.'
};

export const NEMA_ZALIHA: IRejectionReason = {
    serbianText: 'Trenutno stanje zaliha za traženi proizvod na ovoj lokaciji je na 0, ponovite porudžbinu na drugoj lokaciji.',
    englishText: 'Current stock for the requested product in this location is 0, you can try ordering in a different location.'
};

// export const AUTO_ERROR: IRejectionReason = {
//     serbianText: 'Automatsko odbijanje, jer je se desila greska pri placanju',
//     englishText: 'Automatic rejection, and error happened during payment'
// };

export const ALL_REASONS: Map<string, IRejectionReason> = new Map<
    string,
    IRejectionReason
>();

ALL_REASONS.set('NEMA VODE', NEMA_VODE);
ALL_REASONS.set('TEHNICKI_PROBLEM', TEHNICKI_PROBLEM);
ALL_REASONS.set('NEMA_ZALIHA', NEMA_ZALIHA);
