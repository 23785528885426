import { ThemeProvider } from '@emotion/react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import moment from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AlertContext, ReviewContext } from '../../../contexts';
import { IReviewData } from '../../../interfaces/ReviewInterface';
import { TableTheme } from '../../../util/themes/GlobalThemeOverride';
import { responsiveWidth } from '../../../util/themes/TableWidth';

interface ApproveReviewsProps {
    setCurrentReview: Function;
}

const ApproveReviews: FC<ApproveReviewsProps> = (props) => {
    const { setCurrentReview } = props;

    const { getAllUnapproved } = useContext(ReviewContext);

    const { showAlert } = useContext(AlertContext);

    const [rows, setRows] = useState<IReviewData[] | null>([]);

    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const [page, setPage] = useState<number>(0);

    const history = useHistory();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        getAllUnapproved().then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Cannot get data from server'
                });

                return;
            }

            setRows(response);
        });
    };

    const showSingleReview = (review: IReviewData) => {
        setCurrentReview(review);

        history.push('/review/show-single');
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);

        setPage(0);
    };

    const renderData = () => {
        if (rows) {
            return rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: IReviewData) => (
                    <TableRow key={row.id}>
                        <TableCell
                            align='left'
                            onMouseDown={() => {
                                showSingleReview(row);
                            }}
                        >
                            {row.id}
                        </TableCell>
                        <TableCell
                            align='center'
                            onMouseDown={() => {
                                showSingleReview(row);
                            }}
                        >
                            {row.value}
                        </TableCell>
                        <TableCell
                            align='right'
                            onMouseDown={() => {
                                showSingleReview(row);
                            }}
                        >
                            {moment.utc(row.date).local().format('DD-MM-YYYY')}
                        </TableCell>
                    </TableRow>
                ));
        }
    };

    return (
        <div className='page-container'>
            <ThemeProvider theme={TableTheme}>
                <Paper>
                    <TableContainer
                        component={Paper}
                        sx={{ width: responsiveWidth }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>ID</TableCell>
                                    <TableCell align='center'>Value</TableCell>
                                    <TableCell align='right'>Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderData()}</TableBody>
                        </Table>
                    </TableContainer>
                    {rows ? (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component='div'
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    ) : (
                        <></>
                    )}
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default ApproveReviews;
