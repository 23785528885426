import React, { useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { IProductData } from '../../interfaces/ProductInterface';
import AddProduct from './AddProduct/AddProduct';
import AllProducts from './AllProducts/AllProducts';
import ShowSingleProduct from './ShowSingleProduct/ShowSingleProduct';

const ProductsPage = () => {
    const [selectedProduct, setSelectedProduct] = useState<IProductData | null>(
        null
    );

    const history = useHistory();

    const renderShowSingle = () => {
        if (selectedProduct) {
            return <ShowSingleProduct product={selectedProduct} />;
        }

        history.push('/product/show-all');
    };

    return (
        <div>
            <Route path='/product/show-all'>
                <AllProducts setCurrentProduct={setSelectedProduct} />
            </Route>
            <Route path='/product/add-product'>
                <AddProduct />
            </Route>
            <Route path='/product/show-single'>{renderShowSingle()}</Route>
        </div>
    );
};

export default ProductsPage;
