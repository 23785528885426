import { ThemeProvider } from '@emotion/react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { FC, useState } from 'react';
import { ALL_REASONS } from '../../util/constants/Reasons';
import { selectTheme } from '../../util/themes/GlobalThemeOverride';

interface RejectionReasonSelectProps {
    setValue: Function;
}

const RejectionReasonSelect: FC<RejectionReasonSelectProps> = (props) => {
    const { setValue } = props;

    const [selectedRejectionReason, setSelectedRejectionReason] =
        useState<string>('');

    const renderSelectData = () => {
        const menuItems: JSX.Element[] = [];

        for (let key of Array.from(ALL_REASONS.keys())) {
            menuItems.push(
                <MenuItem key={key} value={key}>
                    {key}
                </MenuItem>
            );
        }

        return menuItems;
    };

    const handleSelectChange = () => (event: SelectChangeEvent) => {
        const reasonKey = event.target.value;

        setSelectedRejectionReason(reasonKey);

        setValue(ALL_REASONS.get(reasonKey));
    };

    return (
        <ThemeProvider theme={selectTheme}>
            <Select
                label='Select Rejection Reason'
                variant='filled'
                onChange={handleSelectChange()}
                value={selectedRejectionReason}
                sx={{ width: '40ch', marginBottom: '20px' }}
            >
                {renderSelectData()}
            </Select>
        </ThemeProvider>
    );
};

export default RejectionReasonSelect;
