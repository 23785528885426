import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { createContext, FC, useContext } from 'react';
import { WebSocketContext } from '.';

import config from '../config/config';
import { ILoginData } from '../interfaces/LoginInterface';
import { RoleEnum } from '../util/enums/Role';

interface IAuthenticationContext {
    getToken(): string;
    getShopLocationId(): number;
    login(data: ILoginData): Promise<boolean | null>;
    logout(): void;
    getEmail(): string;
    getRole(): string | null;
}

const AuthenticationContext = createContext({} as IAuthenticationContext);

interface AuthenticationContextProps {
    children: React.ReactNode;
}

const AuthenticationContextProvider: FC<AuthenticationContextProps> = (
    props
) => {

    const { orderHubConnection } = useContext(WebSocketContext);

    const saveToken = (token: string) => {
        localStorage.setItem('@token', token);
    };

    const removeToken = () => {
        localStorage.removeItem('@token');
    };

    const saveShopLocationId = (shopLocationId: number | null) => {
        if (!shopLocationId) {
            return;
        }

        localStorage.setItem('@shopLocationId', shopLocationId.toString());
    };

    const getShopLocationId = () => {
        const idString = localStorage.getItem('@shopLocationId');

        if (idString === '' || idString === null) {
            return -1;
        }

        return Number.parseInt(idString);
    };

    const getToken = () => {
        const token = localStorage.getItem('@token');

        if (token === null || token === undefined) {
            return '';
        }

        return token;
    };

    const getEmail = () => {
        const email = localStorage.getItem('@email');

        if (email === '' || email === null) {
            return '';
        }

        return email;
    };

    const saveEmail = (email: string) => {
        localStorage.setItem('@email', email);
    };

    const removeEmail = () => {
        localStorage.removeItem('@email');
    };

    const removeShopLocationId = () => {
        localStorage.removeItem('@shopLocationId');
    };

    const login = (data: ILoginData) => {
        return axios
            .create()({
                method: 'POST',
                url: `${config.backendUrl}/api/auth/employeelogin`,
                headers: { 'Content-Type': 'application/json' },
                data
            })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                saveToken(response.data['result']['token']);

                saveShopLocationId(
                    response.data['result']['employee']['shopLocationId']
                );

                saveEmail(response.data['result']['employee']['email']);

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const logout = async () => {
        await orderHubConnection.stop()
        
        removeToken();

        removeShopLocationId();

        removeEmail();

        window.location.reload();
    };

    const isLoggedIn = () => {
        const token = localStorage.getItem('@token');

        const email = localStorage.getItem('@email');

        if (token && email) {
            return true;
        }

        return false;
    };

    const getRole = (): string | null => {
        if (!isLoggedIn()) {
            return null;
        }

        if (getShopLocationId() > 0) {
            return RoleEnum[1];
        }

        return RoleEnum[0];
    };

    const providerValue = {
        getToken,
        getShopLocationId,
        login,
        logout,
        getEmail,
        getRole
    };

    return (
        <AuthenticationContext.Provider value={providerValue}>
            {props.children}
        </AuthenticationContext.Provider>
    );
};

export { AuthenticationContext, AuthenticationContextProvider };
