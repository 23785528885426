import { ThemeProvider } from '@emotion/react';
import { SvgIconComponent } from "@mui/icons-material";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { ArticleCategoryEnum } from "../../util/enums/ArticleCategory";
import { printArticleCategory } from '../../util/functions/ArticleCategory';
import { selectTheme } from '../../util/themes/GlobalThemeOverride';

interface ArticleCategorySelectProps {
    iconComponent: SvgIconComponent | undefined;
	setValue: Function;
	selectedValue: number;
}


const ArticleCategorySelect: FC<ArticleCategorySelectProps> = (props) => {
    const { iconComponent, setValue, selectedValue } = props;

    const [selectedArticleCategoryValue, setSelectedArticleCategoryValue] = useState<string>('');

    const handleSelectChange = () => (event: SelectChangeEvent) => {
        const numberValue = Number(event.target.value);

        setValue(numberValue);
        setSelectedArticleCategoryValue(event.target.value);
    }

    useEffect(() => {
        setSelectedArticleCategoryValue(selectedValue.toString())
    }, []);

    return (
        <ThemeProvider theme={selectTheme}>
			<Select
				label='Select article category'
				variant='filled'
				onChange={handleSelectChange()}
				IconComponent={iconComponent}
				value={selectedArticleCategoryValue}
                sx={{marginBottom: '20px'}}
			>
				<MenuItem key={0} value={0}>
                    {printArticleCategory(ArticleCategoryEnum.CAFE_STORY)}
                </MenuItem>
                
				<MenuItem key={1} value={1}>
                    {printArticleCategory(ArticleCategoryEnum.TUTORIALS)}
                </MenuItem>
			</Select>
		</ThemeProvider>
    )
}

export default ArticleCategorySelect;
