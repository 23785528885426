import { ThemeProvider } from '@emotion/react';
import { Button, Paper, TextField } from '@mui/material';
import { styled } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import ProductCategorySelect from '../../../components/ProductCategorySelect/ProductCategorySelect';
import { AlertContext, ProductSubCategoryContext } from '../../../contexts';
import { IProductCategoryData } from '../../../interfaces/ProductCategoryInterface';
import {
	IProductSubCategoryCreationData,
	IProductSubCategoryData
} from '../../../interfaces/ProductSubCategoryInterface';
import { FormTheme } from '../../../util/themes/GlobalThemeOverride';

const AddSubCategory = () => {
	const { showAlert } = useContext(AlertContext);

	const { addSubcategory } = useContext(ProductSubCategoryContext);

	const [values, setValues] = useState<IProductSubCategoryCreationData>({
		id: 0,
		englishName: '',
		serbianName: '',
		categoryId: 0,
		createdAt: new Date(),
		updatedAt: new Date()
	});

	const [chosenCategory, setChosenCategory] = useState<IProductCategoryData>();

	const [enableButton, setEnableButton] = useState<boolean>(false);

	const validateForm = (prop: keyof IProductSubCategoryCreationData, value: string) => {
		if (value == '') {
			return true;
		}

		return false;
	};

	useEffect(() => {
		if (!validateAll()) {
			setEnableButton(true);

			return;
		}

		setEnableButton(false);
	}, [values.serbianName, values.englishName, chosenCategory]);

	useEffect(() => {
		if (chosenCategory) {
			setValues({ ...values, categoryId: chosenCategory.id });
		}
	}, [chosenCategory]);

	const handleChange =
		(prop: keyof IProductSubCategoryCreationData) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({ ...values, [prop]: event.target.value });
		};

	const validateAll = () => {
		if (validateForm('serbianName', values.serbianName)) {
			return true;
		}

		if (validateForm('englishName', values.englishName)) {
			return true;
		}

		if (!chosenCategory) {
			return true;
		}

		return false;
	};

	const submitForm = () => {
		addSubcategory(values).then((response) => {
			if (!response) {
				showAlert({
					severity: 'error',
					show: true,
					content: 'Adding new SubCategory failed'
				});

				return;
			}

			showAlert({
				severity: 'success',
				show: true,
				content: 'Successfully added new SubCategory'
			});
		});
	};

	return (
		<div className='page-container add-form-container'>
			<ThemeProvider theme={FormTheme}>
				<Paper className='paper-form'>
					<h1>Add Sub Category</h1>
					<div className='form-container'>
						<TextField
							key='serbianName'
							label='SRB Name'
							value={values.serbianName}
							onChange={handleChange('serbianName')}
							error={validateForm('serbianName', values.serbianName)}
							helperText={
								validateForm('serbianName', values.serbianName) ? 'Serbian name is required' : ' '
							}
						/>

						<TextField
							key='englishName'
							label='ENG Name'
							value={values.englishName}
							onChange={handleChange('englishName')}
							error={validateForm('englishName', values.englishName)}
							helperText={
								validateForm('englishName', values.englishName) ? 'English name is required' : ' '
							}
						/>

						<ProductCategorySelect
							setValue={setChosenCategory}
							iconComponent={undefined}
							selectedValue={''}
						/>

						<Button
							variant='contained'
							component='label'
							sx={{ m: 1.5, width: '50ch' }}
							size='large'
							color='success'
							onMouseDown={submitForm}
							disabled={!enableButton}
						>
							Add
							<input type='button' hidden />
						</Button>
					</div>
				</Paper>
			</ThemeProvider>
		</div>
	);
};

export default AddSubCategory;
