import { ThemeProvider } from '@emotion/react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { FC } from 'react';
import { IProductHistoryData } from '../../interfaces/ProductInterface';
import { TableTheme } from '../../util/themes/GlobalThemeOverride';

interface ProductsTableProps {
	products: IProductHistoryData[];
	isModal: boolean;
}

const ProductsTable: FC<ProductsTableProps> = (props) => {
	const { products, isModal } = props;

	const printGrindOption = (grindOptionNumber: number) : string => {
		switch (grindOptionNumber){
			case 0:
				return 'WholeBeans';
			case 1:
				return 'EspressoMochaGrind';
			case 2:
				return 'PaperFilterGrind';
			case 3:
				return 'MetalFilterGrind';
			case 4:
				return 'FrenchPressCafetieraGring';
			case 5:
				return 'FineGrind';
			default:
				return ''
		}
	}

	const renderData = () => {
		return products.map((product: IProductHistoryData) => {
			return (
				<TableRow key={product.productId.toString() + product.sizeLabel}>
					<TableCell align='left'>{product.productName}</TableCell>

					<TableCell align='center'>{product.sizeLabel}</TableCell>

					<TableCell align='center'>{product.amount}</TableCell>

					<TableCell align='right'>{printGrindOption(product.grindOption)}</TableCell>
				</TableRow>
			);
		});
	};

	return (
		<div className='inner-table-products'>
			<ThemeProvider theme={TableTheme}>
				<TableContainer component={Paper} sx={isModal? { maxHeight: 440 }: {}}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell align='left'>Product Name</TableCell>
								
								<TableCell align='center'>Size Label</TableCell>

								<TableCell align='center'>Amount</TableCell>

								<TableCell align='right'>Grind option</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>{renderData()}</TableBody>
					</Table>
				</TableContainer>
			</ThemeProvider>
		</div>
	);
};

export default ProductsTable;
