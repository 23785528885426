export enum PaymentMethod {
    UNDEFINED,
    CASH,
    CARD
}

export function printPaymentMethod(method: number): string {
    if(method === PaymentMethod.CASH){
        return "CASH";
    }

    if(method === PaymentMethod.CARD){
        return "CARD"
    }

    return "UNDEFINED"
}