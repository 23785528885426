import { ThemeProvider } from '@emotion/react';
import { Button, Paper, TextField } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LanguageSelection from '../../../components/LanguageSelection/LanguageSelection';
import ProductCategorySelect from '../../../components/ProductCategorySelect/ProductCategorySelect';
import {
    AlertContext,
    ProductCategoryContext,
    ProductSubCategoryContext
} from '../../../contexts';
import { IProductCategoryData } from '../../../interfaces/ProductCategoryInterface';
import { IProductSubCategoryData } from '../../../interfaces/ProductSubCategoryInterface';
import { FormTheme } from '../../../util/themes/GlobalThemeOverride';

interface ShowSingleSubCategoryProps {
    subCategory: IProductSubCategoryData;
}

const ShowSingleSubCategory: FC<ShowSingleSubCategoryProps> = (props) => {
    const { subCategory } = props;

    const { showAlert } = useContext(AlertContext);

    const { updateSubcategory, getById } = useContext(
        ProductSubCategoryContext
    );

    const { categories } = useContext(ProductCategoryContext);

    const [values, setValues] = useState<IProductSubCategoryData>(subCategory);

    const [chosenCategory, setChosenCategory] =
        useState<IProductCategoryData>();

    const [enableButton, setEnableButton] = useState<boolean>(false);

    const [languageChanged, setLanguageChanged] = useState<boolean>(false);

    const history = useHistory();

    const validateForm = (
        prop: keyof IProductSubCategoryData,
        value: string
    ) => {
        if (value == '') {
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (!validateAll()) {
            setEnableButton(true);

            return;
        }

        setEnableButton(false);
    }, [values.name, chosenCategory]);

    useEffect(() => {
        if (chosenCategory) {
            setValues({ ...values, categoryId: chosenCategory.id });
        }
    }, [chosenCategory]);

    useEffect(() => {
        if (languageChanged) {
            loadData();

            setLanguageChanged(false);
        }
    }, [languageChanged]);

    const loadData = () => {
        getById(values.id).then((response) => {
            if (response === null) {
                showAlert({
                    severity: 'error',

                    show: true,
                    content: 'Cannot get data from server'
                });

                history.push('/category/show-all');

                return;
            }

            setValues(response);
        });
    };

    const handleChange =
        (prop: keyof IProductSubCategoryData) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const validateAll = () => {
        if (validateForm('name', values.name)) {
            return true;
        }

        return false;
    };

    const getCategoryNameFromId = (categoryId: number) => {
        if (categories) {
            for (let category of categories) {
                if (category.id === categoryId) {
                    return category.name;
                }
            }
        }

        return '';
    };

    const submitForm = () => {
        updateSubcategory(values).then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Adding new SubCategory failed'
                });
                return;
            }
            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully added new SubCategory'
            });
        });
    };

    return (
        <div className='page-container add-form-container'>
            <ThemeProvider theme={FormTheme}>
                <Paper className='paper-form'>
                    <h1>Add Sub Category</h1>
                    <div className='form-container'>
                        <LanguageSelection setIsChanged={setLanguageChanged} />

                        <TextField
                            key='name'
                            label='Name'
                            value={values.name}
                            onChange={handleChange('name')}
                            error={validateForm('name', values.name)}
                            helperText={
                                validateForm('name', values.name)
                                    ? 'Name is required'
                                    : ' '
                            }
                        />

                        <ProductCategorySelect
                            setValue={setChosenCategory}
                            iconComponent={undefined}
                            selectedValue={getCategoryNameFromId(
                                values.categoryId
                            )}
                        />

                        <Button
                            variant='contained'
                            component='label'
                            sx={{ m: 1.5, width: '50ch' }}
                            size='large'
                            color='success'
                            onMouseDown={submitForm}
                            disabled={!enableButton}
                        >
                            Update
                            <input type='button' hidden />
                        </Button>
                    </div>
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default ShowSingleSubCategory;
