import { Button, styled, TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { ISectionTempData } from '../../interfaces/ArticleSectionInterface';

const Input = styled('input')({
    display: 'none'
});

export interface AddSectionProps {
    index: number;
    setSectionValue: Function;
    deleteSection: Function;
    receivedValue: ISectionTempData | null;
}

const AddSection: FC<AddSectionProps> = (props) => {
    const { index, setSectionValue, deleteSection, receivedValue } = props;

    interface State {
        text: string | undefined;
        image: File | null;
    }

    const [values, setValues] = useState<State>({ text: '', image: null });

    const [previewImageSection, setPreviewImageSection] = useState<string>('');

    const [chosenInputValue, setChosenInputValue] = useState<keyof State>();

    useEffect(() => {
        if (receivedValue) {
            if (receivedValue.text) {
                setValues({ text: receivedValue.text, image: null });
            }
        }
    }, []);

    const handleChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (prop !== 'image') {
                const text = event.target.value;
                
                setValues({ text: text, image: null });

                setSectionValue(index, text);

                return;
            }

            if (event.target.files) {
                const file = event.target.files[0];

                setValues({ text: '', image: file });

                setSectionValue(index, file);

                fileToBase64(file);
            }
        };

    const fileToBase64 = async (file: File) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            let base64data: string = reader.result as string;

            setPreviewImageSection(base64data);
        };

        reader.readAsDataURL(file);
    };

    const renderPhotoSection = () => {
        return getMediaTag();
    };

    const getMediaTag = () => {
        if (values.image) {
            const tokens = values.image.name.split('.');

            if (tokens) {
                const extension = tokens[tokens?.length - 1];

                if (extension === 'mp4') {
                    return (
                        <video
                            className='image-preview section-image'
                            controls
                            muted
                        >
                            <source
                                src={`${previewImageSection}`}
                                type='video/mp4'
                            />
                        </video>
                    );
                }

                return (
                    <img
                        className='image-preview section-image'
                        src={`${previewImageSection}`}
                        alt='preview'
                    />
                );
            }
        }

        if (receivedValue) {
            if(receivedValue.imageUrl){
                const tokens = receivedValue.imageUrl.split('.');

                if (tokens) {
                    const extension = tokens[tokens?.length - 1];
    
                    if (extension === 'mp4') {
                        return (
                            <video
                                className='image-preview section-image'
                                controls
                                muted
                            >
                                <source
                                    src={`${receivedValue?.imageUrl}`}
                                    type='video/mp4'
                                />
                            </video>
                        );
                    }
    
                    return (
                        <img
                            className='image-preview section-image'
                            src={`${receivedValue?.imageUrl}`}
                            alt='preview'
                        />
                    );
                }
            }
        }

        return <></>;
    };

    const chooseInput = (prop: keyof State) => {
        setChosenInputValue(prop);
    };

    const renderInput = () => {
        if (chosenInputValue) {
            if (chosenInputValue === 'text') {
                return (
                    <TextField
                        key={`text-${index}`}
                        label='Text'
                        value={values.text}
                        onChange={handleChange('text')}
                        multiline
                        rows={5}
                        sx={{ marginTop: '15px', alignSelf: 'center' }}
                    />
                );
            } else {
                return (
                    <div className='image-input-container'>
                        <label
                            htmlFor={`contained-button-file-section${index}`}
                        >
                            <Input
                                accept='.mp4, .png, .jpg, .jpeg'
                                id={`contained-button-file-section${index}`}
                                multiple
                                type='file'
                                key='section-image'
                                onChange={handleChange('image')}
                            />

                            <Button
                                variant='contained'
                                component='span'
                                sx={{ m: 1.5, width: '50ch' }}
                            >
                                Upload Section Image
                            </Button>
                        </label>

                        {renderPhotoSection()}
                    </div>
                );
            }
        }

        if (receivedValue?.text) {
            return (
                <TextField
                    key={`text-${index}`}
                    label='Text'
                    value={values.text}
                    onChange={handleChange('text')}
                    multiline
                    rows={5}
                    sx={{ marginTop: '15px', alignSelf: 'center' }}
                />
            );
        }

        if (receivedValue?.imageUrl) {
            return (
                <div className='image-input-container'>
                    <label htmlFor={`contained-button-file-section${index}`}>
                        <Input
                            id={`contained-button-file-section${index}`}
                            multiple
                            type='file'
                            accept='.mp4, .png, .jpg, .jpeg'
                            key='section-image'
                            onChange={handleChange('image')}
                        />

                        <Button
                            variant='contained'
                            component='span'
                            sx={{ m: 1.5, width: '50ch' }}
                        >
                            Upload Section Image
                        </Button>
                    </label>

                    {renderPhotoSection()}
                </div>
            );
        }

        return <></>;
    };

    return (
        <div className='single-section-data-container'>
            <div className='buttons-container'>
                <Button
                    onMouseDown={() => {
                        chooseInput('text');
                    }}
                    variant='contained'
                    component='span'
                    sx={{ m: 0.5, width: '15ch' }}
                >
                    Add Text
                </Button>

                <Button
                    onMouseDown={() => {
                        chooseInput('image');
                    }}
                    variant='contained'
                    component='span'
                    sx={{ m: 0.5, width: '15ch' }}
                >
                    Add Image
                </Button>

                <Button
                    onMouseDown={() => {
                        deleteSection(index);
                    }}
                    variant='contained'
                    component='span'
                    color='error'
                    sx={{ m: 0.5, width: '15ch' }}
                >
                    Remove
                </Button>
            </div>

            {renderInput()}
        </div>
    );
};

export default AddSection;
