import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import DefaultPage from '../DefaultPage/DefaultPage';
import AddEmployee from './AddEmployee/AddEmployee';
import AllEmployees from './AllEmployees/AllEmployees';

const EmployeePage = () => {
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    return (
        <div>
            <Route exact path='/employee/add-employee'>
                <AddEmployee />
            </Route>
            <Route exact path='/employee/show-all'>
                <AllEmployees />
            </Route>
        </div>
    );
};

export default EmployeePage;
