import { AxiosError, AxiosResponse } from 'axios';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { AlertContext, AuthenticationContext, AxiosContext } from '.';
import { IOnboardingAnswerTypeData } from '../interfaces/OnboardingAnswerTypeInterface';

interface IOnboardingAnswersTypesContext {
    getFlavours(): IOnboardingAnswerTypeData[];
    getTypesOfCoffee(): IOnboardingAnswerTypeData[];
}

const OnboardingAnswersTypesContext = createContext(
    {} as IOnboardingAnswersTypesContext
);

interface OnboardingAnswersTypesContextProps {
    children: React.ReactNode;
}

const OnboardingAnswersTypesContextProvider: FC<OnboardingAnswersTypesContextProps> =
    (props) => {
        const { axiosInstance } = useContext(AxiosContext);

        const { showAlert } = useContext(AlertContext);

        const { getToken } = useContext(AuthenticationContext);

        const [flavours, setFlavours] = useState<IOnboardingAnswerTypeData[]>(
            []
        );

        const [typesOfCoffee, setTypesOfCoffee] = useState<
            IOnboardingAnswerTypeData[]
        >([]);

        useEffect(() => {
            if (!getToken()) {
                return;
            }

            getAllFlavours().then((response) => {
                if (response === null) {
                    showAlert({
                        severity: 'error',
                        show: true,
                        content: 'Cannot get data from server'
                    });

                    return;
                }

                setFlavours(response);

                return response;
            });

            getAllTypesOfCoffee().then((response) => {
                if (response === null) {
                    showAlert({
                        severity: 'error',
                        show: true,
                        content: 'Cannot get data from server'
                    });

                    return;
                }

                setTypesOfCoffee(response);

                return response;
            });
        }, []);

        const getFlavours = () => {
            return flavours;
        };

        const getTypesOfCoffee = () => {
            return typesOfCoffee;
        };

        const getAllFlavours = () => {
            return axiosInstance({
                method: 'GET',
                url: 'api/flavour/getall',
                headers: {
                    Authorization: 'Bearer '.concat(getToken())
                }
            })
                .then((response: AxiosResponse) => {
                    if (response === undefined) {
                        return null;
                    }

                    return response.data;
                })

                .catch((error: AxiosError) => {
                    return null;
                });
        };

        const getAllTypesOfCoffee = () => {
            return axiosInstance({
                method: 'GET',
                url: 'api/typeofcoffee/getall',
                headers: {
                    Authorization: 'Bearer '.concat(getToken())
                }
            })
                .then((response: AxiosResponse) => {
                    if (response === undefined) {
                        return null;
                    }

                    return response.data;
                })

                .catch((error: AxiosError) => {
                    return null;
                });
        };

        const providerValue = {
            getFlavours,
            getTypesOfCoffee
        };

        return (
            <OnboardingAnswersTypesContext.Provider value={providerValue}>
                {props.children}
            </OnboardingAnswersTypesContext.Provider>
        );
    };

export { OnboardingAnswersTypesContext, OnboardingAnswersTypesContextProvider };
