export interface ITag {
    id: number;
    name: string;
    object: any;
}

export class TagType {
    public static readonly AvailableCategories = 'AvailableCategories';
    public static readonly Flavours = 'Flavours';
    public static readonly Types = 'Types';
    public static readonly Sizes = 'Sizes';
}