import { AxiosError, AxiosResponse } from 'axios';
import { createContext, FC, useContext } from 'react';
import { AuthenticationContext, AxiosContext } from '.';
import { IAddPointsToUserData, IUserData } from '../interfaces/UserInterface';

interface IUserContext {
    getAllUsers(): Promise<IUserData[] | null>;
    addPointsById(data: IAddPointsToUserData): Promise<boolean>;
}

const UserContext = createContext({} as IUserContext);

interface UserContextProps {
    children: React.ReactNode;
}

const UserContextProvider: FC<UserContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const { getToken } = useContext(AuthenticationContext);

    const getAllUsers = () => {
        return axiosInstance({
            method: 'GET',
            url: 'api/user/getall',
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const addPointsById = (data: IAddPointsToUserData) => {
        return axiosInstance({
            method: 'PUT',
            url: `api/user/addpointsbyid`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            },
            data
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const providerValue = {
        getAllUsers,
        addPointsById
    };

    return (
        <UserContext.Provider value={providerValue}>
            {props.children}
        </UserContext.Provider>
    );
};

export { UserContext, UserContextProvider };
