import { ThemeProvider } from '@emotion/react';
import { Button, Paper, styled, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AlertContext, ProductCategoryContext } from '../../../contexts';
import { IProductCategoryCreationData } from '../../../interfaces/ProductCategoryInterface';
import { FormTheme } from '../../../util/themes/GlobalThemeOverride';

const Input = styled('input')({
    display: 'none'
});

const AddCategory = () => {
    const { showAlert } = useContext(AlertContext);

    const { addCategory } = useContext(ProductCategoryContext);

    const [values, setValues] = useState<IProductCategoryCreationData>({
        id: 0,
        englishName: '',
        serbianName: '',
        imageUrl: '',
        iconUrl: '',
        pointsAmount: 0,
        createdAt: new Date(),
        updatedAt: new Date()
    });

    const [image, setImage] = useState<File | null>(null);

    const [previewImage, setPreviewImage] = useState<string>('');

    const [icon, setIcon] = useState<File | null>(null);

    const [previewIcon, setPreviewIcon] = useState<string>('');

    const [enableButton, setEnableButtion] = useState<boolean>(false);

    useEffect(() => {
        if (!validateAll()) {
            setEnableButtion(true);

            return;
        }

        setEnableButtion(false);
    }, [values.serbianName, values.englishName, image, icon]);

    const validateForm = (
        prop: keyof IProductCategoryCreationData,
        value: string
    ) => {
        if (value == '') {
            return true;
        }

        return false;
    };

    const validateAll = () => {
        if (validateForm('serbianName', values.serbianName)) {
            return true;
        }

        if (validateForm('englishName', values.englishName)) {
            return true;
        }

        return false;
    };

    const handleChange =
        (prop: keyof IProductCategoryCreationData) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleImageChange =
        () => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                const file = event.target.files[0];

                setImage(file);

                fileToBase64(file, true);
            }
        };

    const handleIconChange =
        () => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                const file = event.target.files[0];

                setIcon(file);

                fileToBase64(file, false);
            }
        };

    const renderImage = () => {
        if (image) {
            return (
                <img
                    className='image-preview'
                    src={`${previewImage}`}
                    alt='preview'
                />
            );
        }

        return <></>;
    };

    const renderIcon = () => {
        if (icon) {
            return (
                <img
                    className='icon-preview'
                    src={`${previewIcon}`}
                    alt='preview'
                />
            );
        }

        return <></>;
    };

    const fileToBase64 = async (file: File | undefined, isImage:boolean) => {
        if(file !== undefined){
            const reader = new FileReader();

            reader.onloadend = () => {
                let base64data: string = reader.result as string;
    
                if (isImage){
                    setPreviewImage(base64data);
                    
                    return;
                }
    
                setPreviewIcon(base64data);
    
            };
    
            reader.readAsDataURL(file);

            return;
        }
        
        if(isImage){
            setImage(null);

            setPreviewImage('');

            return;
        }

        setIcon(null);

        setPreviewIcon('');
    };

    const submitForm = () => {
        let formData = new FormData();

        formData.append('data', JSON.stringify(values));

        if (image) {
            formData.append('Images[]', image, `Image${image.name}`);
        }

        if (icon) {
            formData.append('Images[]', icon, `Icon${icon.name}`);
        }

        addCategory(formData).then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Adding new Category failed'
                });

                return;
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully added new Category'
            });
        });
    };

    return (
        <div className='page-container add-form-container'>
            <ThemeProvider theme={FormTheme}>
                <Paper className='paper-form'>
                    <h1>Add Category</h1>
                    <div className='form-container'>
                        <TextField
                            key='serbianName'
                            label='SRB Name'
                            value={values.serbianName}
                            onChange={handleChange('serbianName')}
                            error={validateForm(
                                'serbianName',
                                values.serbianName
                            )}
                            helperText={
                                validateForm('serbianName', values.serbianName)
                                    ? 'Serbian name is required'
                                    : ' '
                            }
                        />
                        <TextField
                            key='englishName'
                            label='ENG Name'
                            value={values.englishName}
                            onChange={handleChange('englishName')}
                            error={validateForm(
                                'englishName',
                                values.englishName
                            )}
                            helperText={
                                validateForm('englishName', values.englishName)
                                    ? 'English name is required'
                                    : ' '
                            }
                        />

                        <div className='image-entry-container'>
                            <div className='image-upload-container-addCategory'>
                                <label htmlFor='contained-button-image'>
                                    <Input
                                        accept='.png, .jpg, .jpeg'
                                        id='contained-button-image'
                                        multiple
                                        type='file'
                                        key='categoryImage'
                                        onChange={handleImageChange()}
                                    />

                                    <Button
                                        variant='contained'
                                        component='span'
                                        sx={{ m: 1.5, width: '25ch' }}
                                    >
                                        Upload Image
                                    </Button>
                                </label>

                                {renderImage()}
                            </div>

                            <div className='icon-upload-container-addCategory'>
                                <label htmlFor='contained-button-icon'>
                                    <Input
                                        accept='.png, .jpg, .jpeg'
                                        id='contained-button-icon'
                                        multiple
                                        type='file'
                                        key='categoryIcon'
                                        onChange={handleIconChange()}
                                    />

                                    <Button
                                        variant='contained'
                                        component='span'
                                        sx={{ m: 1.5, width: '25ch' }}
                                    >
                                        Upload Icon
                                    </Button>
                                </label>

                                {renderIcon()}
                            </div>
                        </div>

                        <Button
                            variant='contained'
                            component='label'
                            sx={{ m: 1.5, width: '50ch' }}
                            size='large'
                            color='success'
                            onMouseDown={submitForm}
                            disabled={!enableButton}
                        >
                            Add
                            <input type='button' hidden />
                        </Button>
                    </div>
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default AddCategory;
