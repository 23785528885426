import { ThemeProvider } from "@emotion/react";
import FilterListIcon from "@mui/icons-material/FilterList";
import Modal from "react-modal";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import ProductCategorySelect from "../../../components/ProductCategorySelect/ProductCategorySelect";
import { ProductContext, AlertContext } from "../../../contexts";
import { IProductCategoryData } from "../../../interfaces/ProductCategoryInterface";
import { IProductData } from "../../../interfaces/ProductInterface";
import { TableTheme } from "../../../util/themes/GlobalThemeOverride";
import { responsiveWidth } from "../../../util/themes/TableWidth";

interface AllProductsProps {
  setCurrentProduct: Function;
}

const AllProducts: FC<AllProductsProps> = (props) => {
  const { setCurrentProduct } = props;

  const { getAllProducts, deleteProduct } = useContext(ProductContext);

  const { showAlert } = useContext(AlertContext);

  const [rows, setRows] = useState<IProductData[] | null>([]);

  const [fullData, setFullData] = useState<IProductData[] | null>([]);

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [page, setPage] = useState<number>(0);

  const [rerender, setRerender] = useState<boolean>(false);

  const [selectedFilterCategory, setSelectedFilterCategory] =
    useState<IProductCategoryData | null>(null);

  const [entityToDeleteId, setEntityToDeleteId] = useState<number>(0);

  const history = useHistory();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (rerender) {
      loadData();

      setRerender(false);
    }
  }, [rerender]);

  useEffect(() => {
    if (!fullData) {
      return;
    }

    if (selectedFilterCategory !== null) {
      let filteredData: IProductData[] = [];

      for (let row of fullData) {
        if (row.categoryName === selectedFilterCategory.name) {
          filteredData.push(row);
        }
      }

      setRows(filteredData);

      return;
    }

    setRows(fullData);
  }, [fullData, selectedFilterCategory]);

  const loadData = () => {
    getAllProducts().then((response) => {
      if (!response) {
        showAlert({
          severity: "error",
          show: true,
          content: "Can't get data from server",
        });

        return;
      }

      setFullData(response);
    });
  };

  const handleDelete = () => {
    if (entityToDeleteId > 0) {
      deleteProduct(entityToDeleteId).then((response) => {
        if (!response) {
          showAlert({
            severity: "error",
            show: true,
            content: "Deleting proudct failed",
          });

          setEntityToDeleteId(0);

          return;
        }

        showAlert({
          severity: "success",
          show: true,
          content: "Successfully deleted product",
        });

        setEntityToDeleteId(0);
        setRerender(true);
      });
    }
  };

  const showSingleProduct = (product: IProductData) => {
    setCurrentProduct(product);

    history.push("/product/show-single");
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);

    setPage(0);
  };

  const renderData = () => {
    if (rows) {
      return rows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row: IProductData) => (
          <TableRow key={row.id}>
            <TableCell
              align="left"
              onMouseDown={() => {
                showSingleProduct(row);
              }}
            >
              {row.id}
            </TableCell>

            <TableCell
              align="center"
              onMouseDown={() => {
                showSingleProduct(row);
              }}
            >
              {row.name}
            </TableCell>

            <TableCell
              align="center"
              onMouseDown={() => {
                showSingleProduct(row);
              }}
            >
              {row.subcategoryName}
            </TableCell>

            <TableCell align="center">
              <Button
                variant="contained"
                component="span"
                color="error"
                onMouseDown={() => {
                  setEntityToDeleteId(row.id);
                }}
              >
                Delete
                <input type="button" hidden />
              </Button>
            </TableCell>
          </TableRow>
        ));
    }
  };

  return (
    <div className="page-container">
      <ThemeProvider theme={TableTheme}>
        <Paper
          className="table-page-toolbar"
          sx={{ display: "flex", backgroundColor: "#6b6b6b" }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              history.push("/product/add-product");
            }}
          >
            Add new Product
            <input type="button" hidden />
          </Button>

          <ProductCategorySelect
            setValue={setSelectedFilterCategory}
            iconComponent={FilterListIcon}
            selectedValue=""
          />
        </Paper>

        <Paper>
          <TableContainer component={Paper} sx={{ width: responsiveWidth }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">ID</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Subcategory</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderData()}</TableBody>
            </Table>
          </TableContainer>

          {rows ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </Paper>
      </ThemeProvider>
      <Modal
        isOpen={entityToDeleteId > 0}
        onRequestClose={() => setEntityToDeleteId(0)}
        ariaHideApp={false}
		className='modal-confirm'
      >
        <DeleteConfirmationModal
          deleteFunction={handleDelete}
          entityDeleteType="Product"
          closeModalFunction={setEntityToDeleteId}
        />
      </Modal>
    </div>
  );
};

export default AllProducts;
