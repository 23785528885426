import { ThemeProvider } from '@emotion/react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { AlertContext, OnboardingAnswersTypesContext } from '../../contexts';
import { IOnboardingAnswerTypeData } from '../../interfaces/OnboardingAnswerTypeInterface';
import { ONBOARDING_ANSWER_FLAVOURS_NAME } from '../../util/constants/Constants';
import { selectTheme } from '../../util/themes/GlobalThemeOverride';

interface OnboardingAnswersTypeSelectProps {
    typeName: string;
    setValue: Function;
}

const OnboardingAnswersTypeSelect: FC<OnboardingAnswersTypeSelectProps> = (
    props
) => {
    const { setValue, typeName } = props;

    const { getFlavours, getTypesOfCoffee } = useContext(
        OnboardingAnswersTypesContext
    );

    const [answers, setAnswers] = useState<
        IOnboardingAnswerTypeData[] | null
    >();

    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
        if (typeName === ONBOARDING_ANSWER_FLAVOURS_NAME) {
            setAnswers(getFlavours());

            return;
        }

        setAnswers(getTypesOfCoffee());
    }, []);

    const handleSelectChange = () => (event: SelectChangeEvent) => {
        const name = event.target.value;

        setSelectedValue(name);

        answers?.map((element: IOnboardingAnswerTypeData) => {
            if (element.name === name) {
                setValue(element);
            }
        });
    };

    const renderSelectData = () => {
        if (answers) {
            return answers.map((answer: IOnboardingAnswerTypeData) => (
                <MenuItem key={answer.id} value={answer.name}>
                    {answer.name}
                </MenuItem>
            ));
        }
    };

    return (
        <ThemeProvider theme={selectTheme}>
            <Select
                label={`Select ${typeName}`}
                variant='filled'
                onChange={handleSelectChange()}
                value={selectedValue}
                sx={{ width: '200px' }}
            >
                <MenuItem key={0} value={''} sx={{ height: '35.63px' }}>
                    {' '}
                </MenuItem>
                {renderSelectData()}
            </Select>
        </ThemeProvider>
    );
};

export default OnboardingAnswersTypeSelect;
