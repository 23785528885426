import axios, { AxiosInstance, AxiosResponse } from 'axios';
import React, { createContext, FC } from 'react';

import config from '../config/config';

interface IAxtiosContext {
    axiosInstance: AxiosInstance;
}

const AxiosContext = createContext({} as IAxtiosContext);

interface AxiosContextProps {
    children: React.ReactNode;
}

const AxiosContextProvider: FC<AxiosContextProps> = (props) => {
    const axiosInstance = axios.create({
        baseURL: config.backendUrl,

        validateStatus: () => true
    });

    const handleEndLoader = (response: AxiosResponse) => {
        //console.log('hangleendLoader response', response);

        if (response.status > 399) {
            return Promise.reject(response);
        }

        return response;
    };

    axiosInstance.interceptors.response.use((response) =>
        handleEndLoader(response)
    );

    const providerValue = {
        axiosInstance
    };

    return (
        <AxiosContext.Provider value={providerValue}>
            {props.children}
        </AxiosContext.Provider>
    );
};

export { AxiosContext, AxiosContextProvider };
