import { ThemeProvider } from '@emotion/react';
import {
    Button,
    Checkbox,
    FormHelperText,
    InputLabel,
    Paper,
    styled,
    TextField
} from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import AddSize from '../../../components/AddSize/AddSize';
import ProductCategorySelect from '../../../components/ProductCategorySelect/ProductCategorySelect';
import ProductSubCategorySelect from '../../../components/ProductSubCategorySelect/ProductSubCategorySelect';
import TagsCollection from '../../../components/TagsCollection/TagsCollection';
import { AlertContext, ProductContext } from '../../../contexts';
import { IProductCategoryData } from '../../../interfaces/ProductCategoryInterface';
import { IProductSubCategoryData } from '../../../interfaces/ProductSubCategoryInterface';
import { ISizeData } from '../../../interfaces/SizeInterface';
import { TagType } from '../../../interfaces/TagsCollectionInterface';
import {
    GROUND_COFFEE_ID,
    WaysOfPreparation
} from '../../../util/constants/Constants';
import { FormTheme } from '../../../util/themes/GlobalThemeOverride';

const Input = styled('input')({
    display: 'none'
});

interface State {
    serbianName: string;
    englishName: string;
    sizes: Array<ISizeData>;
    intensity: number;
    subcategoryId: number;

    flavourTags: number[];
    typeOfCoffeeTags: number[];
    waysOfPreparation: number[];

    // Only ground coffee<
    serbianDescription: string;
    englishDescription: string;
    origin: string;
    plantationLocation: string;
    coffeeBeanType: string;
    serbianWaysOfRoasting: string;
    englishWaysOfRoasting: string;
    serbianWannaKnowMore: string;
    englishWannaKnowMore: string;
    neutrality: number;
    //>

    timesOrdered: number;

    image: File | null;
}

const AddProduct = () => {
    const { showAlert } = useContext(AlertContext);

    const { addProduct } = useContext(ProductContext);

    const [values, setValues] = useState<State>({
        serbianName: '',
        englishName: '',
        sizes: [],
        intensity: 0,
        subcategoryId: 0,

        flavourTags: [],
        typeOfCoffeeTags: [],
        waysOfPreparation: [],

        serbianDescription: '',
        englishDescription: '',
        origin: '',
        plantationLocation: '',
        coffeeBeanType: '',
        serbianWaysOfRoasting: '',
        englishWaysOfRoasting: '',
        serbianWannaKnowMore: '',
        englishWannaKnowMore: '',
        neutrality: 0,

        timesOrdered: 0,
        image: null
    });

    const [checked, setChecked] = useState<boolean>(false);

    const [waysOfPreparationChecked, setWaysOfPreparationChecked] = useState<boolean[]>([false,
                                                                                        false,
                                                                                        false,
                                                                                        false,
                                                                                        false,
                                                                                        false,
                                                                                        false]);

    const [previewImage, setPreviewImage] = useState<string>('');

    const [enableButton, setEnableButton] = useState<boolean>(false);

    const [selectedCategory, setSelectedCategory] =
        useState<IProductCategoryData | null>(null);

    const [selectedSubCategory, setSelectedSubCategory] =
        useState<IProductSubCategoryData | null>(null);

    const [selectedWaysOfPreparation, setSelectedWaysOfPreparation] =
        useState<number[]>([]);

    useEffect(() => {
        if (validateAll()) {
            setEnableButton(true);
        }
    }, [
        values.serbianName,
        values.englishName,
        values.sizes,
        values.subcategoryId
    ]);

    useEffect(() => {
        if (selectedSubCategory) {
            setValues({ ...values, subcategoryId: selectedSubCategory.id });
        }
    }, [selectedSubCategory]);

    useEffect(() => {
        setValues({...values, waysOfPreparation: selectedWaysOfPreparation});
    }, [selectedWaysOfPreparation])

    const handleChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (prop !== 'image') {
                setValues({ ...values, [prop]: event.target.value });
            }

            if (event.target.files) {
                const file = event.target.files[0];

                setValues({ ...values, image: file });

                fileToBase64(file);
            }
        };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setChecked(event.target.checked);
    };

    const validateForm = (
        prop: keyof State,
        value: string | number | ISizeData[]
    ) => {
        if (prop === 'sizes') {
            if (values.sizes.length === 0) {
                return true;
            }
        }

        if (prop !== 'image') {
            if (value === '' || value === 0) {
                return true;
            }
        }
    };

    const validateAll = () => {
        if (validateForm('serbianName', values.serbianName)) {
            return false;
        }

        if (validateForm('englishName', values.englishName)) {
            return false;
        }

        if (validateForm('sizes', values.sizes)) {
            return false;
        }

        if (validateForm('subcategoryId', values.subcategoryId)) {
            return false;
        }

        return true;
    };

    const renderPhoto = () => {
        if (values.image) {
            return (
                <img
                    className='image-preview'
                    src={`${previewImage}`}
                    alt='preview'
                />
            );
        }
    };

    const submitForm = () => {
        let formData = new FormData();

        formData.append(
            'data',
            JSON.stringify({
                serbianName: values.serbianName,
                englishName: values.englishName,
                sizes: values.sizes,
                intensity: values.intensity,
                subcategoryId: values.subcategoryId,
                flavourTags: values.flavourTags,
                typeOfCoffeeTags: values.typeOfCoffeeTags,

                waysOfpreparation: values.waysOfPreparation,
                isDeliverable: checked,

                serbianDescription: values.serbianDescription,
                englishDescription: values.englishDescription,
                origin: values.origin,
                plantationLocation: values.plantationLocation,
                coffeeBeanType: values.coffeeBeanType,
                serbianWaysOfRoasting: values.serbianWaysOfRoasting,
                englishWaysOfRoasting: values.englishWaysOfRoasting,
                serbianWannaKnowMore: values.serbianWannaKnowMore,
                englishWannaKnowMore: values.englishWannaKnowMore,
                neutrality: values.neutrality,

                timesOrdered: values.timesOrdered
            })
        );

        if (values.image) {
            formData.append('Images', values.image, values.image.name);
        }

        addProduct(formData).then((response: boolean) => {
            if (response === false) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Adding new product failed'
                });

                return;
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully added new product'
            });
        });
    };

    const fileToBase64 = async (file: File | undefined) => {
        if (file !== undefined) {
            const reader = new FileReader();

            reader.onloadend = () => {
                let base64data: string = reader.result as string;

                setPreviewImage(base64data);
            };

            reader.readAsDataURL(file);

            return;
        }

        setValues({ ...values, image: null });
    };

    const renderSubCategoryHelperText = () => {
        if (values.subcategoryId <= 0) {
            return (
                <FormHelperText sx={{ color: '#d32f2f' }}>
                    Subcategory is required
                </FormHelperText>
            );
        }

        return <FormHelperText sx={{ color: '#d32f2f' }}> </FormHelperText>;
    };

    const setValuesSize = (data: ISizeData[]) => {
        setValues({ ...values, sizes: data });
    };

    const setValuesTypes = (data: any[]) => {
        setValues({ ...values, typeOfCoffeeTags: data.map(e => e.id) });
    };

    const setValuesFlavours = (data: any[]) => {
        setValues({ ...values, flavourTags: data.map(e => e.id) });
    };

    const handleWaysOfPreparationCheckbox = (index: number, checked: boolean) => {
        let oldWaysList: number[] = JSON.parse(JSON.stringify(selectedWaysOfPreparation));
        let oldWaysChecks: boolean[] = JSON.parse(JSON.stringify(waysOfPreparationChecked));

        oldWaysChecks[index] = checked;

        setWaysOfPreparationChecked(oldWaysChecks);

        if (checked) {
            if (!oldWaysList.includes(index)) {
                oldWaysList.push(index);
            }
        }else {
            oldWaysList = oldWaysList.filter(e => (e !== index));
        }

        setSelectedWaysOfPreparation(oldWaysList);
    }

    return (
        <div className='page-container add-form-container add-product-container'>
            <ThemeProvider theme={FormTheme}>
                <div className='any-product-data'>
                    <Paper className='paper-form'>
                        <h1>Add Product</h1>
                        <div className='form-container'>
                            <TextField
                                key='serbianName'
                                label='SRB Name'
                                value={values.serbianName}
                                onChange={handleChange('serbianName')}
                                error={validateForm(
                                    'serbianName',
                                    values.serbianName
                                )}
                                helperText={
                                    validateForm(
                                        'serbianName',
                                        values.serbianName
                                    )
                                        ? 'Serbian name cannot be empty!'
                                        : ' '
                                }
                            ></TextField>

                            <TextField
                                key='englishName'
                                label='ENG Name'
                                value={values.englishName}
                                onChange={handleChange('englishName')}
                                error={validateForm(
                                    'englishName',
                                    values.englishName
                                )}
                                helperText={
                                    validateForm(
                                        'serbianName',
                                        values.englishName
                                    )
                                        ? 'English name cannot be empty!'
                                        : ' '
                                }
                            ></TextField>

                            <div className='inner-form-products'>
                                <AddSize
                                    setValue={setValuesSize}
                                    receivedSizes={null}
                                />
                            </div>

                            <div className='inner-form-products'>
                                <InputLabel
                                    sx={{
                                        marginBottom: '10px'
                                    }}
                                >
                                    Select Flavours
                                </InputLabel>
                                <TagsCollection
                                    tagType={TagType.Flavours}
                                    returnValue={setValuesFlavours}
                                    receivedTags={[]}
                                />
                            </div>

                            <div className='inner-form-products'>
                                <InputLabel
                                    sx={{
                                        marginBottom: '10px'
                                    }}
                                >
                                    Select Types Of Coffee
                                </InputLabel>

                                <TagsCollection
                                    tagType={TagType.Types} 
                                    returnValue={setValuesTypes} 
                                    receivedTags={[]}                                
                                />
                            </div>

                            <div className='checkbox-container'>
                                <InputLabel sx={{ top: '10px' }}>
                                    Is Deliverable
                                </InputLabel>

                                <Checkbox
                                    checked={checked}
                                    onChange={handleCheckboxChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color='primary'
                                />
                            </div>

                            <TextField
                                key='serbianDescription'
                                label='SRB Description'
                                value={values.serbianDescription}
                                onChange={handleChange(
                                    'serbianDescription'
                                )}
                            ></TextField>

                            <TextField
                                key='englishDescription'
                                label='ENG Description'
                                value={values.englishDescription}
                                onChange={handleChange(
                                    'englishDescription'
                                )}
                            ></TextField>

                            <div className='category-select-container'>
                                <div className='category-select-column'>
                                    <InputLabel
                                        sx={{
                                            marginBottom: '10px'
                                        }}
                                    >
                                        Select Category *
                                    </InputLabel>
                                    <ProductCategorySelect
                                        iconComponent={undefined}
                                        setValue={setSelectedCategory}
                                        selectedValue=''
                                    />
                                    {renderSubCategoryHelperText()}
                                </div>

                                <div className='category-select-column'>
                                    {selectedCategory ? (
                                        <>
                                            <InputLabel
                                                sx={{
                                                    marginBottom: '10px'
                                                }}
                                            >
                                                Select SubCategory *
                                            </InputLabel>
                                            <ProductSubCategorySelect
                                                iconComponent={undefined}
                                                setValue={
                                                    setSelectedSubCategory
                                                }
                                                categoryId={selectedCategory.id}
                                                selectedValue=''
                                            />
                                            {renderSubCategoryHelperText()}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            <div className='image-upload-container-addProduct'>
                                <label htmlFor='contained-button-file'>
                                    <Input
                                        accept='.png, .jpg, .jpeg'
                                        id='contained-button-file'
                                        multiple
                                        type='file'
                                        key='image'
                                        onChange={handleChange('image')}
                                    />

                                    <Button
                                        variant='contained'
                                        component='span'
                                        sx={{ m: 1.5, width: '50ch' }}
                                    >
                                        Upload Image
                                    </Button>
                                </label>

                                {renderPhoto()}
                            </div>

                            <Button
                                variant='contained'
                                component='label'
                                sx={{ m: 1.5, width: '50ch' }}
                                size='large'
                                color='success'
                                onMouseDown={submitForm}
                                disabled={!enableButton}
                            >
                                Add Product
                                <input type='button' hidden />
                            </Button>
                        </div>
                    </Paper>
                </div>
                {selectedCategory?.id === GROUND_COFFEE_ID ? (
                    <div className='ground-coffee-data'>
                        <Paper className='paper-form'>
                            <h1>Ground Coffee Data</h1>
                            <div className='form-container'>
                                <TextField
                                    key='origin'
                                    label='Origin'
                                    value={values.origin}
                                    onChange={handleChange('origin')}
                                ></TextField>

                                <TextField
                                    key='plantationLocation'
                                    label='Plantation Location'
                                    value={values.plantationLocation}
                                    onChange={handleChange(
                                        'plantationLocation'
                                    )}
                                ></TextField>

                                <TextField
                                    key='coffeebeanType'
                                    label='Coffee Bean Type'
                                    value={values.coffeeBeanType}
                                    onChange={handleChange('coffeeBeanType')}
                                ></TextField>

                                <TextField
                                    key='serbianWayOfRoasting'
                                    label='SRB Ways of roasting'
                                    value={values.serbianWaysOfRoasting}
                                    onChange={handleChange(
                                        'serbianWaysOfRoasting'
                                    )}
                                ></TextField>

                                <TextField
                                    key='enlgishWaysOfRoasting'
                                    label='ENG Ways of roasting'
                                    value={values.englishWaysOfRoasting}
                                    onChange={handleChange(
                                        'englishWaysOfRoasting'
                                    )}
                                ></TextField>

                                <TextField
                                    key='serbianWannaKnowMore'
                                    label='SRB Wanna Know More'
                                    value={values.serbianWannaKnowMore}
                                    onChange={handleChange(
                                        'serbianWannaKnowMore'
                                    )}
                                ></TextField>

                                <TextField
                                    key='enlgishWannaKnowMore'
                                    label='ENG Wanna Know More'
                                    value={values.englishWannaKnowMore}
                                    onChange={handleChange(
                                        'englishWannaKnowMore'
                                    )}
                                ></TextField>

                                <InputLabel
                                        sx={{
                                            marginBottom: '10px'
                                        }}
                                    >
                                        Select way of preparation
                                </InputLabel>
                                {
                                    WaysOfPreparation.map(way => (
                                        <div>
                                            <img
                                                className='ways-of-preparation-icon'
                                                src={way.image}
                                            />
                                            <Checkbox 
                                                checked={waysOfPreparationChecked[way.key]}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleWaysOfPreparationCheckbox(way.key, event.target.checked)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                color='primary'
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </Paper>
                    </div>
                ) : (
                    <></>
                )}
            </ThemeProvider>
        </div>
    );
};

export default AddProduct;
