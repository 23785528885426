import React, { useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { IProductCategoryData } from '../../interfaces/ProductCategoryInterface';
import { IProductSubCategoryData } from '../../interfaces/ProductSubCategoryInterface';
import AddCategory from './AddCategory/AddCategory';
import AddSubCategory from './AddSubCategory/AddSubCategory';
import AllCategories from './AllCategories/AllCategories';
import ShowSingleCategory from './ShowSingleCategory/ShowSingleCategory';
import ShowSingleSubCategory from './ShowSingleSubCategory/ShowSingleSubCategory';

const CategoryPage = () => {
	const [currentCategory, setCurrentCategory] = useState<IProductCategoryData | null>(null);

	const [currentSubCategory, setCurrentSubCategory] = useState<IProductSubCategoryData | null>(null);

	const history = useHistory();

	const renderShowSingle = () => {
		if (currentCategory) {
			return <ShowSingleCategory category={currentCategory} />;
		}

		if (currentSubCategory) {
			return <ShowSingleSubCategory subCategory={currentSubCategory} />;
		}

		history.push('/category/show-all');
	};

	return (
		<div>
			<Route exact path='/category/show-all'>
				<AllCategories setCurrentSubCategory={setCurrentSubCategory} setCurrentCategory={setCurrentCategory} />
			</Route>
			<Route exact path='/category/add-category'>
				<AddCategory />
			</Route>

			<Route exact path='/category/show-single-subcategory'>{renderShowSingle()}</Route>

			<Route exact path='/category/show-single'>{renderShowSingle()}</Route>

			<Route exact path='/category/add-subcategory'>
				<AddSubCategory />
			</Route>
		</div>
	);
};

export default CategoryPage;
