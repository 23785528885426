import { ThemeProvider } from '@emotion/react';
import { Paper } from '@mui/material';
import { FC } from 'react';

import { FormTheme } from '../../../util/themes/GlobalThemeOverride';

interface GetPaymentResponsePageProps {
    success: boolean;
}

const GetPaymentResponsePage: FC<GetPaymentResponsePageProps> = (props) => {
    const { success } = props;

    return (
        <div className='page-container'>
            <ThemeProvider theme={FormTheme}>
                <Paper className='paper-form'>
                    {success? 
                        (<>
                            <h1>Payment successful</h1>
                            <h1>Order was accepted</h1>
                        </>) : 
                        (<>
                            <h1>Payment failed</h1>
                            <h1>Order was rejected</h1>
                        </>)}
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default GetPaymentResponsePage;
