import { ThemeProvider } from '@emotion/react';
import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow
} from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';

import { useHistory } from 'react-router-dom';
import ProductCategorySelect from '../../../../../components/ProductCategorySelect/ProductCategorySelect';
import { AlertContext, ProductContext, ProductSubCategoryContext } from '../../../../../contexts';
import { IProductCategoryData } from '../../../../../interfaces/ProductCategoryInterface';
import { IProductSubCategoryData } from '../../../../../interfaces/ProductSubCategoryInterface';
import { TableTheme } from '../../../../../util/themes/GlobalThemeOverride';
import { responsiveWidthHalfTable } from '../../../../../util/themes/TableWidth';
import { IProductData } from '../../../../../interfaces/ProductInterface';

interface AllSubCategoriesProps {
	setCurrentSubCategory: Function;
}

const AllSubCategories: FC<AllSubCategoriesProps> = (props) => {
	const { setCurrentSubCategory } = props;

	const { showAlert } = useContext(AlertContext);

	const { getAllSubcategories, deleteSubcategory } = useContext(ProductSubCategoryContext);

	const { getAllProducts } = useContext(ProductContext);

	const [rows, setRows] = useState<IProductSubCategoryData[]>([]);

	const [fullData, setFullData] = useState<IProductSubCategoryData[]>([]);

	const [rowsPerPage, setRowsPerPage] = useState<number>(10);

	const [page, setPage] = useState<number>(0);

	const [rerender, setRerender] = useState<boolean>(false);

	const [selectedFilterCategory, setSelectedFilterCategory] = useState<IProductCategoryData | null>(null);

	const [products, setProducts] = useState<IProductData[]>([]);

	const history = useHistory();

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		if (rerender) {
			loadData();

			setRerender(false);
		}
	}, [rerender]);

	useEffect(() => {
		if (!fullData) {
			return;
		}

		if (selectedFilterCategory !== null) {
			let filteredData: IProductSubCategoryData[] = [];

			for (let row of fullData) {
				if (row.categoryId === selectedFilterCategory.id) {
					filteredData.push(row);
				}
			}

			setRows(filteredData);

			return;
		}

		setRows(fullData);
	}, [fullData, selectedFilterCategory]);

	const loadData = () => {
		getAllSubcategories().then((response) => {
			if (!response) {
				showAlert({
					severity: 'error',
					show: true,
					content: 'Cannot get data from server'
				});

				return;
			}

			setFullData(response);
		});

		getAllProducts().then((response) => {
			if (!response) {
				showAlert({
					severity: 'error',
					show: true,
					content: 'Cannot get data from server'
				});

				return;
			}

			setProducts(response);
		});
	};

	const isDisabled = (id: number) => {
		if (products.length === 0) {
			return false;
		}

		for (let product of products) {
			if (product.subcategoryId == id) {
				return true;
			}
		}

		return false;
	};

	const handleDelete = (id: number) => {
		deleteSubcategory(id).then((response) => {
			if (!response) {
				showAlert({
					severity: 'error',
					show: true,
					content: 'Deleting subcategory failed'
				});

				return;
			}

			showAlert({
				severity: 'success',
				show: true,
				content: 'Successfully deleted subcategory'
			});

			setRerender(true);
		});
	};

	const showSingleSubCategory = (subcategory: IProductSubCategoryData) => {
		setCurrentSubCategory(subcategory);

		history.push('/category/show-single-subcategory');
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);

		setPage(0);
	};

	const renderData = () => {
		if (rows) {
			return rows
				.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
				.map((row: IProductSubCategoryData) => (
					<TableRow key={row.id}>
						<TableCell
							align='left'
							onMouseDown={() => {
								showSingleSubCategory(row);
							}}
						>
							{row.id}
						</TableCell>

						<TableCell
							align='center'
							onMouseDown={() => {
								showSingleSubCategory(row);
							}}
						>
							{row.name}
						</TableCell>

						<TableCell
							align='center'
							onMouseDown={() => {
								showSingleSubCategory(row);
							}}
						>
							{row.categoryId}
						</TableCell>

						<TableCell align='center'>
							<Button
								variant='contained'
								component='span'
								color='error'
								onMouseDown={() => {
									handleDelete(row.id);
								}}
								disabled={isDisabled(row.id)}
							>
								Delete
								<input type='button' hidden />
							</Button>
						</TableCell>
					</TableRow>
				));
		}
	};

	return (
		<>
			<ThemeProvider theme={TableTheme}>
				<Button
					variant='contained'
					color='success'
					sx={{ marginBottom: 3 }}
					onClick={() => {
						history.push('/category/add-subcategory');
					}}
				>
					Add new SubCategory
					<input type='button' hidden />
				</Button>

				<Paper className='table-page-toolbar' sx={{ display: 'flex', backgroundColor: '#6b6b6b' }}>
					<ProductCategorySelect
						setValue={setSelectedFilterCategory}
						iconComponent={FilterListIcon}
						selectedValue=''
					/>
				</Paper>

				<Paper>
					<TableContainer component={Paper} sx={{ width: responsiveWidthHalfTable }}>
						<Table size='small'>
							<TableHead>
								<TableRow>
									<TableCell align='left'>ID</TableCell>
									<TableCell align='center'>Name</TableCell>
									<TableCell align='center'>Category ID</TableCell>
									<TableCell align='center'></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>{renderData()}</TableBody>
						</Table>
					</TableContainer>
					{rows ? (
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component='div'
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					) : (
						<></>
					)}
				</Paper>
			</ThemeProvider>
		</>
	);
};

export default AllSubCategories;
