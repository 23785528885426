import { ThemeProvider } from '@emotion/react';
import {
    Button,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material';
import React, { useContext, useState } from 'react';
import {
    EmployeeContext,
    AlertContext,
    ShopLocationContext
} from '../../../contexts';
import { IEmployeeRegistrationData } from '../../../interfaces/EmployeeInterface';
import { IShopLocationData } from '../../../interfaces/ShopLocationInterface';
import { RoleEnum } from '../../../util/enums/Role';
import { convertToRoleEnumValue } from '../../../util/functions/Role';
import { FormTheme } from '../../../util/themes/GlobalThemeOverride';

const AddEmployee = () => {
    const { showAlert } = useContext(AlertContext);

    const { addEmployee } = useContext(EmployeeContext);

    const { shopLocations } = useContext(ShopLocationContext);

    const [creatingWaiter, setCreatingWaiter] = useState<boolean>(false);

    const [values, setValues] = useState<IEmployeeRegistrationData>({
        email: '',
        shopLocationId: -1,
        role: -1
    });

    const validateForm = (
        prop: keyof IEmployeeRegistrationData,
        value: string | number
    ) => {
        if (prop === 'email') {
            let emailRegex = /([\w.-]+)@([\w-]+)((.(\w){2,3})+)$/;

            if (!emailRegex.test(values.email)) {
                return true;
            }

            if (value === '') {
                return true;
            }
        }

        if (prop === 'role') {
            if (value === -1) {
                return true;
            }
        }

        if (creatingWaiter) {
            if (values.shopLocationId === -1) {
                return true;
            }
        }

        return false;
    };

    const ValidateEmail = (email: string) => {
        if (email === '') {
            return 'Email cannot be empty!';
        }

        let emailRegex = /([\w.-]+)@([\w-]+)((.(\w){2,3})+)$/;

        if (!emailRegex.test(email)) {
            return 'Invalid email format';
        }

        return ' ';
    };

    // useEffect(() => {}, [values.email, values.role, values.shopLocationId]);

    const handleAdd = () => {
        addEmployee(values).then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Adding new employee failed'
                });
                return;
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully added new employee'
            });
        });
    };

    const handleChange =
        (prop: keyof IEmployeeRegistrationData) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleShopLocationSelectChange =
        (prop: keyof IEmployeeRegistrationData) =>
        (event: SelectChangeEvent) => {
            setValues({ ...values, [prop]: event.target.value });

            return event.target.value;
        };

    const handleSelectChange =
        (prop: keyof IEmployeeRegistrationData) =>
        (event: SelectChangeEvent) => {
            if (event.target.value === 'WAITER') {
                setCreatingWaiter(true);
            }

            setValues({
                ...values,
                [prop]: convertToRoleEnumValue(event.target.value)
            });

            return event.target.value;
        };

    const renderShopLocationSelect = () => {
        if (shopLocations && creatingWaiter) {
            return (
                <div className='select-wrapper'>
                    <InputLabel id='factory-label' sx={{ top: '17px' }}>
                        Select Factory
                    </InputLabel>
                    <Select
                        defaultValue=''
                        labelId='factory-label'
                        label='Factory'
                        variant='filled'
                        onChange={handleShopLocationSelectChange(
                            'shopLocationId'
                        )}
                    >
                        {renderSelectData()}
                    </Select>
                </div>
            );
        }
        return <></>;
    };

    const renderSelectData = () => {
        if (shopLocations) {
            return shopLocations.map((shopLocation: IShopLocationData) => (
                <MenuItem key={shopLocation.id} value={shopLocation.id}>
                    {shopLocation.name}
                </MenuItem>
            ));
        }
    };

    return (
        <div className='page-container add-form-container'>
            <ThemeProvider theme={FormTheme}>
                <Paper className='paper-form'>
                    <h1>Add Employee</h1>
                    <div className='form-container'>
                        <TextField
                            key='email'
                            label='Email'
                            value={values.email}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange('email')}
                            error={validateForm('email', values.email)}
                            helperText={
                                validateForm('email', values.email)
                                    ? ValidateEmail(values.email)
                                    : ValidateEmail(values.email)
                            }
                        ></TextField>

                        <div className='select-wrapper'>
                            <InputLabel id='role-label' sx={{ top: '17px' }}>
                                Select Role
                            </InputLabel>
                            <Select
                                defaultValue=''
                                labelId='role-label'
                                label='Role'
                                variant='filled'
                                onChange={handleSelectChange('role')}
                            >
                                <MenuItem key={RoleEnum[0]} value={RoleEnum[0]}>
                                    {RoleEnum[0]}
                                </MenuItem>
                                <MenuItem key={RoleEnum[1]} value={RoleEnum[1]}>
                                    {RoleEnum[1]}
                                </MenuItem>
                            </Select>
                        </div>

                        {renderShopLocationSelect()}

                        <Button
                            variant='contained'
                            component='label'
                            sx={{ m: 1.5, width: '50ch' }}
                            size='large'
                            color='success'
                            onMouseDown={handleAdd}
                        >
                            Add
                            <input type='button' hidden />
                        </Button>
                    </div>
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default AddEmployee;
