import { ThemeProvider } from '@emotion/react';
import { Button, Paper, TextField } from '@mui/material';
import { styled } from '@mui/system';
import React, { FC, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LanguageSelection from '../../../components/LanguageSelection/LanguageSelection';
import { AlertContext, ProductCategoryContext } from '../../../contexts';
import { IProductCategoryData } from '../../../interfaces/ProductCategoryInterface';
import { FormTheme } from '../../../util/themes/GlobalThemeOverride';

const Input = styled('input')({
    display: 'none'
});

interface ShowSingleCategoryProps {
    category: IProductCategoryData;
}

const ShowSingleCategory: FC<ShowSingleCategoryProps> = (props) => {
    const { category } = props;

    const { showAlert } = useContext(AlertContext);

    const { updateCategory, getById } = useContext(ProductCategoryContext);

    const [values, setValues] = useState<IProductCategoryData>(category);

    const [image, setImage] = useState<File | null>(null);

    const [previewImage, setPreviewImage] = useState<string>('');

    const [icon, setIcon] = useState<File | null>(null);

    const [previewIcon, setpreviewIcon] = useState<string>('');

    const [enableButton, setEnableButtion] = useState<boolean>(false);

    const [languageChanged, setLanguageChanged] = useState<boolean>(false);

    const history = useHistory();

    useEffect(() => {
        if (!validateAll()) {
            setEnableButtion(true);

            return;
        }

        setEnableButtion(false);
    }, [values.name, image, icon]);

    useEffect(() => {
        if (languageChanged) {
            loadData();

            setLanguageChanged(false);
        }
    }, [languageChanged]);

    const validateForm = (prop: keyof IProductCategoryData, value: string) => {
        if (value == '') {
            return true;
        }

        return false;
    };

    const validateAll = () => {
        if (validateForm('name', values.name)) {
            return true;
        }

        return false;
    };

    const loadData = () => {
        getById(values.id).then((response) => {
            if (response === null) {
                showAlert({
                    severity: 'error',

                    show: true,
                    content: 'Cannot get data from server'
                });

                history.push('/category/show-all');

                return;
            }

            setValues(response);
        });
    };

    const handleChange =
        (prop: keyof IProductCategoryData) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleImageChange =
        () => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                const file = event.target.files[0];

                setImage(file);

                fileToBase64(file, true);
            }
        };

    const handleIconChange =
        () => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                const file = event.target.files[0];

                setIcon(file);

                fileToBase64(file, false);
            }
        };

    const renderImage = () => {
        if (image) {
            return (
                <img
                    className='image-preview'
                    src={`${previewImage}`}
                    alt='preview'
                />
            );
        }

        return (
            <img
                className='image-preview'
                src={`${values.imageUrl}`}
                alt='preview'
            />
        );
    };

    const renderIcon = () => {
        if (icon) {
            return (
                <img
                    className='icon-preview'
                    src={`${previewIcon}`}
                    alt='preview'
                />
            );
        }

        return (
            <img
                className='icon-preview'
                src={`${values.iconUrl}`}
                alt='preview'
            />
        );
    };

    const fileToBase64 = async (file: File | undefined, isImage:boolean) => {
        if(file !== undefined){
            const reader = new FileReader();

            reader.onloadend = () => {
                let base64data: string = reader.result as string;
    
                if (isImage){
                    setPreviewImage(base64data);
                    
                    return;
                }
    
                setpreviewIcon(base64data);
    
            };
    
            reader.readAsDataURL(file);

            return;
        }
        
        if(isImage){
            setImage(null);

            setPreviewImage('');

            return;
        }

        setIcon(null);

        setpreviewIcon('');
    };

    const submitForm = () => {
        let formData = new FormData();

        formData.append('data', JSON.stringify(values));

        if (image) {
            formData.append('Images[]', image, `Image${image.name}`);
        }

        if (icon) {
            formData.append('Images[]', icon, `Icon${icon.name}`);
        }

        updateCategory(formData).then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Adding new Category failed'
                });

                return;
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully added new Category'
            });
        });
    };

    return (
        <div className='page-container update-form-container'>
            <ThemeProvider theme={FormTheme}>
                <Paper className='paper-form'>
                    <h1>Update Category</h1>
                    <div className='form-container'>
                        <LanguageSelection setIsChanged={setLanguageChanged} />

                        <TextField
                            key='name'
                            label='Name'
                            value={values.name}
                            onChange={handleChange('name')}
                            error={validateForm('name', values.name)}
                            helperText={
                                validateForm('name', values.name)
                                    ? 'Serbian name is required'
                                    : ' '
                            }
                        />

                        <div className='image-entry-container'>
                            <div className='image-upload-container-updateCategory'>
                                <label htmlFor='contained-button-image'>
                                    <Input
                                        accept='.png, .jpg, .jpeg'
                                        id='contained-button-image'
                                        multiple
                                        type='file'
                                        key='categoryImage'
                                        onChange={handleImageChange()}
                                    />

                                    <Button
                                        variant='contained'
                                        component='span'
                                        sx={{ m: 1.5, width: '25ch' }}
                                    >
                                        Upload Image
                                    </Button>
                                </label>

                                {renderImage()}
                            </div>

                            <div className='icon-upload-container-updateCategory'>
                                <label htmlFor='contained-button-icon'>
                                    <Input
                                        accept='.png, .jpg, .jpeg'
                                        id='contained-button-icon'
                                        multiple
                                        type='file'
                                        key='categoryIcon'
                                        onChange={handleIconChange()}
                                    />

                                    <Button
                                        variant='contained'
                                        component='span'
                                        sx={{ m: 1.5, width: '25ch' }}
                                    >
                                        Upload Icon
                                    </Button>
                                </label>

                                {renderIcon()}
                            </div>
                        </div>

                        <Button
                            variant='contained'
                            component='label'
                            sx={{ m: 1.5, width: '50ch' }}
                            size='large'
                            color='success'
                            onMouseDown={submitForm}
                            disabled={!enableButton}
                        >
                            Update
                            <input type='button' hidden />
                        </Button>
                    </div>
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default ShowSingleCategory;
