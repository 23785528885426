import React, { useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { IArticleData } from '../../interfaces/ArticleInterface';
import AddArticle from './AddArticle/AddArticle';
import AllArticles from './AllArticles/AllArticles';
import ShowSingleArticle from './ShowSingleArticle/ShowSingleArticle';

const ArticlePage = () => {
	const [currentArticle, setCurrentArticle] = useState<IArticleData | null>(null);

	const history = useHistory();

	const renderShowSingle = () => {
		if (currentArticle) {
			return <ShowSingleArticle selectedId={currentArticle.id} />;
		}

		history.push('/article/show-all');
	};

	return (
		<div>
			<Route path='/article/show-all'>
				<AllArticles setCurrentArticle={setCurrentArticle} />
			</Route>
			<Route path='/article/add-article'>
				<AddArticle />
			</Route>
			<Route path='/article/show-single'>{renderShowSingle()}</Route>
		</div>
	);
};

export default ArticlePage;
