import React, { useState } from 'react';
import { Route } from 'react-router-dom';

import AddShopLocation from './AddShopLocation/AddShopLocation';
import ShowAllShopLocations from './ShowAllShopLocations/ShowAllShopLocations';
import ShowSingleShopLocation from './ShowSingleShopLocation/ShowSingleShopLocation';

const ShopLocationsPage = () => {
    const [shopLocation, setShopLocation] = useState(null);

    return (
        <div>
            <Route path={'/shoplocation/add'}>
                <AddShopLocation />
            </Route>
            <Route path={'/shoplocation/show-all'}>
                <ShowAllShopLocations setShopLocation={setShopLocation} />
            </Route>
            <Route path={'/shoplocation/show-shoplocation'}>
                <ShowSingleShopLocation shopLocation={shopLocation} />
            </Route>
        </div>
    );
};

export default ShopLocationsPage;
