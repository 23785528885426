import { AxiosError, AxiosResponse } from 'axios';
import React, {
    createContext,
    FC,
    useContext,
    useEffect,
    useState
} from 'react';

import { IOrderData, IUpdateOrderData } from '../interfaces/OrderInterface';
import { AuthenticationContext, AxiosContext } from './';
import { IProductHistoryData } from '../interfaces/ProductInterface';
import { IRejectionReason } from '../interfaces/RejectionReasonInterface';
import { RoleEnum } from '../util/enums/Role';
import { IOrderReceivedMessage } from '../interfaces/MessageInterface';
import sound from '../assets/notification.mp3';
import { WebSocketContext } from './WebSocketContext';

interface IOrderContext {
    getAllProcessedOrdersByShopLocation(): Promise<IOrderData[] | null>;
    rejectOrder(
        id: number,
        rejectionReason: IRejectionReason
    ): Promise<IOrderData | null>;
    acceptOrder(id: number): Promise<IOrderData | null>;
    getProductsForOrder(id: number): Promise<IProductHistoryData[] | null>;
    updateOrderDetails(data: IUpdateOrderData): Promise<boolean>;
    incomingOrders: IOrderData[];
    readyOrder(orderId: number): Promise<boolean>;
    getAllProcessedOrders(): Promise<IOrderData[] | null>;
}

const OrderContext = createContext({} as IOrderContext);

interface OrderContextProps {
    children: React.ReactNode;
}

const OrderContextProvider: FC<OrderContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const { orderHubConnection } = useContext(WebSocketContext);

    const { getToken, getShopLocationId, getRole } = useContext(
        AuthenticationContext
    );

    const [incomingOrders, setIncomingOrders] = useState<IOrderData[]>([]);

    const ordersSortingFunction = (order1: IOrderData, order2: IOrderData) => {
        if(order1.isReady === order2.isReady){
            return order2.id - order1.id;
        }
        return order1.isReady ? -1 : 1;
    }

    useEffect(() => {
        if (getToken() !== '') {
            orderHubConnection.on('Order', (data: IOrderReceivedMessage) => {
                if (data.isNew) {
                    const notification = new Audio(sound);

                    notification.play();

                    setIncomingOrders((prevState) => [
                        data.order,
                        ...prevState
                    ].sort(ordersSortingFunction));
                } else {
                    setIncomingOrders((prevState) => [
                        ...prevState.filter(
                            (order) => order.id !== data.order.id
                        )
                    ]);
                }
            });

            if (getRole() === RoleEnum[1]) {

                getAllReceivedOrdersByShopLocation().then(data => {
                    data = data.sort(ordersSortingFunction)
                    setIncomingOrders(prevState => [...data, ...prevState])
                })

                const shopLocationId = getShopLocationId();

                orderHubConnection
                    .start()
                    .then(() =>
                        orderHubConnection.invoke(
                            'SubscribeToIncomingOrders',
                            shopLocationId.toString()
                        )
                    );
            } else {

                getAllReceivedOrders().then(data => {
                    data = data.sort(ordersSortingFunction)
                    setIncomingOrders(prevState => [...data, ...prevState])
                })

                orderHubConnection
                .start()
                .then(() =>
                    orderHubConnection.invoke(
                        'SubscribeToIncomingOrders',
                        'ADMIN'
                    )
                );

            }
        }
    }, []);


    function getAllReceivedOrders(): Promise<IOrderData[]> {
        return axiosInstance({
            method: 'GET',
            url: `api/order/getallreceived`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return [];
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return [];
            });
    };

    function getAllReceivedOrdersByShopLocation(): Promise<
        IOrderData[]
    > {
        return axiosInstance({
            method: 'GET',
            url: `api/order/getallreceivedbyshoplocationid/${getShopLocationId()}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return [];
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return [];
            });
    };


    const getAllProcessedOrdersByShopLocation = () => {
        return axiosInstance({
            method: 'GET',
            url: `api/order/getallprocessedbyshoplocationid/${getShopLocationId()}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const getAllProcessedOrders = () => {
        return axiosInstance({
            method: 'GET',
            url: 'api/order/getallprocessed',
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
        .then((response: AxiosResponse) => {
            if (response === undefined) {
                return null;
            }

            return response.data;
        })
        .catch((error: AxiosError) => {
            return null;
        })
    }

    const rejectOrder = (id: number, rejectionReason: IRejectionReason) => {
        return axiosInstance({
            method: 'PUT',
            url: `api/payment/rejectorder/${id}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                'Content-Type': 'application/json'
            },
            data: rejectionReason
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const acceptOrder = (id: number) => {
        return axiosInstance({
            method: 'PUT',
            url: `api/payment/acceptorder/${id}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const getProductsForOrder = (id: number) => {
        return axiosInstance({
            method: 'GET',
            url: `api/order/getproductsfororderbyid/${id}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })

            .catch((error: AxiosError) => {
                return null;
            });
    };

    const updateOrderDetails = (data: IUpdateOrderData) => {
        return axiosInstance({
            method: 'PUT',
            url: 'api/order/update',
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                'Content-Type': 'application/json'
            },
            data
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })

            .catch((error: AxiosError) => {
                return false;
            });
    };

    const readyOrder = (orderId: number) => {
        return axiosInstance({
            method: 'PATCH',
            url: `api/order/readyorder/${orderId}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        }).then((response: AxiosResponse) => {
            if (response === undefined) {
                return false;
            }

            let found = incomingOrders.find(o => o.id === orderId);

            if (found !== undefined){
                const order : IOrderData = found;

                if (order !== undefined) {
                    order.isReady = !order.isReady;
    
                    setIncomingOrders((prevState) => [
                        ...prevState.filter(
                            (o) => o.id !== orderId
                        ),
                        order
                    ].sort((a, b) => {
                        if(a.isReady === b.isReady){
                            return a.id - b.id;

                        }
                        return a.isReady ? -1 : 1;
                    }));
                }
            }

            return true;
        })
        .catch((error: AxiosError) => {
            return false;
        })
    }

    const providerValue = {
        getAllProcessedOrdersByShopLocation,
        rejectOrder,
        acceptOrder,
        getProductsForOrder,
        updateOrderDetails,
        incomingOrders,
        readyOrder,
        getAllProcessedOrders
    };

    return (
        <OrderContext.Provider value={providerValue}>
            {props.children}
        </OrderContext.Provider>
    );
};

export { OrderContext, OrderContextProvider };
