import { ThemeProvider } from "@emotion/react";
import { MobileDatePicker } from "@mui/lab";
import { Button, InputLabel, Paper, TextField } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import ProductsTable from "../../../../../components/ProductsTable/ProductsTable";
import RejectionReasonSelect from "../../../../../components/RejectionReasonSelect/RejectionReasonSelect";
import { AlertContext, OrderContext } from "../../../../../contexts";
import { IOrderData } from "../../../../../interfaces/OrderInterface";
import { IProductHistoryData } from "../../../../../interfaces/ProductInterface";
import { IRejectionReason } from "../../../../../interfaces/RejectionReasonInterface";
import { OrderStatus } from "../../../../../util/enums/OrderStatus";
import { printOrderOption } from "../../../../../util/functions/OrderOptions";
import {
  FormTheme,
  respondModalTheme,
} from "../../../../../util/themes/GlobalThemeOverride";

interface RespondToOrderModalProps {
  order: IOrderData;
  setShowRespondModal: Function;
}

const RespondToOrderModal: FC<RespondToOrderModalProps> = (props) => {
  const { order, setShowRespondModal } = props;

  const { showAlert } = useContext(AlertContext);

  const { getProductsForOrder, rejectOrder, acceptOrder } =
    useContext(OrderContext);

  const [products, setProducts] = useState<IProductHistoryData[]>([]);

  const [enableReject, setEnableReject] = useState<boolean>(false);

  const [rejectionReason, setRejectionReason] =
    useState<IRejectionReason | null>(null);

  useEffect(() => {
    getProductsForOrder(order.id).then((response) => {
      if (!response) {
        showAlert({
          severity: "error",
          show: true,
          content: "Cannot get data from server",
        });

        return;
      }

      setProducts(response);
    });
  }, []);

  useEffect(() => {
    if (rejectionReason) {
      setEnableReject(true);

      return;
    }

    setEnableReject(false);
  }, [rejectionReason]);

  const setNumberOfOrders = (number: number) => {
    localStorage.setItem("@numberOfOrders", number.toString());
  };

  const getNumberOfOrders = () => {
    const number = localStorage.getItem("@numberOfOrders");

    if (!number) {
      return 0;
    }

    return Number.parseInt(number);
  };

  const handleAccept = () => {
    acceptOrder(order.id)
      .then((response) => {
        if (
          response === null ||
          response.orderStatus === OrderStatus.REJECTED
        ) {
          showAlert({
            severity: "error",
            show: true,
            content: "Order can't be accepted",
          });

          setShowRespondModal(false);

          return;
        }

        showAlert({
          severity: "success",
          show: true,
          content: "Order accepted",
        });

        setShowRespondModal(false);
      })
      .catch((error) => {
        showAlert({
          severity: "error",
          show: true,
          content: "Order can't be accepted",
        });

        setShowRespondModal(false);

        return;
      });
  };

  const handleReject = () => {
    if (rejectionReason) {
      rejectOrder(order.id, rejectionReason).then((response) => {
        if (!response) {
          showAlert({
            severity: "error",
            show: true,
            content: "Rejecting order failed",
          });

          setShowRespondModal(false);

          return;
        }

        setNumberOfOrders(getNumberOfOrders() - 1);

        setShowRespondModal(false);
      });
    }
  };

  const handleClose = () => {
    setShowRespondModal(false);
  };

  return (
    <div className="modal-respond">
      <ThemeProvider theme={respondModalTheme}>
        <Paper
          className="paper-form"
          sx={{ maxWidth: "50%", minWidth: "none" }}
        >
          <div className="respond-to-order-data">
            <div>
              <Paper className="paper-form">
                <h1>Order content</h1>
                <ProductsTable products={products} isModal={true} />
              </Paper>
            </div>
            <div>
              <h1>Order Details</h1>
              <div className="form-container">
                <TextField
                  key="customerName"
                  label="Customer"
                  value={order.customerName}
                  required
                ></TextField>

                <MobileDatePicker
                  label="Arrival Time"
                  inputFormat="DD-MM-yyyy"
                  value={order.arrivalTime}
                  renderInput={(params: any) => <TextField {...params} />}
                  onChange={() => {}}
                  readOnly={true}
                />

                <TextField
                  key="orderOption"
                  label="Order option"
                  value={printOrderOption(order.orderOption)}
                ></TextField>

                <TextField
                  key="address"
                  label="Address"
                  value={order.addressAndBuildingNumber}
                ></TextField>

                <TextField
                  key="apartment"
                  label="Apartment number"
                  value={order.apartmentNumber ? order.apartmentNumber : ""}
                ></TextField>

                <TextField
                  key="phone"
                  label="Phone"
                  value={order.phoneNumber}
                  required
                ></TextField>

                <TextField
                  key="priceTotal"
                  label="Total"
                  value={order.priceTotal}
                  required
                ></TextField>
              </div>
            </div>
          </div>
          <div className="respond-modal-button-container">
            <Button
              variant="contained"
              component="span"
              color="success"
              onMouseDown={handleAccept}
            >
              Accept
            </Button>

            <Button
              variant="contained"
              component="span"
              color="error"
              onMouseDown={handleReject}
              disabled={!enableReject}
            >
              Reject
            </Button>

            <Button
              variant="contained"
              component="span"
              color="primary"
              onMouseDown={handleClose}
            >
              Close
            </Button>
          </div>{" "}
          <InputLabel
            sx={{
              marginBottom: "10px",
            }}
          >
            Select Rejection Reason
          </InputLabel>
          <RejectionReasonSelect setValue={setRejectionReason} />
        </Paper>
      </ThemeProvider>
    </div>
  );
};

export default RespondToOrderModal;
