import { AxiosError, AxiosResponse } from 'axios';
import { FC, useContext, createContext } from 'react';
import { AuthenticationContext, AxiosContext, LanguageContext } from '.';
import {
    IProductSubCategoryCreationData,
    IProductSubCategoryData
} from '../interfaces/ProductSubCategoryInterface';

interface IProductSubCategoryContext {
    getAllSubcategoriesByCategoryId(
        categoryId: number
    ): Promise<IProductSubCategoryData[] | null>;
    getAllSubcategories(): Promise<IProductSubCategoryData[] | null>;
    deleteSubcategory(id: number): Promise<boolean>;
    addSubcategory(data: IProductSubCategoryCreationData): Promise<boolean>;
    updateSubcategory(data: IProductSubCategoryData): Promise<boolean>;
    getById(id: number): Promise<IProductSubCategoryData | null>;
}

const ProductSubCategoryContext = createContext(
    {} as IProductSubCategoryContext
);

interface ProductSubCategoryContextProps {
    children: React.ReactNode;
}

const ProductSubCategoryContextProvider: FC<ProductSubCategoryContextProps> = (
    props
) => {
    const { axiosInstance } = useContext(AxiosContext);

    const { getToken } = useContext(AuthenticationContext);

    const { getLanguage } = useContext(LanguageContext);

    const getAllSubcategoriesByCategoryId = (categoryId: number) => {
        return axiosInstance({
            method: 'GET',
            url: `api/productsubcategory/getallbycategoryid/${categoryId}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const getAllSubcategories = () => {
        return axiosInstance({
            method: 'GET',
            url: `api/productsubcategory/getall`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const deleteSubcategory = (id: number) => {
        return axiosInstance({
            method: 'DELETE',
            url: `api/productsubcategory/delete/${id}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const addSubcategory = (data: IProductSubCategoryCreationData) => {
        return axiosInstance({
            method: 'POST',
            url: 'api/productsubcategory/add',
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            },
            data: data
        })
            .then((response: AxiosResponse) => {
                if (!response) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const updateSubcategory = (data: IProductSubCategoryData) => {
        return axiosInstance({
            method: 'PUT',
            url: 'api/productsubcategory/update',
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                Language: getLanguage().toString()
            },
            data: data
        })
            .then((response: AxiosResponse) => {
                if (!response) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const getById = (id: number) => {
        return axiosInstance({
            method: 'GET',
            url: `api/productsubcategory/getbyid/${id}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                Language: getLanguage().toString()
            }
        })
            .then((response: AxiosResponse) => {
                if (!response) {
                    return null;
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const providerValue = {
        getAllSubcategoriesByCategoryId,
        getAllSubcategories,
        deleteSubcategory,
        addSubcategory,
        updateSubcategory,
        getById
    };

    return (
        <ProductSubCategoryContext.Provider value={providerValue}>
            {props.children}
        </ProductSubCategoryContext.Provider>
    );
};

export { ProductSubCategoryContext, ProductSubCategoryContextProvider };
