import { createContext, FC, useState } from 'react';
import { AlertData } from '../interfaces/AlertInterface';

interface IAlertContext {
    showAlert(alertData: AlertData): void;
    getAlertData(): AlertData;
}

const AlertContext = createContext({} as IAlertContext);

interface AlertContextProps {
    children: React.ReactNode;
}

const AlertContextProvider: FC<AlertContextProps> = (props) => {
    const [alertData, setAlertData] = useState<AlertData>({
        severity: undefined,
        show: false,
        content: ''
    });

    const showAlert = (alertData: AlertData) => {
        setAlertData(alertData);
    };

    const getAlertData = () => {
        return alertData;
    };

    const providerValue = {
        showAlert,
        getAlertData
    };

    return (
        <AlertContext.Provider value={providerValue}>
            {props.children}
        </AlertContext.Provider>
    );
};

export { AlertContext, AlertContextProvider };
