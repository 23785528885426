import { AxiosError, AxiosResponse } from 'axios';
import React, { createContext, FC, useContext } from 'react';
import { AuthenticationContext, AxiosContext } from '.';
import {
    IArticleData,
    IArticleWithSectionsData
} from '../interfaces/ArticleInterface';

interface IArticleContext {
    getAllArticles(): Promise<IArticleData[] | null>;
    deleteArticle(id: number): Promise<boolean>;
    addArticle(data: FormData): Promise<boolean>;
    getArticleWithSectionsById(
        id: number
    ): Promise<IArticleWithSectionsData | null>;
    updateArticle(data: FormData): Promise<boolean>;
}

const ArticleContext = createContext({} as IArticleContext);

interface ArticleContextProps {
    children: React.ReactNode;
}

const ArticleContextProvider: FC<ArticleContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const { getToken } = useContext(AuthenticationContext);

    const getAllArticles = () => {
        return axiosInstance({
            method: 'GET',
            url: 'api/article/getall',
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })

            .catch((error: AxiosError) => {
                return null;
            });
    };

    const deleteArticle = (id: number) => {
        return axiosInstance({
            method: 'DELETE',
            url: `api/article/delete/${id}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })

            .catch((error: AxiosError) => {
                return false;
            });
    };

    const addArticle = (data: FormData) => {
        return axiosInstance({
            method: 'POST',
            url: 'api/article/add',
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                'Content-Type': 'multipart/form-data'
            },
            data
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })

            .catch((errror: AxiosError) => {
                return false;
            });
    };

    const getArticleWithSectionsById = (id: number) => {
        return axiosInstance({
            method: 'GET',
            url: `api/article/getarticlewithsectionsbyid/${id}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const updateArticle = (data: FormData) => {
        return axiosInstance({
            method: 'PUT',
            url: 'api/article/update',
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                'Content-Type': 'multipart/form-data'
            },
            data
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })

            .catch((errror: AxiosError) => {
                return false;
            });
    };

    const providerValue = {
        getAllArticles,
        deleteArticle,
        addArticle,
        getArticleWithSectionsById,
        updateArticle
    };

    return (
        <ArticleContext.Provider value={providerValue}>
            {props.children}
        </ArticleContext.Provider>
    );
};

export { ArticleContext, ArticleContextProvider };
