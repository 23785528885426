import moment from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';

import { ShopLocationContext } from '../../../contexts/ShopLocationContext';
import { IShopLocationData } from '../../../interfaces/ShopLocationInterface';
import { TableTheme } from '../../../util/themes/GlobalThemeOverride';
import { responsiveWidth } from '../../../util/themes/TableWidth';
import { AlertContext } from '../../../contexts/AlertContext';

interface ShowAllShopLocationsProps {
    setShopLocation: Function;
}

const ShowAllShopLocations: FC<ShowAllShopLocationsProps> = (props) => {
    const { getShopLocations, deleteShopLocation } =
        useContext(ShopLocationContext);

    const { showAlert } = useContext(AlertContext);

    const [rows, setRows] = useState<IShopLocationData[] | null>([]);

    const { setShopLocation } = props;

    const [rerender, setRerender] = useState<boolean>(false);

    const history = useHistory();

    const handleDelete = async (id: number) => {
        deleteShopLocation(id).then((response: boolean) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Deleting factory failed'
                });
                return;
            }
            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully deleted factory'
            });
            setRerender(true);
        });
    };

    const loadData = () => {
        getShopLocations().then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: "Can't get data from server"
                });
            }
            setRows(response);
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (rerender) {
            loadData();
            setRerender(false);
        }
    }, [rerender]);

    const showShopLocation =
        (shopLocation: IShopLocationData) =>
        (event: React.MouseEvent<HTMLTableCellElement>) => {
            setShopLocation(shopLocation);

            history.push('/shoplocation/show-shoplocation');
        };

    function renderData() {
        if (rows) {
            return rows.map((row: IShopLocationData) => (
                <TableRow key={row.id}>
                    <TableCell scope='row'>{row.id}</TableCell>
                    <TableCell
                        align='center'
                        onMouseDown={showShopLocation(row)}
                    >
                        {row.name}
                    </TableCell>
                    <TableCell
                        align='center'
                        onMouseDown={showShopLocation(row)}
                    >
                        {row.address}
                    </TableCell>
                    <TableCell
                        align='center'
                        onMouseDown={showShopLocation(row)}
                    >
                        {row.city}
                    </TableCell>
                    <TableCell
                        align='center'
                        onMouseDown={showShopLocation(row)}
                    >
                        {row.postalCode}
                    </TableCell>
                    
                    <TableCell
                        align='center'
                        onMouseDown={showShopLocation(row)}
                    >
                        {moment
                            .utc(row.updatedAt)
                            .local()
                            .format('DD-MM-YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell
                        align='center'
                        onMouseDown={showShopLocation(row)}
                    >
                        {moment
                            .utc(row.updatedAt)
                            .local()
                            .format('DD-MM-YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell align='center'>
                        <Button
                            variant='contained'
                            component='span'
                            color='error'
                            onMouseDown={() => {
                                handleDelete(row.id);
                            }}
                        >
                            Delete
                            <input type='button' hidden />
                        </Button>
                    </TableCell>
                </TableRow>
            ));
        }
        return;
    }

    return (
        <div className='page-container'>
            <ThemeProvider theme={TableTheme}>
                <Button
                    variant='contained'
                    color='success'
                    onClick={() => {
                        window.location.href = '/shoplocation/add';
                    }}
                    className='table-view-page-btn'
                    sx={{ marginBottom: 3 }}
                >
                    Add new Factory
                    <input type='button' hidden />
                </Button>
                <TableContainer
                    component={Paper}
                    sx={{
                        width: responsiveWidth
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='left' className='table-row'>
                                    Id
                                </TableCell>
                                <TableCell align='center'>Name</TableCell>
                                <TableCell align='center'>Address</TableCell>
                                <TableCell align='center'>City</TableCell>
                                <TableCell align='center'>
                                    Postal code
                                </TableCell>
                                <TableCell align='center'>Created at</TableCell>
                                <TableCell align='center'>Updated at</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{renderData()}</TableBody>
                    </Table>
                </TableContainer>
            </ThemeProvider>
        </div>
    );
};

export default ShowAllShopLocations;
