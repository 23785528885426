import { AxiosError, AxiosResponse } from 'axios';
import React, {
    createContext,
    FC,
    useContext,
    useEffect,
    useState
} from 'react';

import { IShopLocationData } from '../interfaces/ShopLocationInterface';
import { AuthenticationContext, AxiosContext } from './';

interface IShopLocationContext {
    addShopLocation(FormCollection: FormData): Promise<boolean>;
    getShopLocations(): Promise<IShopLocationData[] | null>;
    deleteShopLocation(id: number): Promise<boolean>;
    updateShopLocation(FormCollection: FormData): Promise<boolean>;
    shopLocations: IShopLocationData[] | null;
}

const ShopLocationContext = createContext({} as IShopLocationContext);

interface ShopLocationContextProps {
    children: React.ReactNode;
}

const ShopLocationContextProvider: FC<ShopLocationContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);
    const { getToken } = useContext(AuthenticationContext);

    const [shopLocations, setShopLocations] = useState<
        IShopLocationData[] | null
    >(null);

    useEffect(() => {
        if (!shopLocations) {
            getShopLocations();
        }
    }, []);

    const getShopLocations = () => {
        return axiosInstance({
            method: 'GET',
            url: 'api/shoplocation/getall'
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                setShopLocations(response.data);
                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const addShopLocation = (data: FormData) => {
        return axiosInstance({
            method: 'POST',
            url: 'api/shoplocation/add',
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                'Content-Type': 'multipart/form-data'
            },
            data
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const deleteShopLocation = (id: number) => {
        return axiosInstance({
            method: 'DELETE',
            url: `api/shoplocation/delete/${id}`,
            headers: {
                Authorization: `Bearer `.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const updateShopLocation = (data: FormData) => {
        return axiosInstance({
            method: 'PUT',
            url: `api/shoplocation/update`,
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                'Content-Type': 'multipart/form-data'
            },
            data
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const providerValue = {
        addShopLocation,
        getShopLocations,
        deleteShopLocation,
        updateShopLocation,
        shopLocations
    };

    return (
        <ShopLocationContext.Provider value={providerValue}>
            {props.children}
        </ShopLocationContext.Provider>
    );
};

export { ShopLocationContext, ShopLocationContextProvider };
