import { ThemeProvider } from '@emotion/react';
import {
    Button,
    ImageList,
    ImageListItem,
    Paper,
    TextField
} from '@mui/material';
import moment from 'moment';
import { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AlertContext, ReviewContext } from '../../../contexts';
import { IReviewData } from '../../../interfaces/ReviewInterface';
import { FormTheme } from '../../../util/themes/GlobalThemeOverride';

interface IShowsingleReviewProps {
    review: IReviewData;
}

const ShowSingleReview: FC<IShowsingleReviewProps> = (props) => {
    const { review } = props;

    const { approveReview, deleteReview } = useContext(ReviewContext);

    const { showAlert } = useContext(AlertContext);

    const history = useHistory();

    const handleApprove = () => {
        approveReview(review.id).then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Unable to approve review'
                });

                return;
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully approved review'
            });

            history.push('/review/approve-reviews');
        });
    };

    const handleDelete = () => {
        deleteReview(review.id).then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Unable to delete review'
                });

                return;
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully deleted review'
            });

            history.push('/review/approve-reviews');
        });
    };

    return (
        <div className='page-container add-form-container update-article-container'>
            <ThemeProvider theme={FormTheme}>
                <Paper className='paper-form'>
                    <h1>Preview Review</h1>
                    <div className='form-container'>
                        <TextField
                            key='value'
                            label='Value'
                            value={review.value}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />

                        <TextField
                            key='description'
                            label='Description'
                            multiline
                            rows={5}
                            value={review.description}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />

                        <TextField
                            key='date'
                            label='Date'
                            value={moment
                                .utc(review.date)
                                .local()
                                .format('DD-MM-YYYY')}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        {review.imageUrls.length !== 0 ? (
                            <ImageList
                                sx={{ width: 450, height: 450 }}
                                cols={2}
                                rowHeight={164}
                            >
                                {review.imageUrls.map((imageUrl: string) => {
                                    return (
                                        <ImageListItem key={imageUrl}>
                                            <img
                                                className='image-list-image'
                                                src={`${imageUrl}`}
                                                srcSet={`${imageUrl}`}
                                                alt={'preview'}
                                                loading='lazy'
                                            />
                                        </ImageListItem>
                                    );
                                })}
                            </ImageList>
                        ) : (
                            <></>
                        )}

                        <div className='approve-delete-button-container'>
                            <Button
                                variant='contained'
                                component='label'
                                sx={{ m: 1.5, width: '20ch' }}
                                size='large'
                                color='success'
                                onMouseDown={handleApprove}
                            >
                                Approve
                                <input type='button' hidden />
                            </Button>

                            <Button
                                variant='contained'
                                component='label'
                                sx={{ m: 1.5, width: '20ch' }}
                                size='large'
                                color='error'
                                onMouseDown={handleDelete}
                            >
                                Delete
                                <input type='button' hidden />
                            </Button>
                        </div>
                    </div>
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default ShowSingleReview;
