import { ThemeProvider } from '@emotion/react';
import { Button, Paper, styled, TextField } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import { AlertContext, LandingPageContentContext } from '../../contexts';
import { ILandingPageContentData } from '../../interfaces/LandingPageContentInterface';
import { FormTheme } from '../../util/themes/GlobalThemeOverride';

const Input = styled('input')({
    display: 'none'
});

const LandingPageContent = () => {
    const { getContent, updateContent } = useContext(LandingPageContentContext);

    const { showAlert } = useContext(AlertContext);

    const [values, setValues] = useState<ILandingPageContentData>({
        subtitleSerbian: '',
        subtitleEnglish: '',
        image: '',
        titleEnglish: '',
        titleSerbian: ''
    });

    const [enableButton, setEnableButton] = useState<boolean>(false);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (!validateAll()) {
            setEnableButton(true);

            return;
        }

        setEnableButton(false);
    }, [
        values.image,
        values.subtitleEnglish,
        values.subtitleSerbian,
        values.titleEnglish,
        values.titleSerbian
    ]);

    function loadData() {
        getContent().then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Cannot get data from server'
                });

                return null;
            }

            setValues(response);
        });
    }

    const fileToBase64 = async (file: File | undefined) => {
        if(file !== undefined){

            const reader = new FileReader();

            reader.onloadend = () => {
                let base64data: string = reader.result as string;
    
                setValues({ ...values, image: base64data });
            };
    
            reader.readAsDataURL(file);

            return;
        }

        
    };

    const renderPhoto = () => {
        if (values.image) {
            return (
                <img
                    className='image-preview'
                    src={`${values.image}`}
                    alt='preview'
                />
            );
        }
    };

    const handleChange =
        (prop: keyof ILandingPageContentData) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const validateForm = (
        prop: keyof ILandingPageContentData,
        value: string
    ) => {
        if (value === '') {
            return true;
        }

        return false;
    };

    const validateAll = () => {
        if (values.subtitleEnglish === '') {
            return true;
        }

        if (values.subtitleSerbian === '') {
            return true;
        }

        if (values.titleEnglish === '') {
            return true;
        }

        if (values.titleSerbian === '') {
            return true;
        }

        if (values.image === '') {
            return true;
        }
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const image = event.target.files[0];

            if (image) {
                fileToBase64(image);
            }
        }
    };

    const submitForm = () => {
        updateContent(values).then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Updating landing page content failed'
                });

                return;
            }
            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully updated landing page content'
            });
        });
    };

    return (
        <div className='page-container add-form-container'>
            <ThemeProvider theme={FormTheme}>
                <Paper className='paper-form'>
                    <h1>Edit landing page content</h1>
                    <div className='form-container'>
                        <TextField
                            key='titleSerbian'
                            label='Title SRB'
                            value={values.titleSerbian}
                            onChange={handleChange('titleSerbian')}
                            error={validateForm(
                                'titleSerbian',
                                values?.titleSerbian
                            )}
                            helperText={
                                validateForm(
                                    'titleSerbian',
                                    values?.titleSerbian
                                )
                                    ? 'SRB Title cannot be empty'
                                    : ' '
                            }
                        />

                        <TextField
                            key='titleEnglish'
                            label='Title ENG'
                            value={values.titleEnglish}
                            onChange={handleChange('titleEnglish')}
                            error={validateForm(
                                'titleEnglish',
                                values?.titleEnglish
                            )}
                            helperText={
                                validateForm(
                                    'titleEnglish',
                                    values.titleEnglish
                                )
                                    ? 'ENG Title cannot be empty'
                                    : ' '
                            }
                        />

                        <TextField
                            key='subtitleSerbian'
                            label='Subtitle SRB'
                            value={values.subtitleSerbian}
                            onChange={handleChange('subtitleSerbian')}
                            error={validateForm(
                                'subtitleSerbian',
                                values.subtitleSerbian
                            )}
                            helperText={
                                validateForm(
                                    'subtitleSerbian',
                                    values.subtitleSerbian
                                )
                                    ? 'SRB Subtitle cannot be empty'
                                    : ' '
                            }
                        />

                        <TextField
                            key='subtitleEnglish'
                            label='Subtitle ENG'
                            value={values.subtitleEnglish}
                            onChange={handleChange('subtitleEnglish')}
                            error={validateForm(
                                'subtitleEnglish',
                                values.subtitleEnglish
                            )}
                            helperText={
                                validateForm(
                                    'subtitleEnglish',
                                    values.subtitleEnglish
                                )
                                    ? 'ENG Subtitle cannot be empty'
                                    : ' '
                            }
                        />

                        <div className='image-upload-container-landingPageContent'>
                            <label htmlFor='contained-button-file'>
                                <Input
                                    accept='.png, .jpg, .jpeg'
                                    id='contained-button-file'
                                    multiple
                                    type='file'
                                    key='image'
                                    onChange={handleImageChange}
                                />

                                <Button
                                    variant='contained'
                                    component='span'
                                    sx={{ m: 1.5, width: '50ch' }}
                                >
                                    Upload Image
                                </Button>
                            </label>

                            {renderPhoto()}
                        </div>

                        <Button
                            variant='contained'
                            component='label'
                            sx={{ m: 1.5, width: '50ch' }}
                            size='large'
                            color='success'
                            id='update-content-btn'
                            onMouseDown={submitForm}
                            disabled={!enableButton}
                        >
                            Update Content
                            <input type='button' hidden />
                        </Button>
                    </div>
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default LandingPageContent;
