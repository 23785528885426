import { ThemeProvider } from "@emotion/react";
import { Button, Chip, ListItem, Paper } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { ITag, TagType } from "../../interfaces/TagsCollectionInterface";
import { InnerFormTheme } from "../../util/themes/GlobalThemeOverride";
import OnboardingAnswersTypeSelect from "../OnboardingAnswersTypeSelect/OnboardingAnswersTypeSelect";
import ProductCategorySelect from "../ProductCategorySelect/ProductCategorySelect";

interface TagsCollectionProps {
    tagType: TagType;
    returnValue: Function;
    receivedTags: ITag[];
}

const TagsCollection: FC<TagsCollectionProps> = (props) => {
    const { tagType, returnValue, receivedTags } = props;

    const [selectedTags, setSelectedTags] = useState<ITag[]>(receivedTags);

    const [currentValue, setCurrentValue] = useState<ITag>();

    const [enabledButton, setEnabledButton] = useState<boolean>(false);

    useEffect(() => {
        returnValue(selectedTags.map(tag => tag.object));

    }, [selectedTags]);

    useEffect(() => {
        if (checkConflict(currentValue)){
            setEnabledButton(false);

            return;
        }

        setEnabledButton(true);
    }, [currentValue]);

    const removeTag = (tag: ITag) => {
        const index = selectedTags.lastIndexOf(tag);

        if (index > -1) {
            selectedTags.splice(index, 1);
        }

        const newSelectedTags: ITag[] = JSON.parse(JSON.stringify(selectedTags));

        setSelectedTags(newSelectedTags);
    }

    const submitForm = () => {
        if (currentValue) {
            const newSelectedTags: ITag[] = JSON.parse(JSON.stringify(selectedTags));
            
            newSelectedTags.push(currentValue);

            setSelectedTags(newSelectedTags);

            setEnabledButton(false);
        }
    }

    const mapSelectedValue = (selectedValue: any) => {
        setCurrentValue({id: selectedValue.id, name: selectedValue.name, object: selectedValue})
    }

    const renderSelectComponent = () => {
        switch (tagType) {
            case TagType.AvailableCategories:
                return (<ProductCategorySelect
                    setValue={mapSelectedValue} iconComponent={undefined} selectedValue={""}
                />)
            case TagType.Flavours:
                return (<OnboardingAnswersTypeSelect
                    typeName={TagType.Flavours}
                    setValue={mapSelectedValue}
                />)
            case TagType.Types:
                return (<OnboardingAnswersTypeSelect
                    typeName={TagType.Types}
                    setValue={mapSelectedValue}
                />)
            default:
                return (<h1>RENDER COMPONENT</h1>)
        }
    }

    function checkConflict(value: ITag | undefined) {
        if (!value) {
            return true;
        }

        for (let tag of selectedTags) {
            if (tag.id === value.id) {
                return true;
            }
        }

        return false;
    }

    function renderChipList() {
        return (
            <Paper
                component='ul'
                className='tags-chipList'
                sx={{
                    p: 0,
                    m: 0,
                    boxShadow: 'none',
                    marginBottom: '10px',
                }}
            >
                {selectedTags.map((tag: ITag) => {
                    return (
                        <ListItem key={tag.id} sx={{alignItems: 'center', justifyContent:'center'}}>
                            <Chip 
                                label={tag.name}
                                onDelete={() => removeTag(tag)}
                            />
                        </ListItem>
                    )
                })}
            </Paper>
        )
    }

    return (
        <>
            {renderChipList()}
            <ThemeProvider theme={InnerFormTheme}>
                <Paper
                    className='tags-container'
                    sx={{
                        maxWidth: '500px',
                        alignItems: 'center',
                        boxShadow: 'none',

                    }}
                >
                    {renderSelectComponent()}

                    <Button
                            variant='contained'
                            component='label'
                            sx={{
                                width: '25ch',
                                marginBottom: '20px',
                                marginTop: '20px'
                            }}
                            size='large'
                            color='success'
                            onMouseDown={submitForm}
                            disabled={!enabledButton}
                        >
                            Add
                            <input type='button' hidden />
                    </Button>
                </Paper>
            </ThemeProvider>
        </>
    )
}

export default TagsCollection;