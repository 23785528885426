import { useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { IOrderData } from '../../interfaces/OrderInterface';
import GetPaymentResponsePage from './GetPaymentResponsePage/GetPaymentResponsePage';

import IncomingOrders from './IncomingOrders/IncomingOrders';
import ProcessedOrders from './ProcessedOrders/ProcessedOrders';
import ShowSingleOrder from './ShowSingleOrder/ShowSingleOrder';

const OrderPage = () => {
    const [selectedOrder, setSelectedOrder] = useState<IOrderData | null>(null);

    const history = useHistory();

    const renderShowSingle = () => {
        if (selectedOrder) {
            return <ShowSingleOrder order={selectedOrder} />;
        }
    };

    return (
        <div>
            <Route path={'/order/processed-orders'}>
                <ProcessedOrders setSelectedOrder={setSelectedOrder} />
            </Route>
            <Route path={'/order/incoming-orders'}>
                <IncomingOrders />
            </Route>
            <Route path={'/order/show-single'}>{renderShowSingle()}</Route>
            <Route path={'/order/payment-success'}>
                <GetPaymentResponsePage success={true} />
            </Route>
            <Route path={'/order/payment-fail'}>
                <GetPaymentResponsePage success={false} />
            </Route>
        </div>
    );
};
export default OrderPage;
