import './scss/main.scss';

import CloseIcon from '@mui/icons-material/Close';

import { Route, Switch } from 'react-router-dom';

import Login from './pages/Authentication/Login/Login';
import OrderPage from './pages/Order/OrderPage';
import ShopLocationPage from './pages/ShopLocation/ShopLocationsPage';
import AppTemplate from './templates/AppTemplate/AppTemplate';
import EmployeePage from './pages/Employee/EmployeePage';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ProductsPage from './pages/Product/ProductsPage';
import { useContext } from 'react';
import { AlertContext, AuthenticationContext } from './contexts';
import { Alert, IconButton } from '@mui/material';
import { RoleEnum } from './util/enums/Role';
import ArticlePage from './pages/Article/ArticlePage';
import CategoryPage from './pages/Categories/CategoryPage';
import ReviewPage from './pages/Review/ReviewPage';
import DefaultPage from './pages/DefaultPage/DefaultPage';
import AddPointsToUser from './pages/AddPointsToUser/AddPointsToUser';
import LandingPageContent from './pages/LandingPageContent/LandingPageContent';

function App() {
    const { getRole } = useContext(AuthenticationContext);

    const { showAlert, getAlertData } = useContext(AlertContext);

    const renderAlert = () => {
        const alertData = getAlertData();

        return alertData.show ? (
            <Alert
                sx={{
                    zIndex: 99,
                    maxWidth: '50ch',
                    alignSelf: 'center',
                    marginTop: '100px'
                }}
                variant='filled'
                severity={alertData.severity}
                action={
                    <IconButton
                        aria-label='close'
                        color='inherit'
                        size='small'
                        onClick={() => {
                            showAlert({
                                severity: undefined,
                                show: false,
                                content: ''
                            });
                        }}
                    >
                        <CloseIcon fontSize='inherit' />
                    </IconButton>
                }
            >
                {alertData.content}
            </Alert>
        ) : (
            <></>
        );
    };

    return (
        <>
            {!getRole() ? (
                <div className='login-wrapper'>
                    {renderAlert()}
                    <Login />
                </div>
            ) : getRole() === RoleEnum[0] ? (
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <Switch>
                        <AppTemplate>
                            <Route path={'/shoplocation'}>
                                <ShopLocationPage />
                            </Route>
                            
                            <Route path={'/employee'}>
                                <EmployeePage />
                            </Route>

                            <Route path={'/product'}>
                                <ProductsPage />
                            </Route>

                            <Route path={'/article'}>
                                <ArticlePage />
                            </Route>

                            <Route path={'/category'}>
                                <CategoryPage />
                            </Route>

                            <Route path={'/review'}>
                                <ReviewPage />
                            </Route>

                            <Route path={'/order'}>
                                <OrderPage />
                            </Route>

                            <Route path={'/landingpage/update-content'}>
                                <LandingPageContent />
                            </Route>

                            <Route path={'/points/add-points'}>
                                <AddPointsToUser />
                            </Route>

                            <Route exact path={'/'}>
                                <DefaultPage />
                            </Route>
                        </AppTemplate>
                    </Switch>
                </LocalizationProvider>
            ) : (
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <Switch>
                        <AppTemplate>
                            <Route path={'/order'}>
                                <OrderPage />
                            </Route>

                            <Route exact path={'/'}>
                                <DefaultPage />
                            </Route>
                        </AppTemplate>
                    </Switch>
                </LocalizationProvider>
            )}
        </>
    );
}

export default App;
