import { ThemeProvider } from '@emotion/react';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    AlertContext,
    OrderContext,
    ProductCategoryContext,
    ProductSubCategoryContext
} from '../../../contexts';
import { IProductCategoryData } from '../../../interfaces/ProductCategoryInterface';
import { IProductSubCategoryData } from '../../../interfaces/ProductSubCategoryInterface';
import { TableTheme } from '../../../util/themes/GlobalThemeOverride';
import {
    responsiveWidth,
    responsiveWidthHalfTable
} from '../../../util/themes/TableWidth';
import AllSubCategories from './components/AllSubCategories/AllSubCategories';

interface AllCategoriesProps {
    setCurrentCategory: Function;
    setCurrentSubCategory: Function;
}

const AllCategories: FC<AllCategoriesProps> = (props) => {
    const { setCurrentCategory, setCurrentSubCategory } = props;

    const { categories, loadData, deleteCategory } = useContext(
        ProductCategoryContext
    );

    const { getAllSubcategories } = useContext(ProductSubCategoryContext);

    const { showAlert } = useContext(AlertContext);

    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const [page, setPage] = useState<number>(0);

    const [rerender, setRerender] = useState<boolean>(false);

    const [subcategories, setSubcategories] = useState<
        IProductSubCategoryData[] | null
    >(null);

    const history = useHistory();

    useEffect(() => {
        getAllSubcategories().then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Cannot get data from server'
                });

                return;
            }

            setSubcategories(response);
        });
    }, []);

    useEffect(() => {
        if (rerender) {
            loadData();

            setRerender(false);
        }
    }, [rerender]);

    const handleDelete = (id: number) => {
        deleteCategory(id).then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Deleting category failed'
                });

                return;
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully deleted category'
            });

            setRerender(true);
        });
    };

    const showSingleCategory = (category: IProductCategoryData) => {
        setCurrentCategory(category);

        history.push('/category/show-single');
    };

    const isDisabled = (id: number) => {
        if (!subcategories) {
            return true;
        }

        for (let element of subcategories) {
            if (element.categoryId == id) {
                return true;
            }
        }

        return false;
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);

        setPage(0);
    };

    const renderData = () => {
        if (categories) {
            return categories
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: IProductCategoryData) => (
                    <TableRow key={row.id}>
                        <TableCell
                            align='left'
                            onMouseDown={() => {
                                showSingleCategory(row);
                            }}
                        >
                            {row.id}
                        </TableCell>

                        <TableCell
                            align='center'
                            onMouseDown={() => {
                                showSingleCategory(row);
                            }}
                        >
                            {row.name}
                        </TableCell>

                        <TableCell align='center'>
                            <Button
                                variant='contained'
                                component='span'
                                color='error'
                                onMouseDown={() => {
                                    handleDelete(row.id);
                                }}
                                disabled={isDisabled(row.id)}
                            >
                                Delete
                                <input type='button' hidden />
                            </Button>
                        </TableCell>
                    </TableRow>
                ));
        }
    };

    return (
        <div className='page-container categories-page'>
            <div className='categories-part'>
                <ThemeProvider theme={TableTheme}>
                    <Button
                        variant='contained'
                        color='success'
                        className='table-view-page-btn'
                        sx={{ marginBottom: 3 }}
                        onClick={() => {
                            history.push('/category/add-category');
                        }}
                    >
                        Add new Category
                        <input type='button' hidden />
                    </Button>

                    <Paper>
                        <TableContainer
                            component={Paper}
                            sx={{ width: responsiveWidthHalfTable }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='left'>ID</TableCell>

                                        <TableCell align='center'>
                                            Name
                                        </TableCell>

                                        <TableCell align='center'></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderData()}</TableBody>
                            </Table>
                        </TableContainer>
                        {categories ? (
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component='div'
                                count={categories.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        ) : (
                            <></>
                        )}
                    </Paper>
                </ThemeProvider>
            </div>
            <div className='subcategories-part'>
                <AllSubCategories
                    setCurrentSubCategory={setCurrentSubCategory}
                />
            </div>
        </div>
    );
};

export default AllCategories;
