import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

import { ThemeProvider } from '@emotion/react';
import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Chip
} from '@mui/material';

import { OrderContext } from '../../../contexts/OrderContext';
import { IOrderData } from '../../../interfaces/OrderInterface';
import { TableTheme } from '../../../util/themes/GlobalThemeOverride';
import RespondToOrderModal from './components/RespondToOrderModal/RespondToOrderModal';
import { AuthenticationContext } from '../../../contexts';
import { RoleEnum } from '../../../util/enums/Role';
import { printOrderOption } from '../../../util/functions/OrderOptions';
import { PaymentMethod, printPaymentMethod } from '../../../util/enums/PaymentMethod';

const IncomingOrders = () => {
	const { incomingOrders, readyOrder } = useContext(OrderContext);

	const { getRole } = useContext(AuthenticationContext)

	const [rowsPerPage, setRowsPerPage] = useState<number>(10);

	const [page, setPage] = useState<number>(0);

	const [showRespondModal, setShowRespondModal] = useState<boolean>(false);

	const [selectedOrder, setSelectedOrder] = useState<IOrderData | null>(null);

	const [rerender, setRerender] = useState<boolean>(false);

	const history = useHistory();

	useEffect(() => {
		if (rerender) {
			setRerender(false);
		}
	}, [rerender])

	const openRespondModal = (order: IOrderData) => {
		setSelectedOrder(order);

		setShowRespondModal(true);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);

		setPage(0);
	};

	const getPageClass = (): string => {
		if(getRole() === RoleEnum[0]){
			return 'page-container';
		}
		return ''
	}

	const renderButton = () => {
		// if(getRole() !== RoleEnum[0]){
			return (<Button
				variant='contained'
				color='secondary'
				onMouseDown={() => {
					history.push('/order/processed-orders');
				}}
				className='table-view-page-btn'
				sx={{ marginBottom: 3 }}
			>
				Processed Orders
				<input type='button' hidden />
			</Button>)
		// }

		// return <></>
	}

	const handleReadyClick = (orderId: number) => {
		readyOrder(orderId).then(response => {
			setRerender(true);
		}).catch(error => {
			console.log(`error on ready order ${orderId}`)
		})
	}

	const renderData = () => {
		if (incomingOrders) {
			return incomingOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: IOrderData) => (
				<TableRow
					key={row.id}
					sx={{
						'&:last-child td, &:last-child th': {
							border: 0
						}
					}}
					className={row.isReady ? 'ready-row' : ''}
				>
					<TableCell component='td' scope='row' onClick={() => {
						openRespondModal(row);
					}}>
						{row.id}
					</TableCell>

					{getRole() === RoleEnum[0]? (<TableCell align='center' onClick={() => {
						openRespondModal(row);
					}}>{row.shopLocationName}</TableCell>) : (<></>)}

					<TableCell align='center' onClick={() => {
						openRespondModal(row);
					}}>
						{moment.utc(row.arrivalTime).isValid() ? moment.utc(row.arrivalTime).local().format('D-MMM HH:mm') : 'As soon as possible'}
					</TableCell>

					<TableCell align='center' onClick={() => {
						openRespondModal(row);
					}}>{row.customerName}</TableCell>

					<TableCell align='center' onClick={() => {
						openRespondModal(row);
					}}>{row.priceTotal}</TableCell>

					<TableCell align='center' onClick={() => {
						openRespondModal(row);
					}}>
						<Chip 
							color={row.paymentMethod === PaymentMethod.CASH ? 'primary' : 'secondary'}
							label={printPaymentMethod(row.paymentMethod)}
						/>
					</TableCell>

					<TableCell align='center' sx={{fontSize:'0.750rem'}}>{printOrderOption(row.orderOption)}</TableCell>

					<TableCell align='center' onClick={() => {
						openRespondModal(row);
					}}>{moment.utc(row.createdAt).local().format('D-MMM HH:mm')}</TableCell>

					<TableCell align='center' onClick={() => {
						openRespondModal(row);
					}}>{row.phoneNumber}</TableCell>

					<TableCell align='center'>
                        <Button
                            variant='contained'
                            component='span'
                            color='primary'
                            onMouseDown={() => {
                                handleReadyClick(row.id);
                            }}
                        >
                            {row.isReady ? "Unready" : "Ready"}
                            <input type='button' hidden />
                        </Button>
                    </TableCell>
				</TableRow>
			));
		}
	};

	return (
		<div className={getPageClass()}>
			<ThemeProvider theme={TableTheme}>
				{renderButton()}
				<Paper>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align='left'>Id</TableCell>
									{getRole() === RoleEnum[0]? (<TableCell align='center'>Factory</TableCell>) : (<></>)}
									<TableCell align='center'>Arrival Time</TableCell>
									<TableCell align='center'>Email</TableCell>
									<TableCell align='center'>Price Total</TableCell>
									<TableCell align='center'>Payment Type</TableCell>
									<TableCell align='center'>Order Type</TableCell>
									<TableCell align='center'>Created At</TableCell>
									<TableCell align='center'>Phone Number</TableCell>
									<TableCell align='center'>Set Ready</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>{renderData()}</TableBody>
						</Table>
					</TableContainer>
					{incomingOrders ? (
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component='div'
							count={incomingOrders.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					) : (
						<></>
					)}
				</Paper>
			</ThemeProvider>
			<Modal
				isOpen={showRespondModal}
				onRequestClose={() => setShowRespondModal(false)}
				ariaHideApp={false}
				className='modal-container-respond'
				style={{overlay:{overflowY: 'scroll'}}}
			>
				{selectedOrder ? (
					<div>
						<RespondToOrderModal
							setShowRespondModal={setShowRespondModal}
							order={selectedOrder}
						/>
					</div>
				) : (
					<></>
				)}
			</Modal>
		</div>
	);
};

export default IncomingOrders;
