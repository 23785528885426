import { ThemeProvider } from '@emotion/react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useContext, useState } from 'react';
import { AuthenticationContext } from '../../contexts';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { RoleEnum } from '../../util/enums/Role';
import { getLocation } from '../../util/functions/NavBarLocation';

import { NavBarTheme } from '../../util/themes/GlobalThemeOverride';

const renderTabs = (logoutFunction: Function, getRole: Function, location: string, width: number) => {
    if (!getRole) {
        return <></>;
    }

    return getRole() === RoleEnum[0] ? (
        <ThemeProvider theme={NavBarTheme}>
            <Box className='header'>
                <Tabs 
                    value={location} 
                    variant={width > 1010 ? 'fullWidth' : 'scrollable'} 
                    centered={width > 1010 ? true : false}
                >
                    <Tab
                        value='review'
                        label='Reviews'
                        wrapped
                        href='/review/approve-reviews'
                    />

                    <Tab
                        value='article'
                        label='Articles'
                        wrapped
                        href='/article/show-all'
                    />

                    <Tab
                        value='employee'
                        label='Employees'
                        wrapped
                        href='/employee/show-all'
                    />

                    <Tab
                        value='landingpage'
                        label='Landing Page'
                        wrapped
                        href='/landingpage/update-content'
                    />

                    <Tab
                        value='product'
                        label='Products'
                        wrapped
                        href='/product/show-all'
                    />

                    <Tab
                        value='category'
                        label='Categories'
                        wrapped
                        href='/category/show-all'
                    />

                    <Tab
                        value='shoplocation'
                        label='Factories'
                        wrapped
                        href='/shoplocation/show-all'
                    />

                    <Tab
                        value='order'
                        label='Orders'
                        wrapped
                        href='/order/incoming-orders'
                    />

                    <Tab
                        value='points'
                        label='Add points'
                        wrapped
                        href='/points/add-points'
                    />

                    <Tab
                        value='logout'
                        label='Logout'
                        wrapped
                        sx={{
                            textAlign: 'left',
                            paddingLeft: 0,
                        }}
                        onMouseDown={() => {
                            logoutFunction();
                        }}
                    />
                </Tabs>
            </Box>
        </ThemeProvider>
    ) : (
        <ThemeProvider theme={NavBarTheme}>
            <Box className='header'>
                <Tabs value={location} variant='fullWidth' centered>
                    <Tab
                        value='order'
                        label='Orders'
                        wrapped
                        href='/order/incoming-orders'
                    />

                    <Tab
                        value='logout'
                        label='Logout'
                        wrapped
                        sx={{
                            maxWidth: '10ch',
                            textAlign: 'left',
                            paddingLeft: 0
                        }}
                        onMouseDown={() => {
                            logoutFunction();
                        }}
                    />
                </Tabs>
            </Box>
        </ThemeProvider>
    );
};

const NavBar = () => {
    const { logout, getRole } = useContext(AuthenticationContext);

    const [location, setLocation] = useState<string>(getLocation());

    const {height, width} = useWindowDimensions();

    return <>{renderTabs(logout, getRole, location, width)}</>;
};

export default NavBar;
