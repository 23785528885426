import React, { FC, useContext } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton } from '@mui/material';

import NavBar from '../../components/NavBar/NavBar';
import { AlertContext } from '../../contexts/AlertContext';
import { getLocation } from '../../util/functions/NavBarLocation';
import { RoleEnum } from '../../util/enums/Role';
import { VALID_LOCATIONS_ADMIN, VALID_LOCATIONS_WAITER } from '../../util/constants/ValidNavigationLocations';
import DefaultPage from '../../pages/DefaultPage/DefaultPage';
import { AuthenticationContext } from '../../contexts';

interface AppTemplateProps {
    children: React.ReactNode;
}

const AppTemplate: FC<AppTemplateProps> = (props) => {
    const { children } = props;

    const { getRole } = useContext(AuthenticationContext);

    const { showAlert, getAlertData } = useContext(AlertContext);


    const renderAlert = () => {
        const alertData = getAlertData();

        return alertData.show ? (
            <Alert
                sx={{
                    position: 'absolute',
                    left: '75%',
                    top: '9%',
                    zIndex: 99
                }}
                variant='filled'
                severity={alertData.severity}
                action={
                    <IconButton
                        aria-label='close'
                        color='inherit'
                        size='small'
                        onClick={() => {
                            showAlert({
                                severity: undefined,
                                show: false,
                                content: ''
                            });
                        }}
                    >
                        <CloseIcon fontSize='inherit' />
                    </IconButton>
                }
            >
                {alertData.content}
            </Alert>
        ) : (
            <></>
        );
    };

    const renderChildren = () =>{
        const location = getLocation();

        if(getRole() === RoleEnum[0]){
            if(VALID_LOCATIONS_ADMIN.includes(location)){
                return (<div className='page-wrapper'>{children}</div>)
            }

            window.location.href = '/employee/show-all';
        }

        if(VALID_LOCATIONS_WAITER.includes(location)){
            return (<>{children}</>)
        }

        window.location.href = '/order/incoming-orders';
    }

    return (
        <>
            {renderAlert()}
            <NavBar />
            {renderChildren()}
            
        </>
    );
};

export default AppTemplate;
