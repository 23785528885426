import React, { useContext, useState } from 'react';

import { Button, Paper, TextField, ThemeProvider } from '@mui/material';

import { AlertContext, AuthenticationContext } from '../../../contexts';
import { FormTheme } from '../../../util/themes/GlobalThemeOverride';
import { useHistory } from 'react-router-dom';
import { RoleEnum } from '../../../util/enums/Role';

const Login = () => {
    const { login, getRole } = useContext(AuthenticationContext);

    const { showAlert } = useContext(AlertContext);

    const history = useHistory();

    interface State {
        email: string;
        password: string;
    }

    const [values, setValues] = useState<State>({
        email: '',
        password: ''
    });

    const handleChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleLogin = () => {
        login(values).then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Login unsuccessful'
                });
                return;
            }

            if (getRole() === RoleEnum[0]) {
                window.location.href = '/employee/show-all';
                return;
            }

            window.location.href = '/order/incoming-orders';
        });
    };

    const validateForm = (prop: keyof State, value: string) => {
        if (value === '') {
            return true;
        }

        return false;
    };

    const validateAll = () => {
        if (values.email === '' || values.password === '') {
            return false;
        }

        return true;
    };

    return (
        <div className='page-container login-container'>
            <ThemeProvider theme={FormTheme}>
                <Paper className='paper-form'>
                    <h1>Login</h1>
                    <div className='form-container'>
                        <TextField
                            required
                            label='Email'
                            defaultValue=''
                            key='email'
                            onChange={handleChange('email')}
                            error={validateForm('email', values.email)}
                            helperText={
                                validateForm('email', values.email)
                                    ? 'Email cannot be empty'
                                    : ''
                            }
                        ></TextField>

                        <TextField
                            required
                            label='Password'
                            defaultValue=''
                            key='password'
                            type='password'
                            onChange={handleChange('password')}
                            error={validateForm('password', values.password)}
                            helperText={
                                validateForm('password', values.password)
                                    ? 'Password cannot be empty'
                                    : ''
                            }
                        ></TextField>

                        <Button
                            variant='contained'
                            component='span'
                            size='large'
                            color='success'
                            onMouseDown={handleLogin}
                            sx={{ marginBottom: '40px', width: '50ch' }}
                            // sx={
                            //     validateAll()
                            //         ? {
                            //               display: 'inline-block',
                            //               marginBottom: '40px',
                            //               width: '50ch',
                            //               textAlign: 'center'
                            //           }
                            //         : { display: 'none' }
                            // }
                            disabled={!validateAll()}
                        >
                            Login
                            <input type='button' hidden />
                        </Button>
                    </div>
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default Login;
