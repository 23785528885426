import { ThemeProvider } from '@emotion/react';
import { SvgIconComponent } from '@mui/icons-material';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { ProductCategoryContext } from '../../contexts';
import { IProductCategoryData } from '../../interfaces/ProductCategoryInterface';
import { selectTheme } from '../../util/themes/GlobalThemeOverride';

interface ProductCategorySelectProps {
	iconComponent: SvgIconComponent | undefined;
	setValue: Function;
	selectedValue: string;
}

const ProductCategorySelect: FC<ProductCategorySelectProps> = (props) => {
	const { iconComponent, setValue, selectedValue } = props;

	const { categories } = useContext(ProductCategoryContext);

	const [selectedCategoryValue, setSelectedCategoryValue] = useState('');

	const handleSelectChange = () => (event: SelectChangeEvent) => {
		setSelectedCategoryValue(event.target.value);

		setValue(getCategoryFromName(event.target.value));
	};

	useEffect(() => {
		if (selectedValue) {
			setSelectedCategoryValue(selectedValue);
		}
	}, []);

	const getCategoryFromName = (name: string) => {
		if (categories) {
			for (let category of categories) {
				if (category.name === name) {
					return category;
				}
			}

			return null;
		}

		return null;
	};

	const renderSelectData = () => {
		if (categories) {
			return categories.map((category: IProductCategoryData) => (
				<MenuItem key={category.id} value={category.name}>
					{category.name}
				</MenuItem>
			));
		}

		return <></>;
	};

	return (
		<ThemeProvider theme={selectTheme}>
			<Select
				label='Select Category'
				variant='filled'
				onChange={handleSelectChange()}
				IconComponent={iconComponent}
				value={selectedCategoryValue}
			>
				<MenuItem key={0} value={''} sx={{ height: '35.63px' }}>
					{' '}
				</MenuItem>
				{renderSelectData()}
			</Select>
		</ThemeProvider>
	);
};

export default ProductCategorySelect;
