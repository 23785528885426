import { AxiosError, AxiosResponse } from 'axios';
import { createContext, FC, useContext } from 'react';

import {
    IEmployeeData,
    IEmployeeRegistrationData
} from '../interfaces/EmployeeInterface';
import { AuthenticationContext, AxiosContext } from './';

interface IEmployeeContext {
    getAllEmployees(): Promise<IEmployeeData[] | null>;
    deleteEmployee(id: number): Promise<boolean>;
    addEmployee(data: IEmployeeRegistrationData): Promise<boolean>;
    updateEmployee(data: IEmployeeData): Promise<boolean>;
}

const EmployeeContext = createContext({} as IEmployeeContext);

interface EmployeeContextProps {
    children: React.ReactNode;
}

const EmployeeContextProvider: FC<EmployeeContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const { getToken } = useContext(AuthenticationContext);

    const getAllEmployees = () => {
        return axiosInstance({
            method: 'GET',
            url: 'api/employee/getall',
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const deleteEmployee = (id: number) => {
        return axiosInstance({
            method: 'DELETE',
            url: `api/employee/delete/${id}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const addEmployee = (data: IEmployeeRegistrationData) => {
        return axiosInstance({
            method: 'POST',
            url: 'api/employee/add',
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                'Content-Type': 'application/json'
            },
            data
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const updateEmployee = (data: IEmployeeData) => {
        return axiosInstance({
            method: 'PUT',
            url: 'api/employee/update',
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                'Content-Type': 'application/json'
            },
            data
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const providerValue = {
        getAllEmployees,
        deleteEmployee,
        addEmployee,
        updateEmployee
    };

    return (
        <EmployeeContext.Provider value={providerValue}>
            {props.children}
        </EmployeeContext.Provider>
    );
};

export { EmployeeContext, EmployeeContextProvider };
