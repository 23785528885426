import { createTheme } from '@mui/material/styles';

export const TableTheme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: 'Raleway',
                    fontWeight: 400,
                    color: 'white',
                    maxWidth: 180,
                    wordWrap: 'break-word'
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(107, 107, 107)',
                    width: '100%'
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(107, 107, 107)',
                    fontFamily: 'Raleway',
                    color: 'white',
                    fontWeight: 400
                }
            }
        },
        MuiPaper:{
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        }
    }
});

export const NavBarTheme = createTheme({
    components: {
        MuiTabs: {
            styleOverrides: {
                root: {
                    height: 50,
                    width: '100%'
                    //minWidth: '600px'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: 20,
                    fontFamily: 'Raleway',
                    fontWeight: 400,
                    color: 'white',
                    wordWrap: 'break-word'
                    //minWidth: '120px'
                }
            }
        }
    }
});

export const DeleteConfirmModalTheme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(107, 107, 107)',
                    width: '100%',
                    borderRadius: 30,
                    maxWidth: '700px',
                    minWidth: '200px'
                }
            }
        }
    }
})

export const FormTheme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(107, 107, 107)',
                    width: '100%',
                    borderRadius: 30,
                    maxWidth: '500px',
                    minWidth: '500px'
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                    maxWidth: '400px',
                    marginBottom: 20,

                    fontFamily: 'Raleway',
                    fontWeight: 300,
                    color: 'green',

                    '& .MuiFormLabel-root.Mui-error': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiFormHelperText-root.Mui-error': {
                        color: 'red !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiInputLabel-root.Mui-error': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiFormLabel-root.Mui-focused': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiFormHelperText-root.Mui-focused': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiOutlinedInput-input': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiInputLabel-root': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiOutlinedInput-root.Mui-error': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiOutlinedInput-root.Mui-focused': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiOutlinedInput-root': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Raleway',
                    fontWeight: 300,
                    color: 'white',
                    fontSize: '1rem',
                    lineHeight: '1.437em',
                    letterSpacing: '0.00938em'
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    fontFamily: 'Raleway',
                    fontWeight: 400,
                    color: 'white',
                    fontSize: '1rem',
                    lineHeight: '1.437em'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    paddingTop: '15px'
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: 'white',
                    fontFamily: 'Raleway',
                    fontWeight: 400
                }
            }
        }
    }
});

export const respondModalTheme = createTheme({
    components:{
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(107, 107, 107)',
                    borderRadius: 30,
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                    maxWidth: '400px',
                    marginBottom: 7,

                    fontFamily: 'Raleway',
                    fontWeight: 300,
                    color: 'green',

                    '& .MuiFormLabel-root.Mui-error': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiInputLabel-root.Mui-error': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiFormLabel-root.Mui-focused': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiFormHelperText-root.Mui-focused': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiOutlinedInput-input': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiInputLabel-root': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiOutlinedInput-root.Mui-error': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiOutlinedInput-root.Mui-focused': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiOutlinedInput-root': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Raleway',
                    fontWeight: 300,
                    color: 'white',
                    fontSize: '1rem',
                    lineHeight: '1.437em',
                    letterSpacing: '0.00938em'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    paddingTop: '15px'
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: 'white',
                    fontFamily: 'Raleway',
                    fontWeight: 400
                }
            }
        }
    }
})

export const InnerFormTheme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(107, 107, 107)',
                    width: '100%',
                    borderRadius: 30,
                    maxWidth: '200px',
                    minWidth: '200px',
                    boxShadow: 'none'
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                    maxWidth: '400px',
                    marginBottom: 20,

                    fontFamily: 'Raleway',
                    fontWeight: 300,
                    color: 'green',

                    '& .MuiFormLabel-root.Mui-error': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiFormHelperText-root.Mui-error': {
                        color: 'red !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiInputLabel-root.Mui-error': {
                        color: 'red !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiFormLabel-root.Mui-focused': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiFormHelperText-root.Mui-focused': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiOutlinedInput-input': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiInputLabel-root': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiOutlinedInput-root.Mui-error': {
                        color: 'red !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiOutlinedInput-root.Mui-focused': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    },
                    '& .MuiOutlinedInput-root': {
                        color: 'white !important',
                        fontFamily: 'Raleway',
                        fontWeight: 300
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Raleway',
                    fontWeight: 300,
                    color: 'white',
                    fontSize: '1rem',
                    lineHeight: '1.437em',
                    letterSpacing: '0.00938em'
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    fontFamily: 'Raleway',
                    fontWeight: 400,
                    color: 'white',
                    fontSize: '1rem',
                    lineHeight: '1.437em'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    paddingTop: '15px'
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: 'white',
                    fontFamily: 'Raleway',
                    fontWeight: 400
                }
            }
        }
    }
});

export const selectTheme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    maxWidth: '300px',
                    backgroundColor: 'rgb(107, 107, 107)',
                    borderRadius: 30,
                    boxShadow: 'none'
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    maxHeight: '350px'
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: 'white',
                    fontFamily: 'Raleway',
                    fontWeight: 400
                }
            }
        }
    }
});
