import { createContext, FC } from 'react';
import * as signalR from '@microsoft/signalr';
import config from '../config/config';

interface IWebSocketContext {
    orderHubConnection: signalR.HubConnection
}

const WebSocketContext = createContext({} as IWebSocketContext);

interface WebSocketContextProps {
    children: React.ReactNode;
}

const WebSocketContextProvider: FC<WebSocketContextProps> = (props) => {
    
    let orderHubConnection: signalR.HubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${config.backendUrl}/OrderHub`)
            .build();

    
    const providerValue = { orderHubConnection };

    return (
        <WebSocketContext.Provider value={providerValue}>
            {props.children}
        </WebSocketContext.Provider>
    );
};

export { WebSocketContext, WebSocketContextProvider };
