import one from '../../assets/WaysOfPreparationImages/1.jpg'
import two from '../../assets/WaysOfPreparationImages/2.jpg'
import three from '../../assets/WaysOfPreparationImages/3.jpg'
import four from '../../assets/WaysOfPreparationImages/4.jpg'
import five from '../../assets/WaysOfPreparationImages/5.jpg'
import six from '../../assets/WaysOfPreparationImages/6.jpg'
import seven from '../../assets/WaysOfPreparationImages/7.jpg'

export const ONBOARDING_ANSWER_FLAVOURS_NAME = 'Flavours';

export const ONBOARDING_ANSWER_TYPES_NAME = 'Types of coffee';

export const WaysOfPreparation = [
    {key: 0, value: "Espresso", image: one},
    {key: 1, value: "PourOver", image: two},
    {key: 2, value: "Drip", image: three},
    {key: 3, value: "AeroPress", image: four},
    {key: 4, value: "FrenchPress", image: five},
    {key: 5, value: "Syphon", image: six},
    {key: 6, value: "MokaPot", image: seven},
]

export const GROUND_COFFEE_ID = 3003; 