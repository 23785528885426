import { ThemeProvider } from '@emotion/react';
import {
    Button,
    Checkbox,
    FormHelperText,
    InputLabel,
    Paper,
    styled,
    TextField
} from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import AddSize from '../../../components/AddSize/AddSize';
import LanguageSelection from '../../../components/LanguageSelection/LanguageSelection';
import ProductCategorySelect from '../../../components/ProductCategorySelect/ProductCategorySelect';
import ProductSubCategorySelect from '../../../components/ProductSubCategorySelect/ProductSubCategorySelect';
import TagsCollection from '../../../components/TagsCollection/TagsCollection';
import { AlertContext, OnboardingAnswersTypesContext, ProductContext } from '../../../contexts';
import { IProductCategoryData } from '../../../interfaces/ProductCategoryInterface';
import { IProductData } from '../../../interfaces/ProductInterface';
import { IProductSubCategoryData } from '../../../interfaces/ProductSubCategoryInterface';
import { ISizeData } from '../../../interfaces/SizeInterface';
import { ITag, TagType } from '../../../interfaces/TagsCollectionInterface';
import { GROUND_COFFEE_ID, WaysOfPreparation } from '../../../util/constants/Constants';
import { FormTheme } from '../../../util/themes/GlobalThemeOverride';

interface ShowSingleProductProps {
    product: IProductData;
}

const Input = styled('input')({
    display: 'none'
});

const ShowSingleProduct: FC<ShowSingleProductProps> = (props) => {
    const { showAlert } = useContext(AlertContext);

    const { getFlavours, getTypesOfCoffee } = useContext(
        OnboardingAnswersTypesContext
    );

    const { updateProduct, getById } = useContext(ProductContext);

    const [values, setValues] = useState<IProductData>(props.product);

    const [image, setImage] = useState<File | null>(null);

    const [previewImage, setPreviewImage] = useState<string>(props.product.imageUrl);

    const [enableButton, setEnableButton] = useState<boolean>(false);

    const [checked, setChecked] = useState<boolean>(props.product.isDeliverable);

    const [waysOfPreparationChecked, setWaysOfPreparationChecked] = useState<boolean[]>(mapCheckedWays());

    const [selectedCategory, setSelectedCategory] =
        useState<IProductCategoryData | null>(null);

    const [selectedSubCategory, setSelectedSubCategory] =
        useState<IProductSubCategoryData | null>(null);

    const [selectedWaysOfPreparation, setSelectedWaysOfPreparation] = 
        useState<number[]>(values.waysOfPreparation);

    const [languageChanged, setLanguageChanged] = useState<boolean>(false);

    const history = useHistory();

    const [showDetails, setShowDetails] = useState<boolean>(shouldShowDetails())

    useEffect(() => {
        if (validateAll()) {
            setEnableButton(true);
        }
    }, [values.name, values.sizes, values.subcategoryId]);

    useEffect(() => {
        if (languageChanged) {
            loadData();

            setLanguageChanged(false);
        }
    }, [languageChanged]);

    useEffect(() => {
        if (selectedWaysOfPreparation === values.waysOfPreparation) return;

        setValues({...values, waysOfPreparation: selectedWaysOfPreparation})
    }, [selectedWaysOfPreparation])

    useEffect(() => {
        if (selectedSubCategory) {
            setValues({ ...values, subcategoryId: selectedSubCategory.id });
        }
    }, [selectedSubCategory]);

    useEffect(() => {
        if (selectedCategory){
            const shouldShow = shouldShowDetails();
    
            setShowDetails(shouldShow);
        }
    }, [selectedCategory])

    const loadData = () => {
        getById(values.id).then((response) => {
            if (response === null) {
                showAlert({
                    severity: 'error',

                    show: true,
                    content: 'Cannot get data from server'
                });

                history.push('/product/show-all');

                return;
            }

            setValues(response);
        });
    };

    const handleChange =
        (prop: keyof IProductData) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleChangeImage =
        () => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                const file = event.target.files[0];

                setImage(file);

                fileToBase64(file);
            }
        };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setChecked(event.target.checked);

        setValues({ ...values, isDeliverable: event.target.checked });
    };

    const validateForm = (
        prop: keyof IProductData,
        value: string | number | ISizeData[]
    ) => {
        if (values.sizes.length === 0) {
            return true;
        }

        if (value === '' || value === 0) {
            return true;
        }

        return false;
    };

    const validateAll = () => {
        if (validateForm('name', values.name)) {
            return false;
        }

        if (validateForm('sizes', values.sizes)) {
            return false;
        }

        if (validateForm('subcategoryId', values.subcategoryId)) {
            return false;
        }

        return true;
    };

    const renderPhoto = () => {
        if (image || values.imageUrl) {
            return (
                <img
                    className='image-preview'
                    src={`${previewImage}`}
                    alt='preview'
                />
            );
        }
    };

    const mapTypesOfCoffee = () => {
        const received: ITag[] = [];
        const types = getTypesOfCoffee();
        if ( types !== null) {
            for (let type of types) {
                if (values.typeOfCoffeeTags.includes(type.id)){
                    received.push({id: type.id, name: type.name, object: type});
                }
            }
        }

        return received;
    }

    const mapFlavours = () => {
        const received: ITag[] = [];
        const flavours = getFlavours();
        if (flavours !== null) {
            for (let flavour of flavours) {
                if (values.flavourTags.includes(flavour.id)){
                    received.push({id: flavour.id, name: flavour.name, object: flavour});
                }
            }
        }

        return received;
    }

    const submitForm = () => {
        let formData = new FormData();

        formData.append('data', JSON.stringify(values));

        if (image) {
            formData.append('Images', image, image?.name);
        }

        updateProduct(formData).then((response: boolean) => {
            if (response === false) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Updating product failed'
                });

                return;
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully updated product'
            });
        });
    };

    const fileToBase64 = async (file: File | undefined) => {
        if (file !== undefined) {
            const reader = new FileReader();

            reader.onloadend = () => {
                let base64data: string = reader.result as string;

                setPreviewImage(base64data);
            };

            reader.readAsDataURL(file);
            return;
        }
        setImage(null);
    };

    const renderSubCategoryHelperText = () => {
        if (values.subcategoryId <= 0) {
            return (
                <FormHelperText sx={{ color: '#d32f2f' }}>
                    Subcategory is required
                </FormHelperText>
            );
        }

        return <FormHelperText sx={{ color: '#d32f2f' }}> </FormHelperText>;
    };

    const setValuesSize = (data: ISizeData[]) => {
        setValues({ ...values, sizes: data });
    };

    const setValuesTypes = (data: any[]) => {
        setValues({ ...values, typeOfCoffeeTags: data.map(e => e.id) });
    };

    const setValuesFlavours = (data: any[]) => {
        setValues({ ...values, flavourTags: data.map(e => e.id) });
    };

    function shouldShowDetails(): boolean {
        if (!selectedCategory) {
            if(props.product.categoryId === GROUND_COFFEE_ID) return true

            return false;
        }

        if (selectedCategory.id === GROUND_COFFEE_ID) return true

        return false;
    }

    function mapCheckedWays() {
        const checkedWays: boolean[] = [];

        for (let way of values.waysOfPreparation){
            checkedWays[way] = true;
        }

        return checkedWays;
    }

    const handleWaysOfPreparationCheckbox = (index: number, checked: boolean) => {
        let oldWaysList: number[] = JSON.parse(JSON.stringify(selectedWaysOfPreparation));
        let oldWaysChecks: boolean[] = JSON.parse(JSON.stringify(waysOfPreparationChecked));

        oldWaysChecks[index] = checked;

        setWaysOfPreparationChecked(oldWaysChecks);

        if (checked) {
            if (!oldWaysList.includes(index)) {
                oldWaysList.push(index);
            }
        }else {
            oldWaysList = oldWaysList.filter(e => (e !== index));
        }

        setSelectedWaysOfPreparation(oldWaysList);
    }

    return (
        <div className='page-container add-form-container update-product-container'>
            <ThemeProvider theme={FormTheme}>
                <div className='any-product-data'>
                    <Paper className='paper-form'>
                        <h1>Update Product</h1>
                        <div className='form-container'>
                            <LanguageSelection
                                setIsChanged={setLanguageChanged}
                            />

                            <TextField
                                key='name'
                                label='Name'
                                value={values.name}
                                onChange={handleChange('name')}
                                error={validateForm('name', values.name)}
                                helperText={
                                    validateForm('name', values.name)
                                        ? 'Name cannot be empty'
                                        : ' '
                                }
                            ></TextField>

                            <div className='inner-form-products size-form'>
                                <AddSize
                                    setValue={setValuesSize}
                                    receivedSizes={values.sizes}
                                />
                            </div>

                            <div className='inner-form-products'>
                                <InputLabel
                                    sx={{
                                        marginBottom: '10px'
                                    }}
                                >
                                    Select Flavours
                                </InputLabel>
                                <TagsCollection
                                    tagType={TagType.Flavours}
                                    returnValue={setValuesFlavours}
                                    receivedTags={mapFlavours()}
                                />
                            </div>

                            <div className='inner-form-products'>
                                <InputLabel
                                    sx={{
                                        marginBottom: '10px'
                                    }}
                                >
                                    Select Types Of Coffee
                                </InputLabel>

                                <TagsCollection
                                    tagType={TagType.Types} 
                                    returnValue={setValuesTypes} 
                                    receivedTags={mapTypesOfCoffee()}                                
                                />
                            </div>

                            <div className='checkbox-container'>
                                <InputLabel sx={{ top: '10px' }}>
                                    Is Deliverable
                                </InputLabel>

                                <Checkbox
                                    checked={checked}
                                    onChange={handleCheckboxChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color='primary'
                                />
                            </div>

                            <TextField
                                key='description'
                                label='Description'
                                value={values.description}
                                onChange={handleChange('description')}
                            ></TextField>

                            <div className='category-select-container'>
                                <div className='category-select-column'>
                                    <InputLabel
                                        sx={{
                                            marginBottom: '10px'
                                        }}
                                    >
                                        Select Category *
                                    </InputLabel>
                                    <ProductCategorySelect
                                        iconComponent={undefined}
                                        setValue={setSelectedCategory}
                                        selectedValue={values.categoryName}
                                    />
                                    {renderSubCategoryHelperText()}
                                </div>

                                <div className='category-select-column'>
                                    {values.subcategoryId ? (
                                        <>
                                            <InputLabel
                                                sx={{
                                                    marginBottom: '10px'
                                                }}
                                            >
                                                Select SubCategory *
                                            </InputLabel>
                                            <ProductSubCategorySelect
                                                iconComponent={undefined}
                                                setValue={
                                                    setSelectedSubCategory
                                                }
                                                categoryId={
                                                    selectedCategory
                                                        ? selectedCategory.id
                                                        : values.categoryId
                                                }
                                                selectedValue={
                                                    values.subcategoryName
                                                }
                                            />
                                            {renderSubCategoryHelperText()}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            <div className='image-upload-container-showProduct'>
                                <label htmlFor='contained-button-file'>
                                    <Input
                                        accept='.png, .jpg, .jpeg'
                                        id='contained-button-file'
                                        multiple
                                        type='file'
                                        key='image'
                                        onChange={handleChangeImage()}
                                    />

                                    <Button
                                        variant='contained'
                                        component='span'
                                        sx={{ m: 1.5, width: '50ch' }}
                                    >
                                        Upload Image
                                    </Button>
                                </label>

                                {renderPhoto()}
                            </div>

                            <Button
                                variant='contained'
                                component='label'
                                sx={{ m: 1.5, width: '50ch' }}
                                size='large'
                                color='success'
                                onMouseDown={submitForm}
                                disabled={!(enableButton)}
                            >
                                Update Product
                                <input type='button' hidden />
                            </Button>
                        </div>
                    </Paper>
                </div>
                {showDetails ? (
                    <div className='ground-coffee-data'>
                        <Paper className='paper-form'>
                            <h1>Ground Coffee Data</h1>
                            <div className='form-container'>
                                <TextField
                                    key='origin'
                                    label='Origin'
                                    value={values.origin}
                                    onChange={handleChange('origin')}
                                ></TextField>

                                <TextField
                                    key='plantationLocation'
                                    label='Plantation Location'
                                    value={values.plantationLocation}
                                    onChange={handleChange(
                                        'plantationLocation'
                                    )}
                                ></TextField>

                                <TextField
                                    key='coffeebeanType'
                                    label='Coffee Bean Type'
                                    value={values.coffeeBeanType}
                                    onChange={handleChange('coffeeBeanType')}
                                ></TextField>

                                <TextField
                                    key='wayOfRoasting'
                                    label='Ways of roasting'
                                    value={values.waysOfRoasting}
                                    onChange={handleChange('waysOfRoasting')}
                                ></TextField>

                                <TextField
                                    key='wannaKnowMore'
                                    label='Wanna Know More'
                                    value={values.wannaKnowMore}
                                    onChange={handleChange('wannaKnowMore')}
                                ></TextField>

                                <InputLabel
                                        sx={{
                                            marginBottom: '10px'
                                        }}
                                    >
                                        Select way of preparation
                                </InputLabel>
                                {
                                    WaysOfPreparation.map(way => (
                                        <div>
                                            <img
                                                className='ways-of-preparation-icon'
                                                src={way.image}
                                            />
                                            <Checkbox 
                                                checked={waysOfPreparationChecked[way.key]}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleWaysOfPreparationCheckbox(way.key, event.target.checked)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                color='primary'
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </Paper>
                    </div>
                ) : (
                    <></>
                )}
            </ThemeProvider>
        </div>
    );
};

export default ShowSingleProduct;
