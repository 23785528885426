import { AxiosError, AxiosResponse } from 'axios';
import { createContext, FC, useContext } from 'react';
import { AuthenticationContext, AxiosContext } from '.';
import { ILandingPageContentData } from '../interfaces/LandingPageContentInterface';

interface ILandingPageContentContext {
    getContent(): Promise<ILandingPageContentData | null>;
    updateContent(data: ILandingPageContentData): Promise<boolean>;
}

const LandingPageContentContext = createContext(
    {} as ILandingPageContentContext
);

interface LandingPageContentContextProps {
    children: React.ReactNode;
}

const LandingPageContentContextProvider: FC<LandingPageContentContextProps> = (
    props
) => {
    const { axiosInstance } = useContext(AxiosContext);

    const { getToken } = useContext(AuthenticationContext);

    const getContent = () => {
        return axiosInstance({
            method: 'GET',
            url: `api/landingpagecontent/getcontent`
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                console.log(response);

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const updateContent = (data: ILandingPageContentData) => {
        return axiosInstance({
            method: 'PUT',
            url: `api/landingpagecontent/update`,
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                'Content-Type': 'application/json'
            },
            data
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const providerValue = {
        getContent,
        updateContent
    };

    return (
        <LandingPageContentContext.Provider value={providerValue}>
            {props.children}
        </LandingPageContentContext.Provider>
    );
};

export { LandingPageContentContext, LandingPageContentContextProvider };
