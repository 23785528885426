import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { ThemeProvider } from '@emotion/react';
import {
    Button,
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';

import { AuthenticationContext } from '../../../contexts';
import { AlertContext } from '../../../contexts/AlertContext';
import { EmployeeContext } from '../../../contexts/EmployeeContext';
import { IEmployeeData } from '../../../interfaces/EmployeeInterface';
import { printRoleChip } from '../../../util/functions/Role';
import { TableTheme } from '../../../util/themes/GlobalThemeOverride';
import { responsiveWidth } from '../../../util/themes/TableWidth';

const AllEmployees = () => {
    const { getAllEmployees, deleteEmployee } = useContext(EmployeeContext);

    const { showAlert } = useContext(AlertContext);

    const { getEmail } = useContext(AuthenticationContext);

    const [rows, setRows] = useState<IEmployeeData[] | null>([]);

    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const [page, setPage] = useState<number>(0);

    const [rerender, setRerender] = useState<boolean>(false);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (rerender) {
            loadData();

            setRerender(false);
        }
    }, [rerender]);

    const loadData = () => {
        getAllEmployees().then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: "Can't get data from server"
                });
            }

            setRows(response);
        });
    };

    const handleDelete = (id: number) => {
        deleteEmployee(id).then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Deleting employee failed'
                });

                return;
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully deleted employee'
            });

            setRerender(true);
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);

        setPage(0);
    };

    const renderData = () => {
        if (rows) {
            return rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: IEmployeeData) => (
                    <TableRow key={row.id}>
                        <TableCell align='left'>{row.id}</TableCell>

                        <TableCell align='center'>{row.email}</TableCell>

                        <TableCell align='center'>
                            <Chip
                                color={printRoleChip(row.role).color}
                                label={printRoleChip(row.role).text}
                            />
                        </TableCell>

                        <TableCell align='center'>
                            <Button
                                variant='contained'
                                component='span'
                                color='error'
                                disabled={row.email === getEmail()}
                                onMouseDown={() => {
                                    handleDelete(row.id);
                                }}
                            >
                                Delete
                                <input type='button' hidden />
                            </Button>
                        </TableCell>
                    </TableRow>
                ));
        }
    };

    return (
        <div className='page-container'>
            <ThemeProvider theme={TableTheme}>
                <Button
                    variant='contained'
                    color='success'
                    onClick={() => {
                        window.location.href = '/employee/add-employee';
                    }}
                    className='table-view-page-btn'
                    sx={{ marginBottom: 3 }}
                >
                    Add new Employee
                    <input type='button' hidden />
                </Button>
                <Paper>
                    <TableContainer
                        component={Paper}
                        sx={{
                            width: responsiveWidth
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>ID</TableCell>
                                    <TableCell align='center'>Email</TableCell>
                                    <TableCell align='center'>Role</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderData()}</TableBody>
                        </Table>{' '}
                        {rows ? (
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component='div'
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        ) : (
                            <></>
                        )}
                    </TableContainer>
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default AllEmployees;
