import { ThemeProvider } from "@emotion/react";
import { Button, Paper } from "@mui/material";
import { FC } from "react";
import { DeleteConfirmModalTheme } from "../../util/themes/GlobalThemeOverride";

interface DeleteConfirmationModalProps {
  deleteFunction: Function;
  closeModalFunction: Function;
  entityDeleteType: string;
}

const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = (props) => {
  const { deleteFunction, entityDeleteType, closeModalFunction } = props;

  return (
    <div className="modal-respond">
      <ThemeProvider theme={DeleteConfirmModalTheme}>
        <Paper className="paper-form">
          <h1>Are you sure you want to delete {entityDeleteType}?</h1>

          <div className='delete-modal-button-container'>
            <Button
              variant="contained"
              component="span"
              color="error"
              onMouseDown={() => {
                deleteFunction();
              }}
              sx={{
                fontSize: "20px",
                fontWeight: 200,
                lineHeight: 1.8,
                minHeight: "60px",
                minWidth: "100px",
              }}
            >
              Yes
            </Button>

            <Button
              variant="contained"
              component="span"
              color="primary"
              size="large"
              onMouseDown={() => {
                closeModalFunction(0);
              }}
              sx={{
                fontSize: "20px",
                fontWeight: 200,
                lineHeight: 1.8,
                minHeight: "60px",
                minWidth: "100px",
              }}
            >
              Close
            </Button>
          </div>
        </Paper>
      </ThemeProvider>
    </div>
  );
};

export default DeleteConfirmationModal;
