import { TurnedInTwoTone } from '@mui/icons-material';
import { AxiosError, AxiosResponse } from 'axios';
import { createContext, FC, useContext, useState, useEffect } from 'react';
import {
    AlertContext,
    AuthenticationContext,
    AxiosContext,
    LanguageContext
} from '.';
import { IProductCategoryData } from '../interfaces/ProductCategoryInterface';

interface IProductCategoryContext {
    getAllCategories(): Promise<IProductCategoryData[] | null>;
    categories: IProductCategoryData[] | null;
    loadData(): void;
    deleteCategory(id: number): Promise<boolean>;
    addCategory(data: FormData): Promise<boolean>;
    updateCategory(data: FormData): Promise<boolean>;
    getById(id: number): Promise<IProductCategoryData | null>;
}

const ProductCategoryContext = createContext({} as IProductCategoryContext);

interface ProductCategoryContextProps {
    children: React.ReactNode;
}

const ProductCategoryContextProvider: FC<ProductCategoryContextProps> = (
    props
) => {
    const { axiosInstance } = useContext(AxiosContext);

    const { showAlert } = useContext(AlertContext);

    const { getToken, getRole } = useContext(AuthenticationContext);

    const [categories, setCategories] = useState<IProductCategoryData[] | null>(
        []
    );

    const { getLanguage } = useContext(LanguageContext);

    useEffect(() => {
        if (!getRole()) {
            return;
        }

        loadData();
    }, []);

    const loadData = () => {
        getAllCategories().then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: "Can't get data from server"
                });

                return;
            }

            setCategories(response);
        });
    };

    const getAllCategories = () => {
        return axiosInstance({
            method: 'GET',
            url: 'api/productcategory/getall',
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const deleteCategory = (id: number) => {
        return axiosInstance({
            method: 'DELETE',
            url: `api/productcategory/delete/${id}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const addCategory = (data: FormData) => {
        return axiosInstance({
            method: 'POST',
            url: 'api/productcategory/add',
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                'Content-Type': 'multipart/form-data'
            },
            data
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const updateCategory = (data: FormData) => {
        return axiosInstance({
            method: 'PUT',
            url: 'api/productcategory/update',
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                'Content-Type': 'multipart/form-data',
                Language: getLanguage().toString()
            },
            data
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const getById = (id: number) => {
        return axiosInstance({
            method: 'GET',
            url: `api/productcategory/getbyid/${id}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken()),
                Language: getLanguage().toString()
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })
            .catch((error: AxiosError) => {
                return null;
            });
    };

    const providerValue = {
        getAllCategories,
        categories,
        loadData,
        deleteCategory,
        addCategory,
        updateCategory,
        getById
    };

    return (
        <ProductCategoryContext.Provider value={providerValue}>
            {props.children}
        </ProductCategoryContext.Provider>
    );
};

export { ProductCategoryContext, ProductCategoryContextProvider };
