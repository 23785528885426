import React, { useState } from 'react';
import { useHistory, Route } from 'react-router-dom';
import { IReviewData } from '../../interfaces/ReviewInterface';
import ApproveReviews from './ApproveReviews/ApproveReviews';
import ShowSingleReview from './ShowSingleReview/ShowSingleReview';

const ReviewPage = () => {
    const [currentReview, setCurrentReview] = useState<IReviewData | null>(
        null
    );

    const history = useHistory();

    const renderShowSingle = () => {
        if (currentReview) {
            return <ShowSingleReview review={currentReview} />;
        }

        history.push('/review/approve-reviews');
    };

    return (
        <div>
            <Route path='/review/approve-reviews'>
                <ApproveReviews setCurrentReview={setCurrentReview} />
            </Route>
            <Route path='/review/show-single'>{renderShowSingle()}</Route>
        </div>
    );
};

export default ReviewPage;
