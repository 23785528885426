import { IChipData } from '../../interfaces/ChipInterface';
import { RoleEnum } from '../enums/Role';

export const printRoleChip = (roleInt: number): IChipData => {
    switch (roleInt) {
        case 0:
            return { text: 'Admin', color: 'primary' };

        case 1:
            return { text: 'Waiter', color: 'secondary' };

        default:
            return { text: 'Undefined', color: undefined };
    }
};

export const printRole = (roleInt: number | null): string => {
    if (roleInt === null || roleInt === -1) {
        return 'undefined';
    }
    return RoleEnum[roleInt];
};

export const convertToRoleEnumValue = (enumString: string) => {
    switch (enumString) {
        case RoleEnum[0]:
            return 0;

        case RoleEnum[1]:
            return 1;

        default:
            return -1;
    }
};
