import moment from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';

import { ThemeProvider } from '@emotion/react';
import {
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import Paper from '@mui/material/Paper';

import { AlertContext } from '../../../contexts/AlertContext';
import { OrderContext } from '../../../contexts/OrderContext';
import { IOrderData } from '../../../interfaces/OrderInterface';
import { printOrderStatus } from '../../../util/functions/OrderStatus';
import { TableTheme } from '../../../util/themes/GlobalThemeOverride';
import { useHistory } from 'react-router-dom';
import { printOrderOption } from '../../../util/functions/OrderOptions';
import { AuthenticationContext } from '../../../contexts';
import { RoleEnum } from '../../../util/enums/Role';
import {
    PaymentMethod,
    printPaymentMethod
} from '../../../util/enums/PaymentMethod';

interface ProcessedOrdersProps {
    setSelectedOrder: Function;
}

const ProcessedOrders: FC<ProcessedOrdersProps> = (props) => {
    const { setSelectedOrder } = props;

    const { getAllProcessedOrdersByShopLocation, getAllProcessedOrders } =
        useContext(OrderContext);

    const { getRole } = useContext(AuthenticationContext);

    const { showAlert } = useContext(AlertContext);

    const [rows, setRows] = useState<IOrderData[] | null>([]);

    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const [page, setPage] = useState<number>(0);

    const history = useHistory();

    useEffect(() => {
        loadData();
    }, []);

    const getPageClass = (): string => {
        if (getRole() === RoleEnum[0]) {
            return 'page-container';
        }
        return '';
    };

    const loadData = () => {
        if (getRole() !== RoleEnum[0]) {
            getAllProcessedOrdersByShopLocation().then((response) => {
                if (!response) {
                    showAlert({
                        severity: 'error',
                        show: true,
                        content: "Can't get data from server"
                    });

                    return;
                }

                setRows(response);
            });
        } else {
            getAllProcessedOrders().then((response) => {
                if (!response) {
                    showAlert({
                        severity: 'error',
                        show: true,
                        content: "Can't get data from server"
                    });
                }

                setRows(response);
            });
        }
    };

    const showSingleOrder =
        (row: IOrderData) => (event: React.MouseEvent<HTMLTableRowElement>) => {
            setSelectedOrder(row);

            history.push('/order/show-single');
        };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);

        setPage(0);
    };

    const printDate = (row: IOrderData) => {
        const date = moment
            .utc(row.arrivalTime)
            .local()
            .format('DD-MM-YYYY HH:mm');

        if (date === 'Invalid date') {
            return 'As soon as possible';
        }

        return date.toString();
    };

    const renderData = () => {
        if (rows) {
            return rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: IOrderData) => (
                    <TableRow key={row.id} onMouseDown={showSingleOrder(row)}>
                        {getRole() === RoleEnum[0] ? (
                            <TableCell align='center'>
                                {row.shopLocationName}
                            </TableCell>
                        ) : (
                            <></>
                        )}
                        <TableCell align='left' sx={{ maxWidth: '250px' }}>
                            {printDate(row)}
                        </TableCell>
                        <TableCell align='left' sx={{ maxWidth: '700px' }}>
                            {row.customerName}
                        </TableCell>
                        <TableCell align='left' sx={{ maxWidth: '250px' }}>
                            <Chip
                                color={printOrderStatus(row.orderStatus).color}
                                label={printOrderStatus(row.orderStatus).text}
                            />
                        </TableCell>
                        <TableCell align='left' sx={{ maxWidth: '150px' }}>
                            {row.priceTotal}
                        </TableCell>
                        <TableCell align='left' sx={{ maxWidth: '100px' }}>
                            <Chip
                                color={
                                    row.paymentMethod === PaymentMethod.CASH
                                        ? 'secondary'
                                        : 'primary'
                                }
                                label={
                                    row.paymentMethod === PaymentMethod.CASH
                                        ? 'No'
                                        : 'Yes'
                                }
                            />
                        </TableCell>
                        <TableCell align='left' sx={{ maxWidth: '250px' }}>
                            {printOrderOption(row.orderOption)}
                        </TableCell>
                    </TableRow>
                ));
        }
    };

    return (
        <div className={getPageClass()}>
            <ThemeProvider theme={TableTheme}>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {getRole() === RoleEnum[0] ? (
                                        <TableCell align='center'>
                                            Factory
                                        </TableCell>
                                    ) : (
                                        <></>
                                    )}

                                    <TableCell
                                        align='left'
                                        sx={{ maxWidth: '250px' }}
                                    >
                                        Arrival Time
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        sx={{ maxWidth: '700px' }}
                                    >
                                        Email
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        sx={{ maxWidth: '250px' }}
                                    >
                                        Status
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        sx={{ maxWidth: '150px' }}
                                    >
                                        Price Total
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        sx={{ maxWidth: '100px' }}
                                    >
                                        Is Paid
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        sx={{ maxWidth: '250px' }}
                                    >
                                        Order Type
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderData()}</TableBody>
                        </Table>
                    </TableContainer>
                    {rows ? (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component='div'
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    ) : (
                        <></>
                    )}
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default ProcessedOrders;
