import { ThemeProvider } from '@emotion/react';
import {
    Autocomplete,
    Button,
    Paper,
    TextField,
    TextFieldProps
} from '@mui/material';
import React, { useState, useContext, useEffect } from 'react';
import { AlertContext, UserContext } from '../../contexts';
import { IAddPointsToUserData, IUserData } from '../../interfaces/UserInterface';
import { FormTheme } from '../../util/themes/GlobalThemeOverride';

const AddPointsToUser = () => {
    const [usernames, setUsernames] = useState<string[]>(['']);

    const [selectedUsername, setSelectedUsername] = useState<string>('');

    const [values, setValues] = useState<IAddPointsToUserData>({ userId: 0, points: 0, signingCode: ''});

    const [users, setUsers] = useState<IUserData[] | null>(null);

    const [enableButton, setEnableButton] = useState<boolean>(false);

    const { getAllUsers, addPointsById } = useContext(UserContext);

    const { showAlert } = useContext(AlertContext);

    useEffect(() => {
        getAllUsers().then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Cannot get data from server'
                });

                return;
            }

            setUsers(response);
        });
    }, []);

    useEffect(() => {
        if (users) {
            setUsernames(users.map((user: IUserData) => user.email));
        }
    }, [users]);

    useEffect(() => {
        if (!validateAll()) {
            setEnableButton(true);
            return;
        }

        setEnableButton(false);
    }, [values.userId, values.points]);

    const validateAll = () => {
        if (values.points <= 0) {
            return true;
        }

        if (values.userId <= 0) {
            return true;
        }

        return false;
    };

    const handleAutoCompleteChange = (email: string) => {
        if (email) {
            users?.map((user: IUserData) => {
                if (user.email === email) {
                    setValues({ ...values, userId: user.id });
                }
            });
        }
    };

    const handleChange =
        (prop: keyof IAddPointsToUserData) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const validateForm = (prop: keyof IAddPointsToUserData, value: number | string) => {
        if (value === ''){
            return true;
        }
       
        if (prop !== 'signingCode' && value <= 0) {
            return true;
        }

        return false;
    };

    const submitForm = () => {
        addPointsById(values).then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Adding points failed'
                });

                return;
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully added points'
            });
        });
    };

    return (
        <div className='page-container add-form-container'>
            <ThemeProvider theme={FormTheme}>
                <Paper className='paper-form'>
                    <h1>Add point to user</h1>
                    <div className='form-container'>
                        <Autocomplete
                            disablePortal
                            options={usernames}
                            sx={{ width: 300 }}
                            value={selectedUsername}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setSelectedUsername(newValue);

                                    handleAutoCompleteChange(newValue);
                                }
                            }}
                            renderInput={(
                                params: JSX.IntrinsicAttributes & TextFieldProps
                            ) => (
                                <TextField
                                    key='userId'
                                    label='User'
                                    {...params}
                                />
                            )}
                        />

                        <TextField
                            key='points'
                            label='Points'
                            type='number'
                            value={values.points}
                            onChange={handleChange('points')}
                            error={validateForm('points', values.points)}
                            helperText={
                                validateForm('points', values.points)
                                    ? 'Points must be greater than 0'
                                    : ' '
                            }
                        ></TextField>

                        <TextField
                            key='signingCode'
                            label='Signing Code'
                            value={values.signingCode}
                            onChange={handleChange('signingCode')}
                            error={validateForm('signingCode', values.signingCode)}
                            helperText={
                                validateForm('signingCode', values.signingCode)
                                    ? 'Bill Id is required'
                                    : ' '
                            }
                        ></TextField>

                        <Button
                            variant='contained'
                            component='label'
                            sx={{ m: 1.5, width: '50ch' }}
                            size='large'
                            color='success'
                            onMouseDown={submitForm}
                            disabled={!enableButton}
                        >
                            Add Points
                            <input type='button' hidden />
                        </Button>
                    </div>
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default AddPointsToUser;
