export const responsiveWidth = {
	xs: '300px',
	sm: '500px',
	md: '900px',
	lg: '1100px',
	xl: '1500px'
};

export const responsiveWidthHalfTable = {
	xs: '150px',
	sm: '250px',
	md: '450px',
	lg: '550px',
	xl: '750px'
};

export const newResponsiveWidthTable = {
	xs: '200px',
	sm: '350px',
	md: '650px',
	lg: '850px',
	xl: '1050px'
}