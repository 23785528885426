import { ThemeProvider } from '@emotion/react';
import { MobileDatePicker } from '@mui/lab';
import { Button, Paper, TextField } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import ProductsTable from '../../../components/ProductsTable/ProductsTable';
import { AlertContext, OrderContext } from '../../../contexts';
import {
    IOrderData,
    IUpdateOrderData
} from '../../../interfaces/OrderInterface';
import { IProductHistoryData } from '../../../interfaces/ProductInterface';
import { printOrderOption } from '../../../util/functions/OrderOptions';
import { FormTheme } from '../../../util/themes/GlobalThemeOverride';

interface ShowSingleOrderProps {
    order: IOrderData;
}

const ShowSingleOrder: FC<ShowSingleOrderProps> = (props) => {
    const { order } = props;

    const { showAlert } = useContext(AlertContext);

    const { getProductsForOrder, updateOrderDetails } =
        useContext(OrderContext);

    const [orderData, setOrderData] = useState<IOrderData>(order);

    const [productsOfOrder, setProductsOfOrder] = useState<
        IProductHistoryData[]
    >([]);

    const [values, setValues] = useState<IUpdateOrderData>(order);

    useEffect(() => {
        getProductsForOrder(order.id).then((response) => {
            if (response) {
                setProductsOfOrder(response);

                return;
            }

            showAlert({
                severity: 'error',
                show: true,
                content: 'Cannot get data from server'
            });
        });
    }, []);

    const handleChange =
        (prop: keyof IUpdateOrderData) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleChangeDate = (newValue: Date | null) => {
        if (newValue) {
            setValues({ ...values, arrivalTime: newValue });
        }
    };

    const submitForm = () => {
        updateOrderDetails(values).then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Updating order failed'
                });

                return;
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully updated order'
            });
        });
    };

    return (
        <div className='page-container single-order-page-container'>
            <ThemeProvider theme={FormTheme}>
                <div className='order-content-container'>
                    <Paper className='paper-form'>
                        <h1>Order Content</h1>
                        <ProductsTable products={productsOfOrder} isModal={false} />
                    </Paper>
                </div>
                <div className='order-details-container'>
                    <Paper className='paper-form'>
                        <h1>Order Details</h1>
                        <div className='form-container'>
                            <TextField
                                key='id'
                                label='Id'
                                value={orderData.id}
                                required
                                disabled
                            ></TextField>

                            <TextField
                                key='customerName'
                                label='Customer'
                                value={orderData.customerName}
                                required
                                disabled
                            ></TextField>

                            <MobileDatePicker
                                label='Arrival Time'
                                inputFormat='DD-MM-yyyy'
                                value={values.arrivalTime}
                                onChange={handleChangeDate}
                                renderInput={(params: any) => (
                                    <TextField {...params} />
                                )}
                            />

                            <TextField
                                key='orderOption'
                                label='Order option'
                                value={printOrderOption(orderData.orderOption)}
                                disabled
                            ></TextField>

                            <TextField
                                key='address'
                                label='Address'
                                value={values.addressAndBuildingNumber}
                                onChange={handleChange(
                                    'addressAndBuildingNumber'
                                )}
                            ></TextField>

                            <TextField
                                key='apartment'
                                label='Apartment number'
                                value={
                                    values.apartmentNumber
                                        ? values.apartmentNumber
                                        : ''
                                }
                                onChange={handleChange('apartmentNumber')}
                            ></TextField>

                            <TextField
                                key='phone'
                                label='Phone'
                                value={values.phoneNumber}
                                required
                                onChange={handleChange('phoneNumber')}
                            ></TextField>

                            <TextField
                                key='priceTotal'
                                label='Total'
                                value={orderData.priceTotal}
                                required
                                disabled
                            ></TextField>

                            <Button
                                variant='contained'
                                component='label'
                                sx={{ m: 1.5, width: '50ch' }}
                                size='large'
                                color='success'
                                onMouseDown={submitForm}
                            >
                                Save
                                <input type='button' hidden />
                            </Button>
                        </div>
                    </Paper>
                </div>
            </ThemeProvider>
        </div>
    );
};

export default ShowSingleOrder;
