export const getLocation = () => {
    const url = window.location.href;

    let current = url.split('/')[3];

    if (current === 'subcategory') {
        current = 'category';
    }

    return current;
};