import React, { FC, useEffect, useState } from 'react';

import { ThemeProvider } from '@emotion/react';
import { Button, Chip, ListItem, Paper, TextField } from '@mui/material';
import { ISizeData } from '../../interfaces/SizeInterface';
import { InnerFormTheme } from '../../util/themes/GlobalThemeOverride';

interface AddSizeProps {
    setValue: Function;
    receivedSizes: ISizeData[] | null;
}

const AddSize: FC<AddSizeProps> = (props) => {
    const { receivedSizes } = props;

    const returnValue = props.setValue;

    const [values, setValues] = useState<ISizeData>({
        id: 0,
        sizeLabel: '',
        sizeVolume: '',
        coffeebeans: 0,
        price: 0,
        productId: 0,
        createdAt: new Date(),
        updatedAt: new Date()
    });

    const [sizes, setSizes] = useState<ISizeData[]>(
        receivedSizes ? receivedSizes : []
    );

    const [chipList, setChipList] = useState(renderChipList());

    const [enabledButton, setEnabledButton] = useState<boolean>(false);

    useEffect(() => {
        if (sizes.length > 0){
            validateAll();
        }

        returnValue(sizes);

        setChipList(renderChipList());
    }, [sizes]);

    useEffect(() => {
        validateAll()
    }, [values.sizeLabel, values.sizeVolume, values.coffeebeans, values.price])

    const removeSize = (size: ISizeData) => {
        const index = sizes.indexOf(size);

        if (index > -1) {
            sizes.splice(index, 1);
        }

        setChipList(renderChipList());
    };

    const handleChange =
        (prop: keyof ISizeData) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const validateForm = (prop: keyof ISizeData, value: string | number) => {
        if (value === '' || value <= 0) {
            return true;
        }

        if (prop === 'sizeLabel' && checkLabelConflict()) {
            return true;
        }
    };

    const validateAll = () => {
        if (
            validateForm('sizeLabel', values.sizeLabel) ||
            validateForm('sizeVolume', values.sizeVolume) ||
            validateForm('coffeebeans', values.coffeebeans) ||
            validateForm('price', values.price)
        ) {
            setEnabledButton(false);
        } else {
            setEnabledButton(true);
        }
    };

    const printLabelHelperText = () => {
        if (values.sizeLabel === '') {
            return 'Label cannot be empty';
        }

        if (checkLabelConflict()) {
            return 'Label already exists';
        }

        return '';
    };

    const checkLabelConflict = () => {
        for (let size of sizes) {
            if (size.sizeLabel === values.sizeLabel) {
                return true;
            }
        }
        return false;
    };

    const submitForm = () => {
        const newSizes: ISizeData[] = JSON.parse(JSON.stringify(sizes));

        newSizes.push(values);

        setSizes(newSizes);
    };

    function renderChipList() {
        return (
            <Paper
                component='ul'
                className='sizes-chipList'
                sx={{
                    p: 0,
                    m: 0,
                    boxShadow: 'none'
                }}
            >
                {sizes.map((size: ISizeData) => {
                    return (
                        <ListItem key={size.sizeLabel} sx={{maxWidth: '120px', p:0, marginBottom: '10px', justifyContent:'space-around'}}>
                            <Chip
                                component='div'
                                sx={{height: '80px'}}
                                label={(
                                    <section>
                                        <div className="pchip">Label: {size.sizeLabel}</div>
                                        <div className="pchip">Volume: {size.sizeVolume}</div>
                                        <div className="pchip">Points: {size.coffeebeans}</div>
                                        <div className="pchip">Price: {size.price}</div>
                                    </section>
                                )}
                                onDelete={() => {
                                    removeSize(size);
                                }}
                            />
                        </ListItem>
                    );
                })}
            </Paper>
        );
    }

    return (
        <>
            {chipList}
            <ThemeProvider theme={InnerFormTheme}>
                <Paper>
                    <TextField
                        key='sizeLabel'
                        label='Size Label'
                        value={values.sizeLabel}
                        onChange={handleChange('sizeLabel')}
                        error={validateForm('sizeLabel', values.sizeLabel)}
                        helperText={
                            validateForm('sizeLabel', values.sizeLabel)
                                ? printLabelHelperText()
                                : ''
                        }
                    ></TextField>

                    <TextField
                        key='sizeVolume'
                        label='Size Volume'
                        value={values.sizeVolume}
                        onChange={handleChange('sizeVolume')}
                        error={validateForm('sizeVolume', values.sizeVolume)}
                        helperText={
                            validateForm('sizeVolume', values.sizeVolume)
                                ? 'Volume cannot be empty!'
                                : ''
                        }
                    ></TextField>

                    <TextField
                        key='sizeCoffeebenas'
                        label='Coffeebeans amount'
                        value={values.coffeebeans}
                        onChange={handleChange('coffeebeans')}
                        error={validateForm('coffeebeans', values.coffeebeans)}
                        type='number'
                        helperText={
                            validateForm('coffeebeans', values.coffeebeans)
                                ? 'Coffeebeans amount must be a positive integer'
                                : ''
                        }
                    ></TextField>

                    <TextField
                        key='sizePrice'
                        label='Size Price'
                        value={values.price}
                        onChange={handleChange('price')}
                        error={validateForm('price', values.price)}
                        type='number'
                        helperText={
                            validateForm('price', values.price)
                                ? 'Price must be a positive integer'
                                : ''
                        }
                    ></TextField>

                    <Button
                        variant='contained'
                        component='label'
                        sx={{ width: '25ch', marginBottom: '20px' }}
                        size='large'
                        color='success'
                        onMouseDown={submitForm}
                        disabled={!enabledButton}
                    >
                        Add Size
                        <input type='button' hidden />
                    </Button>
                </Paper>
            </ThemeProvider>
        </>
    );
};

export default AddSize;
