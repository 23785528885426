import { AxiosError, AxiosResponse } from 'axios';
import { createContext, FC, useContext } from 'react';
import { AuthenticationContext, AxiosContext } from '.';
import { IReviewData } from '../interfaces/ReviewInterface';

interface IReviewContext {
    getAllUnapproved(): Promise<IReviewData[] | null>;
    approveReview(id: number): Promise<boolean>;
    deleteReview(id: number): Promise<boolean>;
}

const ReviewContext = createContext({} as IReviewContext);

interface ReviewContextProps {
    children: React.ReactNode;
}

const ReviewContextProvider: FC<ReviewContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const { getToken } = useContext(AuthenticationContext);

    const getAllUnapproved = () => {
        return axiosInstance({
            method: 'GET',
            url: 'api/review/getallunapproved',
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return null;
                }

                return response.data;
            })

            .catch((error: AxiosError) => {
                return null;
            });
    };

    const approveReview = (id: number) => {
        return axiosInstance({
            method: 'PUT',
            url: `api/review/approvereview/${id}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const deleteReview = (id: number) => {
        return axiosInstance({
            method: 'DELETE',
            url: `api/review/delete/${id}`,
            headers: {
                Authorization: 'Bearer '.concat(getToken())
            }
        })
            .then((response: AxiosResponse) => {
                if (response === undefined) {
                    return false;
                }

                return true;
            })
            .catch((error: AxiosError) => {
                return false;
            });
    };

    const providerValue = {
        getAllUnapproved,
        approveReview,
        deleteReview
    };

    return (
        <ReviewContext.Provider value={providerValue}>
            {props.children}
        </ReviewContext.Provider>
    );
};

export { ReviewContext, ReviewContextProvider };
