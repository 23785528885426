import React, { useContext, useEffect, useState } from 'react';

import { ThemeProvider } from '@emotion/react';

import { styled } from '@mui/material/styles';
import { TextField, Paper, Button, InputLabel } from '@mui/material';

import { AlertContext } from '../../../contexts/AlertContext';
import { ShopLocationContext } from '../../../contexts/ShopLocationContext';
import { FormTheme } from '../../../util/themes/GlobalThemeOverride';
import { IProductCategoryData } from '../../../interfaces/ProductCategoryInterface';
import TagsCollection from '../../../components/TagsCollection/TagsCollection';
import { TagType } from '../../../interfaces/TagsCollectionInterface';

const Input = styled('input')({
    display: 'none'
});

interface State {
    name: string;
    categoryAvailability: Array<number>;
    postalCode: number;
    phoneNumber: string;
    workingHours: string;
    details: string;
    city: string;
    address: string;
    shopLocationImage: File | null;
}

const AddShopLocation = () => {
    const { addShopLocation } = useContext(ShopLocationContext);

    const { showAlert } = useContext(AlertContext);

    const [previewImage, setPreviewImage] = useState<string>('');

    const [enableButton, setEnableButton] = useState<boolean>(false);

    const [values, setValues] = useState<State>({
        name: '',
        categoryAvailability: [],
        postalCode: NaN,
        phoneNumber: '',
        workingHours: '',
        details: '',
        city: '',
        address: '',
        shopLocationImage: null
    });

    const handleChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (prop !== 'shopLocationImage') {
                setValues({ ...values, [prop]: event.target.value });
                return;
            }

            if (event.target.files) {
                const file = event.target.files[0];

                setValues({ ...values, shopLocationImage: file });

                fileToBase64(file);
            }
        };

    const validateAll = () => {
        if (values.name === '') {
            return false;
        }

        if (values.city === '') {
            return false;
        }

        if (values.address === '') {
            return false;
        }

        if (isNaN(values.postalCode)) {
            return false;
        }

        return true;
    };

    useEffect(() => {
        if (validateAll()) {
            setEnableButton(true);
        }
    }, [values.name, values.postalCode, values.city, values.address]);

    const fileToBase64 = async (file: File | undefined) => {
        if (file !== undefined) {
            const reader = new FileReader();

            reader.onloadend = () => {
                let base64data: string = reader.result as string;

                setPreviewImage(base64data);
            };

            reader.readAsDataURL(file);

            return;
        }

        setValues({ ...values, shopLocationImage: null });
    };

    const renderPhoto = () => {
        if (values.shopLocationImage) {
            return (
                <img
                    className='image-preview'
                    src={`${previewImage}`}
                    alt='preview'
                />
            );
        }
    };

    const submitForm = () => {
        let formData = new FormData();

        formData.append(
            'data',
            JSON.stringify({
                name: values.name,
                categoryAvailability: values.categoryAvailability,
                postalCode: values.postalCode,
                phoneNumber: values.phoneNumber,
                workingHours: values.workingHours,
                details: values.details,
                city: values.city,
                address: values.address
            })
        );

        if (values.shopLocationImage) {
            formData.append(
                'Images',
                values.shopLocationImage,
                values.shopLocationImage.name
            );
        }

        addShopLocation(formData).then((response: boolean) => {
            if (response === false) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Adding new factory failed'
                });

                return;
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully added new factory'
            });
        });
    };

    const validateForm = (prop: keyof State, value: string | number) => {
        if (prop !== 'shopLocationImage') {
            if (value === '') {
                return true;
            }

            if (typeof value === 'number') {
                if (isNaN(value as number)) {
                    return true;
                }
            }
        }
    };

    const setCategoryAvailability = (data: IProductCategoryData[]) => {
        setValues({ ...values, categoryAvailability: data.map(category => category.id) });
    };

    return (
        <div className='page-container add-form-containter'>
            <ThemeProvider theme={FormTheme}>
                <Paper className='paper-form'>
                    <h1>Add Factory</h1>
                    <div className='form-container'>
                        <TextField
                            required
                            label='Name'
                            defaultValue=''
                            key='name'
                            onChange={handleChange('name')}
                            error={validateForm('name', values.name)}
                            helperText={
                                validateForm('name', values.name)
                                    ? 'Name cannot be empty!'
                                    : ' '
                            }
                        ></TextField>

                        <TextField
                            defaultValue=''
                            label='City'
                            required
                            key='city'
                            onChange={handleChange('city')}
                            error={validateForm('city', values.city)}
                            helperText={
                                validateForm('city', values.city)
                                    ? 'City cannot be empty!'
                                    : ' '
                            }
                        ></TextField>

                        <TextField
                            defaultValue=''
                            label='Address'
                            required
                            key='address'
                            onChange={handleChange('address')}
                            error={validateForm('address', values.address)}
                            helperText={
                                validateForm('address', values.address)
                                    ? 'Address cannot be empty!'
                                    : ' '
                            }
                        ></TextField>

                        <TextField
                            label='Postal code'
                            type='number'
                            required
                            key='postalCode'
                            onChange={handleChange('postalCode')}
                            error={validateForm(
                                'postalCode',
                                values.postalCode
                            )}
                            helperText={
                                validateForm('postalCode', values.postalCode)
                                    ? 'Postal code cannot be empty!'
                                    : ' '
                            }
                        ></TextField>

                        <div className='inner-form-products'>
                            <InputLabel
                                sx={{
                                    marginBottom: '10px'
                                }}
                            >
                                Select Available Categories
                            </InputLabel>

                            <TagsCollection 
                                tagType={TagType.AvailableCategories}
                                returnValue={setCategoryAvailability}
                                receivedTags={[]}
                            />
                        </div>

                        <TextField
                            defaultValue=''
                            label='Phone number'
                            key='phoneNumber'
                            onChange={handleChange('phoneNumber')}
                        ></TextField>

                        <TextField
                            defaultValue=''
                            label='Working hours'
                            key='workingHours'
                            onChange={handleChange('workingHours')}
                        ></TextField>

                        <TextField
                            defaultValue=''
                            label='Details'
                            key='details'
                            onChange={handleChange('details')}
                            multiline
                            rows={5}
                        ></TextField>

                        <div className='image-upload-container-addShopLocation'>
                            <label htmlFor='contained-button-file'>
                                <Input
                                    accept='.png, .jpg, .jpeg'
                                    id='contained-button-file'
                                    multiple
                                    type='file'
                                    key='shopLocationImage'
                                    onChange={handleChange('shopLocationImage')}
                                />

                                <Button
                                    variant='contained'
                                    component='span'
                                    sx={{ m: 1.5, width: '50ch' }}
                                >
                                    Upload Image
                                </Button>
                            </label>

                            {renderPhoto()}
                        </div>

                        <Button
                            variant='contained'
                            component='label'
                            sx={{ m: 1.5, width: '50ch' }}
                            size='large'
                            color='success'
                            id='add-shoplocation-btn'
                            onMouseDown={submitForm}
                            disabled={!enableButton}
                        >
                            Add Factory
                            <input type='button' hidden />
                        </Button>
                    </div>
                </Paper>
            </ThemeProvider>
        </div>
    );
};

export default AddShopLocation;
