import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup
} from '@mui/material';
import React, { FC, useContext, useEffect } from 'react';
import { LanguageContext } from '../../contexts';

interface LanguageSelectionProps {
    setIsChanged: Function;
}

const LanguageSelection: FC<LanguageSelectionProps> = (props) => {
    const { setIsChanged } = props;

    const { getLanguage, setLanguage } = useContext(LanguageContext);

    const getLanguageIndexFromValue = (value: string) => {
        switch (value) {
            case 'serbian':
                return 0;

            case 'english':
                return 1;

            default:
                return 0;
        }
    };

    const getLanguageValueFromIndex = (languageIndex: number) => {
        switch (languageIndex) {
            case 0:
                return 'serbian';
            case 1:
                return 'english';
            default:
                return '';
        }
    };

    const [value, setValue] = React.useState(
        getLanguageValueFromIndex(getLanguage())
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    useEffect(() => {
        setLanguage(getLanguageIndexFromValue(value));

        setIsChanged(true);
    }, [value]);

    return (
        <FormControl component='fieldset'>
            <FormLabel component='legend'>Language</FormLabel>
            <RadioGroup
                row
                aria-label='language'
                name='controlled-radio-buttons-group'
                value={value}
                onChange={handleChange}
                sx={{ marginBottom: '10px' }}
            >
                <FormControlLabel
                    value='serbian'
                    control={<Radio />}
                    label='Serbian'
                />
                <FormControlLabel
                    value='english'
                    control={<Radio />}
                    label='English'
                />
            </RadioGroup>
        </FormControl>
    );
};

export default LanguageSelection;
