import { createContext, FC } from 'react';

interface ILanguageContext {
    getLanguage(): number;
    setLanguage(languageIndex: number): void;
}

const LanguageContext = createContext({} as ILanguageContext);

interface LanguageContextProps {
    children: React.ReactNode;
}

const LanguageContextProvider: FC<LanguageContextProps> = (props) => {
    const saveLanguage = (languageIndex: number) => {
        localStorage.setItem('@language', languageIndex.toString());
    };

    const getLanguage = () => {
        const language = localStorage.getItem('@language');

        if (language === '' || language === null || language === undefined) {
            saveLanguage(0);

            return 0;
        }

        return Number.parseInt(language);
    };

    const setLanguage = (languageIndex: number) => {
        saveLanguage(languageIndex);
    };

    const providerValue = {
        getLanguage,
        setLanguage
    };

    return (
        <LanguageContext.Provider value={providerValue}>
            {props.children}
        </LanguageContext.Provider>
    );
};

export { LanguageContext, LanguageContextProvider };
