import { IChipData } from '../../interfaces/ChipInterface';

export const printOrderStatus = (orderStatus: number): IChipData => {
    switch (orderStatus) {
        case 2:
            return { text: 'Responding', color: 'warning' };

        case 3:
            return { text: 'Accepted', color: 'success' };

        case 4:
            return { text: 'Rejected', color: 'error' };

        default:
            return { text: 'Undefined', color: undefined };
    }
};
