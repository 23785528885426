import React, { FC } from 'react';
import { AlertContextProvider } from './AlertContext';
import { ArticleContextProvider } from './ArticleContext';

import { AuthenticationContextProvider } from './AuthenticationContext';
import { AxiosContextProvider } from './AxiosContext';
import { EmployeeContextProvider } from './EmployeeContext';
import { LandingPageContentContextProvider } from './LandingPageContentContext';
import { LanguageContextProvider } from './LanguageContext';
import { OnboardingAnswersTypesContextProvider } from './OnboardingAnswersTypesContext';
import { OrderContextProvider } from './OrderContext';
import { ProductCategoryContextProvider } from './ProductCategoryContext';
import { ProductContextProvider } from './ProductContext';
import { ProductSubCategoryContextProvider } from './ProductSubCategoryContext';
import { ReviewContextProvider } from './ReviewContext';
import { ShopLocationContextProvider } from './ShopLocationContext';
import { UserContextProvider } from './UserContext';
import { WebSocketContextProvider } from './WebSocketContext';

interface GlobalContextProps {
    children: React.ReactNode;
}

const GlobalContextProvider: FC<GlobalContextProps> = (props) => {
    return (
        <AlertContextProvider>
            <LanguageContextProvider>
                <WebSocketContextProvider>
                    <AuthenticationContextProvider>
                        <AxiosContextProvider>
                            <ShopLocationContextProvider>
                                <OrderContextProvider>
                                    <EmployeeContextProvider>
                                        <ProductCategoryContextProvider>
                                            <ProductSubCategoryContextProvider>
                                                <OnboardingAnswersTypesContextProvider>
                                                    <ProductContextProvider>
                                                        <ArticleContextProvider>
                                                            <UserContextProvider>
                                                                <ReviewContextProvider>
                                                                    <LandingPageContentContextProvider>
                                                                        {
                                                                            props.children
                                                                        }
                                                                    </LandingPageContentContextProvider>
                                                                </ReviewContextProvider>
                                                            </UserContextProvider>
                                                        </ArticleContextProvider>
                                                    </ProductContextProvider>
                                                </OnboardingAnswersTypesContextProvider>
                                            </ProductSubCategoryContextProvider>
                                        </ProductCategoryContextProvider>
                                    </EmployeeContextProvider>
                                </OrderContextProvider>
                            </ShopLocationContextProvider>
                        </AxiosContextProvider>
                    </AuthenticationContextProvider>
                </WebSocketContextProvider>
            </LanguageContextProvider>
        </AlertContextProvider>
    );
};

export default GlobalContextProvider;
