import { ThemeProvider } from '@emotion/react';
import {
    Autocomplete,
    Button,
    InputLabel,
    Paper,
    styled,
    TextField,
    TextFieldProps
} from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { FormTheme } from '../../../util/themes/GlobalThemeOverride';
import { IArticleCreationData } from '../../../interfaces/ArticleInterface';
import { AlertContext, ArticleContext, UserContext } from '../../../contexts';
import { IUserData } from '../../../interfaces/UserInterface';
import {
    IArticleSectionData,
    ISectionTempData
} from '../../../interfaces/ArticleSectionInterface';
import AddSection, {
    AddSectionProps
} from '../../../components/AddSection/AddSection';
import ArticleCategorySelect from '../../../components/ArticleCategorySelect/ArticleCategorySelect';

const Input = styled('input')({
    display: 'none'
});

const AddArticle = () => {
    const { getAllUsers } = useContext(UserContext);

    const { showAlert } = useContext(AlertContext);

    const { addArticle } = useContext(ArticleContext);

    const [values, setValues] = useState<IArticleCreationData>({
        id: 0,
        title: '',
        description: '',
        articleCategory: 0,
        imageUrl: '',
        coverImageUrl: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        sections: [],
        userId: -1
    });

    const [selectedArticleCategory, setSelectedArticleCategory] = useState<number>(0);

    const [previewImage, setPreviewImage] = useState<string>('');

    const [image, setImage] = useState<File | null>(null);

    const [previewCover, setPreviewCover] = useState<string>('');

    const [cover, setCover] = useState<File | null>(null);

    const [enableButton, setEnableButton] = useState<boolean>(false);

    const [users, setUsers] = useState<IUserData[] | null>(null);

    const [usernames, setUsernames] = useState<string[]>(['']);

    const [selectedUsername, setSelectedUsername] = useState<string>('');

    const [sectionData, setSectionData] = useState<ISectionTempData[]>([]);

    const [sections, setSections] = useState<FC<AddSectionProps>[]>();

    const [counter, setCounter] = useState<number>(0);

    const [sectionValueChange, setSectionValueChange] =
        useState<boolean>(false);

    useEffect(() => {
        getAllUsers().then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Cannot get data from server'
                });

                return;
            }

            setUsers(response);
        });
    }, []);

    useEffect(() => {
        if (users) {
            setUsernames(users.map((user: IUserData) => user.email));
        }
    }, [users]);

    useEffect(() => {
        setValues({...values, articleCategory: selectedArticleCategory});
    }, [selectedArticleCategory])

    useEffect(() => {
        renderSections();
    }, [sectionData]);

    useEffect(() => {
        if (!validateAll()) {
            setEnableButton(true);

            setSectionValueChange(false);

            return;
        }

        setEnableButton(false);

        setSectionValueChange(false);
    }, [values, sectionData.length, sectionValueChange]);

    const fileToBase64 = async (file: File | undefined, isImage:boolean) => {
        if(file !== undefined){
            const reader = new FileReader();

            reader.onloadend = () => {
                let base64data: string = reader.result as string;
    
                if (isImage){
                    setPreviewImage(base64data);
                    
                    return;
                }
    
                setPreviewCover(base64data);
    
            };
    
            reader.readAsDataURL(file);

            return;
        }
        
        if(isImage){
            setImage(null);

            setPreviewImage('');

            return;
        }

        setCover(null);

        setPreviewCover('');
    };

    const handleChange =
        (prop: keyof IArticleCreationData) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleImageChange =
        () => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                const file = event.target.files[0];

                setImage(file);

                fileToBase64(file, true);
            }
        };

    const handleCoverChange =
        () => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                const file = event.target.files[0];

                setCover(file);

                fileToBase64(file, false);
            }
        };

    const renderPhoto = () => {
        if (image) {
            return (
                <img
                    className='icon-preview'
                    src={`${previewImage}`}
                    alt='preview'
                />
            );
        }
    };

    const renderCover = () => {
        if (cover) {
            return (
                <img
                    className='image-preview'
                    src={`${previewCover}`}
                    alt='preview'
                />
            );
        }
    };

    const handleAutoCompleteChange = (email: string) => {
        if (email) {
            users?.map((user: IUserData) => {
                if (user.email === email) {
                    setValues({ ...values, userId: user.id });
                }
            });
        }
    };

    const validateForm = (prop: keyof IArticleCreationData, value: string) => {
        if (value === '') {
            return true;
        }
    };

    const validateAll = () => {
        if (values.title === '') {
            return true;
        }

        if (values.description === '') {
            return true;
        }

        if (sectionData.length === 0) {
            return true;
        }

        for (let item of sectionData) {
            if (!item.text && !item.image) {
                return true;
            }
        }

        return false;
    };

    const renderNewSection = () => {
        sectionData.push({ index: counter } as ISectionTempData);

        setCounter(counter + 1);

        renderSections();
    };

    const setSectionValue = (sectionIndex: number, value: File | string) => {
        sectionData.forEach((element: ISectionTempData) => {
            if (element.index === sectionIndex) {
                if (typeof value === 'string') {
                    element.text = value;

                    element.image = null;

                } else {
                    element.image = value;

                    element.text = '';
                }
            }
        });

        setSectionValueChange(true);
    };

    const deleteSectionByIndex = (sectionIndex: number) => {
        let tempSectionArray: ISectionTempData[] = [];

        sectionData.forEach((data: ISectionTempData) => {
            if (data.index !== sectionIndex) {
                tempSectionArray.push(data);
            }
        });

        setSectionData(tempSectionArray);
    };

    const renderSections = () => {
        const renderArray: any[] = [];

        sectionData.map((tempSection: ISectionTempData) => {
            renderArray.push(
                <AddSection
                    key={tempSection.index}
                    setSectionValue={setSectionValue}
                    deleteSection={deleteSectionByIndex}
                    index={tempSection.index}
                    receivedValue={null}
                />
            );
        });

        setSections(renderArray);
    };

    const submitForm = () => {
        const sections: IArticleSectionData[] = [];

        let formData = new FormData();

        if (image) {
            formData.append(
                'Images[]',
                image,
                `Article.${image.name}`
            );
        }

        if(cover){
            formData.append(
                'Images[]',
                cover,
                `Cover.${cover.name}`
            );
        }

        sectionData.forEach((element: ISectionTempData) => {
            sections.push({
                id: 0,
                articleId: 0,
                imageUrl: '',
                createdAt: new Date(),
                updatedAt: new Date(),
                sectionIndex: sections.length,
                text: element.image? '' : element.text
            });

            if (element.image) {
                formData.append(
                    'Images[]',
                    element.image,
                    `${(sections.length - 1).toString()}.${
                        element.image.name.split('.')[1]
                    }`
                );
            }
        });

        formData.append(
            'data',
            JSON.stringify({
                title: values.title,
                description: values.description,
                articleCategory: values.articleCategory,
                sections: sections,
                userId: values.userId
            })
        );

        addArticle(formData).then((response) => {
            if (!response) {
                showAlert({
                    severity: 'error',
                    show: true,
                    content: 'Adding new Article failed'
                });

                return;
            }

            showAlert({
                severity: 'success',
                show: true,
                content: 'Successfully added new Article'
            });
        });
    };

    return (
        <div className='page-container add-form-container add-article-container'>
            <ThemeProvider theme={FormTheme}>
                <div className='article-data'>
                    <Paper className='paper-form'>
                        <h1>Add Article</h1>
                        <div className='form-container'>
                            <TextField
                                key='title'
                                label='Title'
                                value={values.title}
                                onChange={handleChange('title')}
                                error={validateForm('title', values.title)}
                                helperText={
                                    validateForm('title', values.title)
                                        ? 'Title cannot be empty'
                                        : ' '
                                }
                                required
                            ></TextField>

                            <TextField
                                key='description'
                                label='Description'
                                value={values.description}
                                onChange={handleChange('description')}
                                error={validateForm(
                                    'description',
                                    values.description
                                )}
                                helperText={
                                    validateForm(
                                        'description',
                                        values.description
                                    )
                                        ? 'Description cannot be empty'
                                        : ' '
                                }
                                multiline
                                rows={5}
                                required
                            ></TextField>

                            <InputLabel
                                    sx={{
                                        marginBottom: '10px'
                                    }}
                                >
                                    Select article category
                            </InputLabel>
                            <ArticleCategorySelect
                                iconComponent={undefined}
                                setValue={setSelectedArticleCategory}
                                selectedValue={0}
                            />

                            <Autocomplete
                                disablePortal
                                options={usernames}
                                sx={{ width: 300 }}
                                value={selectedUsername}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setSelectedUsername(newValue);

                                        handleAutoCompleteChange(newValue);
                                    }
                                }}
                                renderInput={(
                                    params: JSX.IntrinsicAttributes &
                                        TextFieldProps
                                ) => (
                                    <TextField
                                        key='userId'
                                        label='User'
                                        {...params}
                                    />
                                )}
                            />

                            <div className='image-entry-container'>
                                <div className='image-upload-container-addArticle'>
                                    <label htmlFor='contained-button-image'>
                                        <Input
                                            accept='.png, .jpg, .jpeg'
                                            id='contained-button-image'
                                            multiple
                                            type='file'
                                            key='image'
                                            onChange={handleImageChange()}
                                        />

                                        <Button
                                            variant='contained'
                                            component='span'
                                            sx={{ m: 1.5, width: '25ch' }}
                                        >
                                            Upload Image
                                        </Button>
                                    </label>

                                    {renderPhoto()}
                                </div>

                                <div className='image-upload-container-addArticle'>
                                    <label htmlFor='contained-button-cover'>
                                        <Input
                                            accept='.png, .jpg, .jpeg'
                                            id='contained-button-cover'
                                            multiple
                                            type='file'
                                            key='cover'
                                            onChange={handleCoverChange()}
                                        />

                                        <Button
                                            variant='contained'
                                            component='span'
                                            sx={{ m: 1.5, width: '25ch' }}
                                        >
                                            Upload Cover
                                        </Button>
                                    </label>

                                    {renderCover()}
                                </div>
                            </div>
                            <Button
                                variant='contained'
                                component='label'
                                sx={{ m: 1.5, width: '50ch' }}
                                size='large'
                                color='success'
                                onMouseDown={submitForm}
                                disabled={!enableButton}
                            >
                                Add Article
                                <input type='button' hidden />
                            </Button>
                        </div>
                    </Paper>
                </div>
                <div className='section-data'>
                    <Paper className='paper-form-section-container-addArticle'>
                        <h1>Section Data</h1>
                        {sections}

                        <Button
                            onMouseDown={renderNewSection}
                            variant='contained'
                            component='span'
                            sx={{ m: 1.5, width: '15ch' }}
                        >
                            +
                        </Button>
                    </Paper>
                </div>
            </ThemeProvider>
        </div>
    );
};

export default AddArticle;
